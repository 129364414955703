/* eslint-disable no-unused-vars */
import React from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import OnboardingInput from '../components/onboarding-input-field';
import OnboardingSubmitButton from '../components/onboarding-submit-button';
import { PersonalOnboardingData } from '../utils/types';
import PersonalOnboardingFormModel from '../utils/personal-onboarding-form-model';
import OnboardingSingleSelectDropdown from '../components/onboarding-single-select-dropdown';
import { ProfileFormValidationSchema, UseShepherdValues } from '../utils/constants';
import {
  FormContainer, Title, Subtitle, ProfileForm,
} from '../utils/styles';
import Scrollbar from '../../../../shared/components/scrollbar';

interface Props {
  handleNextStep: (newData: PersonalOnboardingData) => void,
  formValues: PersonalOnboardingData
}

const ProfileInfoForm = ({ handleNextStep, formValues }:Props) => {
  const {
    title, subtitle, firstName, lastName, useShepherd,
  } = PersonalOnboardingFormModel.formP1;

  const handleOnSubmit = (values: PersonalOnboardingData) => {
    handleNextStep(values);
  };

  return (
    <Scrollbar maxHeight="100vh">
      <Formik
        validateOnMount
        initialValues={formValues}
        onSubmit={handleOnSubmit}
        validationSchema={ProfileFormValidationSchema}
      >
        {({
          values, isValid, errors, touched, handleBlur, handleChange, handleSubmit,
        }: FormikProps<FormikValues>) => (
          <FormContainer>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            <ProfileForm onSubmit={(event) => { event.preventDefault(); handleSubmit(); }}>
              <OnboardingInput
                inputData={firstName}
                errors={errors.firstName}
                touched={touched.firstName}
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.firstName}
              />
              <OnboardingInput
                inputData={lastName}
                errors={errors.lastName}
                touched={touched.lastName}
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.lastName}
              />
              <OnboardingSingleSelectDropdown
                data={{ data: useShepherd, options: UseShepherdValues }}
                functions={{ handleBlur, handleChange }}
              />
              <OnboardingSubmitButton type="submit" text="Next" isDisabled={!isValid} />
            </ProfileForm>
          </FormContainer>
        )}
      </Formik>
    </Scrollbar>

  );
};

export default ProfileInfoForm;
