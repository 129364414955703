import { useField } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import DownArrow from '../../../../../shared/icons/downArrow';
import UpArrow from '../../../../../shared/icons/upArrow';
import useDetectOutsideClick from '../../../../../utils/hook/detectOutsideClick';
import {
  DropdownData, DropdownFunctions, DropdownOption, DropdownOptions,
} from '../../utils/types';
import {
  Container, DropdownContainer, DropdownInput, DropdownInputContainer,
  DropdownMenu, ErrorMessage, HiddenLabel, InputStyle, VisibleLabel,
} from './utils/styles';

interface DropdownProps {
  data: DropdownData,
  functions: DropdownFunctions,
  options?: DropdownOptions,
}

const OnboardingSingleSelectDropdown = ({
  data,
  functions,
  options = { searchable: false, otherOption: false },
}:DropdownProps) => {
  const {
    id, name, label, placeholder,
  } = data.data;

  const dropdownRef = useRef(null);

  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const [isTouched, setIsTouched] = useState(false);
  const [otherSelected, setOtherSelected] = useState(false);

  useEffect(() => {
    if (isTouched) {
      setTouched(true);
    }
  }, [isTouched]);

  const [, meta, helpers] = useField(name);

  const { value, error, touched } = meta;
  const { setValue, setTouched } = helpers;

  const onClick = () => {
    setIsActive(true);
    setIsTouched(true);
  };

  const showErrorMessage = !isActive && error && touched;

  useEffect(() => {
    setInput(value);
  }, [value]);

  const [input, setInput] = useState(value);
  const [filteredOptions, setFilteredOptions] = useState<Array<DropdownOption>>(data.options);

  const onInputChange = (event:any) => {
    let suggestions:any[] = data.options;
    const inputValue = event.target.value;
    if (inputValue.length > 0) {
      const regex = new RegExp(`${inputValue}`, 'i');
      suggestions = data.options.sort().filter((option) => regex.test(option.value));
    }

    setInput(inputValue);
    setFilteredOptions(suggestions);
    setValue(inputValue);
  };

  const handleOptionSelected = (selectedValue:any) => {
    setInput(selectedValue);
    setFilteredOptions(data.options);
    setValue(selectedValue);
    setIsActive(false);

    if (selectedValue === 'Other') {
      setOtherSelected(true);
      setValue('');
      setTouched(false);
      return;
    }

    setOtherSelected(false);
  };

  const renderDropdown = () => {
    if (filteredOptions.length === 0) return null;

    if (isActive) {
      return (
        <DropdownMenu>
          {filteredOptions.map((option) => (
            <li key={option.id}>
              <button type="button" onClick={() => handleOptionSelected(option.value)}>
                {option.value}
              </button>
            </li>
          ))}
        </DropdownMenu>
      );
    }

    return null;
  };

  const renderOtherField = () => {
    if (otherSelected) {
      return (
        <InputStyle
          id={id}
          name={name}
          placeholder={placeholder}
          autoComplete="off"
          onBlur={functions.handleBlur}
          onChange={functions.handleChange}
          value={value}
          input={value}
          autoFocus
        />
      );
    }

    return null;
  };

  return (
    <Container>
      <HiddenLabel htmlFor={id} />
      <VisibleLabel>{label}</VisibleLabel>
      <DropdownContainer id={id} ref={dropdownRef}>
        <DropdownInputContainer onClick={onClick} input={otherSelected ? 'Other' : input} dropdownActive={isActive}>
          <DropdownInput
            onChange={onInputChange}
            placeholder={placeholder}
            value={otherSelected ? 'Other' : input}
            type="text"
            disabled={!options.searchable}
          />
          {isActive ? <UpArrow /> : <DownArrow />}
        </DropdownInputContainer>
        {renderDropdown()}
        {renderOtherField()}
      </DropdownContainer>
      <ErrorMessage>{showErrorMessage && error}</ErrorMessage>
    </Container>
  );
};

OnboardingSingleSelectDropdown.defaultProps = {
  options: {
    searchable: false,
    otherOption: false,
  },
};

export default OnboardingSingleSelectDropdown;
