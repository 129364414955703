/* eslint-disable no-unused-vars */
import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { Scrollbar as ScrollbarType, ScrollStatus } from 'smooth-scrollbar/interfaces';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import AllNotesTemplate from '.';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { UserCenterPage } from '../../shared/types/types';
import { makeGoogleCalendarUrl, makeMeetingUrl } from '../../utils/meetings/meetingsUtils';
import { PENDING, USER_CENTER_ALL_NOTES } from '../../utils/enums';
import useMeetingsAndUsersStore from '../../zustand/useAllMeetingsStore';
import { getOrMakeMeetingByEventAndCalendarId } from '../googleCalendar';
import { AuthContext } from '../../App';

interface Props {
  section: UserCenterPage;
  setSection: Dispatch<SetStateAction<UserCenterPage>>;
}

const AllNotesPageCtrl = ({ section, setSection }: Props) => {
  const { meetings, resolveState, fetchMeetings } = useMeetingsAndUsersStore();
  const [daysAgo] = useState<number>(6);
  const [error, setError] = useState<any>({});
  const [isLoadingGoToMeeting, setIsLoadingGoToMeeting] = useState<boolean>(false);
  const authState = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (section !== USER_CENTER_ALL_NOTES) return;
    fetchMeetings(daysAgo);
  }, [section]);

  const handleGoToMeeting = async (eventId: string, calendarId: string) => {
    ConsoleImproved.log('handleToGoMeeting', { eventId, calendarId });
    setIsLoadingGoToMeeting(true);
    await getOrMakeMeetingByEventAndCalendarId(eventId, calendarId, authState, history, setError);
    setTimeout(() => {
      setSection('currentMeeting');
      setIsLoadingGoToMeeting(false);
    }, 100); // Wait 100 to smoothly navigate the user. If not it will flicker
  };

  const onScroll = (status: ScrollStatus, scrollbar: ScrollbarType | null) => {
    if (status.limit.y === status.offset.y) {
      console.log('bottom');
    }
  };

  const isLoading = resolveState === PENDING || isLoadingGoToMeeting;

  return (
    <AllNotesTemplate
      section={section}
      notes={meetings}
      isLoading={isLoading}
      onAction={handleGoToMeeting}
      onScroll={onScroll}
    />
  );
};

export default AllNotesPageCtrl;
