import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  width: 256px;
`;

interface Props {
  src: string;
}

const ImageAtom = ({ src }: Props) => (
  <Image src={src} />
);

export default ImageAtom;
