/* eslint-disable no-unused-vars */
import { findPositionOfNodeBefore } from '@meetshepherd/prosemirror-utils';
import { uuid4 } from '@sentry/utils';
import { NodeType, Schema } from 'prosemirror-model';
import { TextSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { UserDataContext } from '../../../../App';
import MentionAPI from '../../../../external/Mentions/MentionAPI';
import { toastWarning } from '../../../../utils/notifications';
import ConsoleImproved from '../../../classes/ConsoleImproved';
import {
  blue1,
  blue2,
  gray1, gray2, gray3, gray4, gray6, surface,
} from '../../../colours';
import { AssigneeV2 } from '../../../types/types';
import { small, uiText, uiTextMedium } from '../../../typography';
import useDetectClickOutside from '../hooks/fixed-click-outside';
import { offsetBoundByproduct } from '../logic/computation/offsets';
import taskAdapter from '../logic/nodes/task-adapter';
import mentionsAdapter from '../logic/suggestions/mentions-adapter';
import CreateNewTaskIcon from './icons/create-new-task';
import MentionThisPersonIcon from './icons/mention-this-person';

interface MenuProps {
  show: boolean;
  top: number;
  left: number;
}

const Menu = styled.div<MenuProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  max-height: 368px;
  position: absolute;
  width: 242px;
  background-color: ${gray1};
  border-radius: 8px;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  z-index: 1000;
  box-shadow: 0px 10px 18px rgba(0,0,0,0.14),0px 0px 1px rgba(0,0,0,0.25);
  flex-flow: column;
  user-select: none;
  gap: 4px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface MenuItemProps {
  selected?: boolean;
}

const MenuItem = styled.div<MenuItemProps>`
  padding: 10px 14px;
  display: flex;
  flex-flow: row;
  align-items: center;

  ${uiText}
  ${({ selected }) => (selected ? `background-color: ${gray3};` : '')}

  &:hover {
    cursor: pointer;
    background-color: ${({ selected }) => (selected ? gray4 : gray3)};
  }

  & svg {
    margin-right: 10px;
  }
`;

MenuItem.defaultProps = {
  selected: false,
};

interface MenuItemPhotoProps {
  url?: string;
}

const MenuItemPhoto = styled.div<MenuItemPhotoProps>`
  width: 22px;
  min-width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: ${gray4};
  ${({ url }) => `background-image: url(${url});`}
  background-position: center;
  background-size: cover;
  margin-right: 8px;
`;

MenuItemPhoto.defaultProps = {
  url: '',
};

const MenuItemText = styled.div`
overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  margin-right: 8px;
`;

const MenuItemSubText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  ${uiText}
`;

interface MentionsFloatingPanelProps {
  show: boolean,
  left: number,
  top: number,
  contents: unknown[],
  selectedIndex: number,
  showPromotion: boolean,
  view?: EditorView | null,
}

const MentionsFloatingPanel = ({
  show,
  left,
  top,
  contents,
  selectedIndex,
  showPromotion,
  view,
}: MentionsFloatingPanelProps) => {
  const selectedRef = useRef(null);
  const userData = useContext(UserDataContext);

  useEffect(() => {
    if (selectedRef.current) {
      (selectedRef.current as unknown as HTMLDivElement).scrollIntoView({
        block: 'nearest',
      });
    }
  }, [selectedIndex, contents, show]);

  const handleSlackNotification = () => {
    // This will be finished when I know more about the mentions and when they are actually working
  };

  const handleClick = (value: { item: AssigneeV2 }) => {
    if (!view) {
      return;
    }
    const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('mentions-suggesting')[0], 0);
    const e = value.item;
    if (!e || !currentPos) return;
    const { tr } = view.state;
    const mention: NodeType = (view.state.schema as Schema).nodes.resolvedMention;
    tr.replaceRangeWith(
      currentPos,
      view.state.selection.$anchor.pos,
      mention.create({
        name: e.data.name,
        email: e.data.email,
        'promote-chance': false,
        userjson: JSON.stringify(e),
        uid: uuid4(),
      }, [(view.state.schema as Schema).text(e.data.name || e.data.email)]),
    );
    view.dispatch!(tr);

    mentionsAdapter.setSelection(0);
    mentionsAdapter.setShow(false);
    handleSlackNotification();
  };

  const handleConfirm = () => {
    if (!view) return false;
    view.dispatch(view.state.tr.insertText(' '));

    try {
      const startPos = view!.posAtDOM(
        view.domAtPos(
          findPositionOfNodeBefore(
            view.state.selection,
          )! - 1,
        ).node!,
        0,
      ) - 1;

      const prevNode = view.state.doc.nodeAt(startPos);

      const c = taskAdapter.getCurrentUser();
      const m = taskAdapter.getCurrentMeetingData();
      // For backwards compatibility, the task adapter isn't set in the older versions.
      if (c && m && typeof prevNode?.attrs?.userjson === 'string') {
        MentionAPI.createMention(
          JSON.parse(prevNode.attrs.userjson).data.email,
          c.email,
          c.email,
          {
            meetingId: m.meetingId,
            startDate: m.date.start,
            tags: m.tags.tags.map((t) => t.name),
            name: m.name,
          },
          '',
        );
      }
    } catch (err) {
      console.debug('Could not notify!');
    }

    return true;
  };

  const handlePromote = () => {
    if (!view) return;
    const taskType: NodeType = view.state.schema.nodes.task;
    try {
      const startPos = view!.posAtDOM(
        view?.domAtPos(
          findPositionOfNodeBefore(
            view.state.selection,
          )! - 1,
        ).node!,
        0,
      ) - 1;

      const prevNode = view.state.doc.nodeAt(startPos);

      const endPos = view.state.selection.$anchor.pos;

      const { tr } = view.state;

      if (typeof prevNode?.attrs.userjson === 'string') {
        const json = JSON.parse(prevNode.attrs.userjson);
        ConsoleImproved.log('Failed to create a task', json);
        toastWarning('Error', 'Can\'t create a task. Try to use your keyboard to create a task');
        return;
      }

      tr.replaceRangeWith(
        startPos,
        endPos,
        taskType.create({
          status: 'todo',
          userjson: prevNode?.attrs.userjson || '{}',
          id: uuid4(),
        }),
      );
      tr.setSelection(TextSelection.create(tr.doc, findPositionOfNodeBefore(
        tr.selection,
      )! - 1));
      view.dispatch(tr);
    } catch (e) {
      console.error('Failed to promote mention!', e);
    }
  };

  const ref = useDetectClickOutside({
    onTriggered: () => {
      mentionsAdapter.setShow(false);
    },
  });

  const menuContentContainerRef = useRef<HTMLDivElement | null>(null);
  let menuBoundingBox: DOMRect | null = null;
  try {
    if (menuContentContainerRef.current?.parentElement && view && view.dom.parentElement) {
      const menuDOM = menuContentContainerRef.current.parentElement;
      const menuRect = menuDOM.getBoundingClientRect();
      const positionedMenuRect = new DOMRect(
        left,
        top,
        menuRect.width,
        menuRect.height,
      );
      const editorRect = view.dom.parentElement.getBoundingClientRect();
      const offset = offsetBoundByproduct(positionedMenuRect, editorRect);
      menuBoundingBox = new DOMRect(
        positionedMenuRect.x + offset.left,
        positionedMenuRect.y + offset.top,
        menuRect.width,
        menuRect.height,
      );
    }
  } catch (error) {
    console.error(error);
  }

  return (
    <Menu
      show={(show || showPromotion) && menuBoundingBox != null}
      left={menuBoundingBox ? menuBoundingBox.x : left}
      top={menuBoundingBox ? menuBoundingBox.y : top}
      ref={ref}
    >
      <div ref={menuContentContainerRef}>
        { show
          && contents.map((value, index) => (
            <MenuItem
              selected={index === selectedIndex}
              key={uuid4()}
              ref={index === selectedIndex ? selectedRef : null}
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClick(value as any);
              }}
            >
              <MenuItemPhoto
                url={(value as any)?.item?.data.photoUrl}
              />
              <MenuItemText>
                {`${(value as any).item?.data.name || (value as any).item?.data.email}`}
              </MenuItemText>
              {/* <MenuItemSubText>
                {`${(value as any).item?.data.email}`}
              </MenuItemSubText> */}
            </MenuItem>
          ))}
        { showPromotion
          /**
           * There could be conflicts here, in the case
           * where show and showPromotion are both true.
           * However, adding "&& !show" seems to cause
           * a larger render break between the two lists,
           * which might not be desirable.
           */
          && (
            <>
              <MenuItem
                selected={selectedIndex === 0}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleConfirm();
                }}
              >
                <MentionThisPersonIcon fill={surface} />
                <MenuItemText>Mention this person</MenuItemText>
              </MenuItem>
              <MenuItem
                selected={selectedIndex === 1}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handlePromote();
                }}
              >
                <CreateNewTaskIcon fill={surface} />
                <MenuItemText>Create new task</MenuItemText>
              </MenuItem>
            </>
          )}
      </div>
    </Menu>
  );
};

MentionsFloatingPanel.defaultProps = {
  view: null,
};

export default MentionsFloatingPanel;
