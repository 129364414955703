import React from 'react';
import { ScrollStatus, Scrollbar as ScrollbarType } from 'smooth-scrollbar/interfaces';
import styled from 'styled-components';
import { gray1, surface } from '../../shared/colours';
import MeetingLoadingPage from '../../shared/components/loading/shepherd-logo-loading-page/MeetingLoadingPage';
import { GapiMeetingData, UserCenterPage } from '../../shared/types/types';
import { USER_CENTER_ALL_NOTES } from '../../utils/enums';
import { groupMeetingsByDate } from '../../utils/meetings/meetingsUtils';
import AllNotesHeaderMolecule from './AllNotesHeaderMolecule';
import NotesListOrganism from './notes-list/NotesListOrganism';

const BackgroundColorContainer = styled.div`
  background-color: ${surface};
  height: 100%;
`;

const Container = styled.div`
  background-color: ${gray1};
  padding: 24px 16px 24px 16px;
  height: 100%;
  border-radius: 24px 24px 0 0;
`;

interface Props {
  section: UserCenterPage;
  notes: GapiMeetingData[];
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onAction: (eventId: string, calendarId: string) => void,
  // eslint-disable-next-line no-unused-vars
  onScroll: (status: ScrollStatus, scrollbar: ScrollbarType | null) => void,
}

const AllNotesTemplate = ({
  section, notes, isLoading, onAction, onScroll,
}: Props) => {
  const groupedNotes = groupMeetingsByDate(notes);

  if (section !== USER_CENTER_ALL_NOTES) return null;

  if (isLoading) {
    return <MeetingLoadingPage text="Fetching Meeting Data..." />;
  }

  return (
    <BackgroundColorContainer>
      <Container>
        <AllNotesHeaderMolecule />
        {/* <NotesFilterBoardOrganism /> */}
        <NotesListOrganism groupedNotes={groupedNotes} onAction={onAction} onScroll={onScroll} />
      </Container>
    </BackgroundColorContainer>
  );
};

export default AllNotesTemplate;
