import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { PrivateNoteData } from '../../../../../types/types';
import ModalFooterWrapper from '../../../../Modal/ModalFooterWrapper';
import { error6 } from '../../../../../colours';
import { uiTextMedium } from '../../../../../typography';
import ButtonSmall from '../../../../button-small';

const DeletePrivateNoteButton = styled.div`
  color: ${error6};
  background: none;
  ${uiTextMedium};

  &:hover {
    cursor: pointer;
  }
`;

const CancelAndUpdatePrivateNoteButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button:first-of-type {
    margin-right: 8px;
  }
`;

interface Props {
  privateNote: PrivateNoteData,
  onClickCloseModal: () => void,
  /* eslint-disable-next-line */
  onClickDeletePrivateNote: (chat: PrivateNoteData) => void,
  onClickUpdatePrivateNoteTitleAndMembers: () => void,
}

const EditPrivateNoteModalFooter = ({
  privateNote,
  onClickCloseModal,
  onClickDeletePrivateNote,
  onClickUpdatePrivateNoteTitleAndMembers,
}:Props) => {
  const onClickHandleDelete = (event: MouseEvent) => {
    event.stopPropagation();
    onClickDeletePrivateNote(privateNote);
  };

  return (
    <ModalFooterWrapper>
      <DeletePrivateNoteButton onClick={(event) => onClickHandleDelete(event)}>
        Delete Private note
      </DeletePrivateNoteButton>
      <CancelAndUpdatePrivateNoteButtonContainer>
        <ButtonSmall text="Cancel" onClick={onClickCloseModal} isOutline />
        <ButtonSmall text="Update" onClick={onClickUpdatePrivateNoteTitleAndMembers} />
      </CancelAndUpdatePrivateNoteButtonContainer>
    </ModalFooterWrapper>
  );
};

export default EditPrivateNoteModalFooter;
