/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import TurndownService from 'turndown';
import mdToSlackBlocks from '../../utils/slack/SlackFormatting';
import { AuthContext, UserDataContext } from '../../App';
import { gray4 } from '../../shared/colours';
import ButtonSmall from '../../shared/components/button-small';
import TitleAtom from '../../shared/components/new-feaures-modal/TitleAtom';
import { SCOPES } from '../../utils/constants';
import { functions } from '../../utils/firebase';
import { gapiGetRecurringEventMeetings, getMeetingsInPeriod, getThisWeeksUpcomingMeetings } from '../../utils/google/GoogleCalendarAPI';
import { toastInfo } from '../../utils/notifications';
import ShareNotesModal from '../meeting/modal/ShareModal';
import { cfSearchPublicUserDataV2ByEmailsV2 } from '../../external/publicUserData/PublicUserDataAPI';
import { dbGetSimpleUserDataByUserId } from '../../database/firebaseUsersAPI';
import { mapGoogleMeetingToDatabaseMeetingData } from '../../database/utils/templateMeetingData';
import { GapiMeetingData, SimpleUserData } from '../../shared/types/types';
import TrelloSettingModalWrapper from '../meeting/modal/TrelloModalComponents/TrelloSettingModalWrapper';
import MyNotesSharePromptModal from '../meeting/modal/ShareModal/Molecule/MyNotesSharePromptModal';
import ArrowRight from '../../shared/icons/arrowRight';
import ArrowRight2 from '../../shared/icons/ArrowRight2';

declare let gapi: any;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 30px;
  background-color: ${gray4};
  height: 100vh;

  > * {
    margin-bottom: 20px;
  }
`;

const AsishTestingPage = () => {
  const authData = useContext(AuthContext);
  const userData = useContext(UserDataContext);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    console.log('User Data');
    console.log(userData);
    return () => { };
  }, [authData, userData]);

  const handleClick1 = () => {
    toastInfo('Clicked', 'Button 1 clicked');

    gapi.auth2.getAuthInstance().currentUser.get().grantOfflineAccess({
      access_type: 'offline',
      prompt: 'consent',
      scopes: SCOPES,
    }).then((data:any) => {
      console.log(data);
      functions().httpsCallable('getGoogleTokens2')({
        userId: userData.userId,
        exchangeCode: data.code,
      }).then((response) => {
        console.log('response', response);
      });
    });
  };
  const handleClick2 = () => {
    toastInfo('Clicked', 'Button 2 clicked');
    getThisWeeksUpcomingMeetings().then((data) => {
      console.log('date here', data);
    }).catch((error:any) => {
      console.log(error);
    });
  };
  const handleClick3 = () => {
    toastInfo('Clicked', 'Button 3 clicked');
    functions().httpsCallable('checkNotificationPermission')({
      userId: userData.userId,
    }).then((data) => {
      console.log(data);
    });
  };
  const handleClick4 = () => {
    const turndownService = new TurndownService();
    const html = `
<div><strong>Asish</strong> mentioned you in <strong>PH Launch meet</strong></div>
<div><a href="https://www.w3schools.com">Click here</a>to view and edit the notes</div>
`;
    const slackBlocks = mdToSlackBlocks(turndownService.turndown(html));
    console.log(JSON.stringify(slackBlocks));
  };

  const handleClick5 = () => {
    gapiGetRecurringEventMeetings('vashish888@gmail.com', '2p657pv4uahd1fa32itlh8mn4m').then(async (data: GapiMeetingData[]) => {
      console.log(data);
      const user: SimpleUserData = await dbGetSimpleUserDataByUserId(userData.userId, 'viewer');
      const newMeeting = data.map((googleMeeting) => mapGoogleMeetingToDatabaseMeetingData(
        googleMeeting,
        'vashish888@gmail.com',
        user,
      ));
      // const mmt = newMeeting.sort(
      //   (x, y) => Date.parse(x.date.start.date) - Date.parse(y.date.start.date),
      // );
      // console.log(mmt);
      // functions().httpsCallable('updateMeetingsFromGAPItoDB')({
      //   meetings: newMeeting,
      // }).then((dataR: any) => {
      // });
    });
  };

  const handleClick6 = () => {
    setIsOpen(true);
  };
  const close = () => {
    setIsOpen(false);
  };

  return (
    <Container>
      <TitleAtom>Asish Testing Page</TitleAtom>
      <ButtonSmall text="Click 1" onClick={handleClick1} Icon={ArrowRight2} hasLeftIcon />
      <ButtonSmall text="Click 2" onClick={handleClick2} />
      <ButtonSmall text="Click 3" onClick={handleClick3} />
      <ButtonSmall text="Click 4" onClick={handleClick4} />
      <ButtonSmall text="Click 5" onClick={handleClick5} />
      <ButtonSmall text="Click 6" onClick={handleClick6} />
      <ArrowRight2 />
      {/* <MyNotesSharePromptModal isOpen={isOpen} handleCloseClick={close} /> */}
    </Container>
  );
};

export default AsishTestingPage;
