import React, {
  Dispatch, SetStateAction,
} from 'react';
import {
  IntegrationsTabView, SlackChannel, TrelloData,
} from '../../../../shared/types/types';

import SlackSelectDefaultChannel from '../SlackModalComponents/SlackSelectDefaultChannel';
import {
  NOTION_PROCESSING_VIEW, OVERVIEW_VIEW, SLACK_NOTIFICATIONS_VIEW, SLACK_PROCESSING_VIEW,
  SLACK_SELECT_DEFAULT_CHANNEL_VIEW, TRELLO_PROCESSING_VIEW,
} from '../../../../utils/constants';
import SlackNotificationsModalCtrl from '../../../../shared/components/quick-settings/notification/slack-notifications/SlackNotificationsModalCtrl';
import { SETTINGS_MODAL_LOCATION, SHARE_MODAL_LOCATION } from '../../../../utils/analytics/enums';
import SlackIntegrationProcessStatus from '../SlackModalComponents/SlackIntegrationProcessStatus';
import NotionIntegrationProcessStatus from '../NotionModalComponents/NotionIntegrationProcessStatus';
import TrelloIntegrationProcessStatus from '../TrelloModalComponents/TrelloIntegrationProcessStatus';
import ComingSoonApps from './ComingSoonApps';
import IntegratedApps from './IntegratedApps';
import NotIntegratedApps from './NotIntegratedApps';

interface Props {
  isSlackEnabled: boolean,
  isNotionEnabled: boolean,
  isTrelloEnabled: boolean,
  view: IntegrationsTabView,
  setView: Dispatch<SetStateAction<IntegrationsTabView>>,
  setNewDefaultChannels: Dispatch<SetStateAction<SlackChannel[]>>,
  trelloData: TrelloData,
  setTrelloData: Dispatch<SetStateAction<TrelloData>>,
  slackChannels: SlackChannel[],
  setActiveConfetti: Dispatch<SetStateAction<boolean>>,
  setIsIntegratingSlack: Dispatch<SetStateAction<boolean>>,
  isIntegratingSlack: boolean,
  // field for analytics, tells where the tab is opened from
  analyticsFrom?: typeof SHARE_MODAL_LOCATION | typeof SETTINGS_MODAL_LOCATION;
}

const IntegrationsTab = ({
  isSlackEnabled, isNotionEnabled, isTrelloEnabled, analyticsFrom = SHARE_MODAL_LOCATION,
  view, setView, slackChannels, setNewDefaultChannels, trelloData,
  setTrelloData, setActiveConfetti, setIsIntegratingSlack, isIntegratingSlack,
}: Props) => {
  const isSlackNotificationModalOpen = view === SLACK_NOTIFICATIONS_VIEW;

  const onCloseSlackNotificationModal = () => {
    setIsIntegratingSlack(false);
    setView(OVERVIEW_VIEW);
  };

  if (view === OVERVIEW_VIEW) {
    return (
      <>
        <IntegratedApps
          setTabView={setView}
          isSlackIntegrated={isSlackEnabled}
          isNotionIntegrated={isNotionEnabled}
          isTrelloIntegrated={isTrelloEnabled}
        />
        <NotIntegratedApps
          isSlackEnabled={isSlackEnabled}
          isNotionIntegrated={isNotionEnabled}
          isTrelloIntegrated={isTrelloEnabled}
          setView={setView}
          setIsIntegratingSlack={setIsIntegratingSlack}
          analyticsFrom={analyticsFrom}
        />
        <ComingSoonApps />
      </>
    );
  }
  if (view === SLACK_PROCESSING_VIEW) {
    return (
      <SlackIntegrationProcessStatus />
    );
  }
  if (view === SLACK_SELECT_DEFAULT_CHANNEL_VIEW) {
    return (
      <SlackSelectDefaultChannel
        slackChannels={slackChannels}
        setNewDefaultChannels={setNewDefaultChannels}
      />
    );
  }
  if (view === SLACK_NOTIFICATIONS_VIEW) {
    return (
      <SlackNotificationsModalCtrl
        isOpen={isSlackNotificationModalOpen}
        onClose={onCloseSlackNotificationModal}
        isIntegratingSlack={isIntegratingSlack}
      />
    );
  }
  if (view === NOTION_PROCESSING_VIEW) {
    return (
      <NotionIntegrationProcessStatus setActiveConfetti={setActiveConfetti} />
    );
  }
  if (view === TRELLO_PROCESSING_VIEW) {
    return (
      <TrelloIntegrationProcessStatus
        setTrelloData={setTrelloData}
        trelloData={trelloData}
      />
    );
  }
  return null;
};

IntegrationsTab.defaultProps = {
  analyticsFrom: SHARE_MODAL_LOCATION,
};

export default IntegrationsTab;
