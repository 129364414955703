import React from 'react';
import styled from 'styled-components';
import QuickSettings from '../quick-settings';
import ShepherdLogo from '../../icons/shepherd/ShepherdLogo';
import CloseSquareIcon from '../../icons/closeSquare';
import SendCloseSidebarWindowMessage from '../../../utils/sendWindowMessages/closeSidebar';
import AddUserAtom from './AddUserAtom';
import {
  Container, LeftContainer, IconContainer, IconGroupContainer,
} from './utils/styles';
import { DEFAULT_HEADER_MEETING_VIEW, HEADER_DASHBORD_VIEW } from './utils/constants';
import { useUI } from '../../../pages/all-pages/ui-context/uiContext';
import { TopHeaderState } from '../../types/types';
import OpenLinkIcon from '../../icons/OpenLink';
import ReactTooltip from '../tooltip/ReactTooltip';

export interface Props {
  view?: TopHeaderState
}

const Header = ({ view = DEFAULT_HEADER_MEETING_VIEW }: Props) => {
  const { openInviteMemberModal } = useUI();
  const handleCloseSidebarClick = () => {
    SendCloseSidebarWindowMessage();
  };
  const handleOpenInFullScreenClick = () => {
    window.open(window.location.href);
  };

  const ShepherdLogoStyled = styled.div`
    align-items: center;
    display: flex;
    margin-left: 45px;
    @media only screen and (min-width: 1280px){
      svg {
        display: none;
      }
    }
    @media only screen and (max-width: 1280px){
      svg {
        display: block;
      }
    }
  `;

  const IconWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 10px;
    @media only screen and (min-width: 1280px){
      svg {
        display: none;
      }
    }
    @media only screen and (max-width: 1280px){
      svg {
        display: block;
      }
    }
  `;

  const renderSettingsAndLogo = () => {
    if (view === DEFAULT_HEADER_MEETING_VIEW || view === HEADER_DASHBORD_VIEW) {
      return (
        <>
          <LeftContainer>
            <QuickSettings />
          </LeftContainer>
          <ShepherdLogoStyled>
            <ShepherdLogo />
          </ShepherdLogoStyled>
        </>
      );
    }

    return null;
  };

  return (
    <Container view={view}>
      {renderSettingsAndLogo()}

      <IconGroupContainer>
        <AddUserAtom onClick={openInviteMemberModal} />
        <IconWrapper>
          <ReactTooltip tip="Open Shepherd in Full Screen" place="bottom">
            <IconContainer onClick={handleOpenInFullScreenClick}>
              <OpenLinkIcon width="24" height="24" />
            </IconContainer>
          </ReactTooltip>
          <IconContainer onClick={handleCloseSidebarClick}>
            <CloseSquareIcon width="24" height="24" />
          </IconContainer>
        </IconWrapper>
      </IconGroupContainer>
    </Container>
  );
};

Header.defaultProps = {
  view: DEFAULT_HEADER_MEETING_VIEW,
};

export default Header;
