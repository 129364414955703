import { Dispatch, SetStateAction } from 'react';
import { dbDeleteSecretChat } from '../../../../database/firebasePrivateNotesAPI';
import {
  Assignee,
  AssigneeV2,
  AttendeeProfile,
  AttendeeV2,
  PrivateNote,
  PrivateNoteAttendeeV2,
  PrivateNoteData,
  PrivateNoteHtml,
} from '../../../types/types';
import { dateISOObject } from '../../../../utils/dateUtils/date';
import { toastDanger } from '../../../../utils/notifications';
import { emptyPublicUserDataV2 } from '../../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';

export const mapAttendeeProfileToAssignee = (attendees: AttendeeProfile[]) => {
  const assignees: Assignee[] = [];
  attendees.forEach((attendee) => {
    assignees.push({
      name: attendee.name,
      email: attendee.email,
      photoUrl: attendee.photoUrl,
      userId: attendee.userId,
    });
  });
  return assignees;
};

export const mapAttendeeV2ToAssigneeV2 = (attendees: AttendeeV2[]): AssigneeV2[] => {
  const assignees: AssigneeV2[] = [];
  attendees.forEach((attendee) => {
    assignees.push({
      resolvedState: attendee.resolvedState,
      userId: attendee.userId,
      isShepherdUser: attendee.isShepherdUser,
      data: attendee.data,
      external: attendee.external,
    });
  });
  return assignees;
};

export const mapAssigneeToAttendeeProfile = (assignees: Assignee[]) => {
  const attendees: AttendeeProfile[] = [];
  assignees.forEach((assignee) => {
    attendees.push({
      name: assignee.name,
      email: assignee.email,
      photoUrl: assignee.photoUrl,
      userId: assignee.userId,
      responseStatus: '',
    });
  });
  return attendees;
};

export const mapDatabaseDataToPrivateNotesData = (
  dbData: any,
  userId: string,
  userEmail: string,
): PrivateNoteData[] => {
  const secretNotes: PrivateNoteData[] = [];
  dbData.forEach((data: any) => {
    secretNotes.push({
      chatId: data.id,
      title: data.data()?.title ?? 'Private note',
      chatPathInRealtimeDb: data.data()?.chatPathInRealtimeDb ?? '',
      userIds: data.data()?.userIds ?? [],
      members: data.data()?.members ?? [],
      meetingId: data.data()?.meetingId ?? [],
      created: data.data()?.created ?? dateISOObject,
      updated: data.data()?.updated ?? dateISOObject,
      creator: data.data()?.creator ?? {},
    });
  });
  const chats = returnSecretChatsDataCurrentUserIsPartOf(secretNotes, userEmail, userId);
  return chats;
};

export const returnSecretChatsCurrentUserIsPartOf = (
  secretChats: PrivateNote[],
  userEmail: string,
  userId: string,
): PrivateNote[] => {
  const filteredChats: PrivateNote[] = [];
  secretChats.forEach((secretChat) => {
    secretChat.members.forEach((member) => {
      if (member.data.email === userEmail || member.userId === userId) {
        filteredChats.push(secretChat);
      }
    });
  });
  return filteredChats;
};

export const returnSecretChatsDataCurrentUserIsPartOf = (
  secretChats: PrivateNoteData[],
  userEmail: string,
  userId: string,
): PrivateNoteData[] => {
  const filteredChats: PrivateNoteData[] = [];
  secretChats.forEach((secretChat) => {
    secretChat.members.forEach((member) => {
      if (member.data.email === userEmail || member.userId === userId) {
        filteredChats.push(secretChat);
      }
    });
  });
  return filteredChats;
};

export const emptyPrivateNoteData = (): PrivateNoteData => {
  const privateNote: PrivateNoteData = {
    chatId: '',
    title: '',
    created: dateISOObject(),
    updated: dateISOObject(),
    userIds: [],
    members: [],
    chatPathInRealtimeDb: '',
    meetingId: '',
    creator: emptyPublicUserDataV2,
  };
  return privateNote;
};

export const getPrivateNoteId = (privateNote: PrivateNoteData) => privateNote.chatId;

export const deletePrivateNote = async (
  userId: string,
  privateNote: PrivateNoteData,
  setPrivateNote: Dispatch<SetStateAction<PrivateNoteData[]>>,
  meetingId: string,
) => {
  const privateNoteId = getPrivateNoteId(privateNote);
  const resolvedState = await dbDeleteSecretChat(userId, meetingId, privateNoteId);
  if (resolvedState !== 'resolved') {
    toastDanger('Error deleting private note', 'There was an issue deleting the private note. Please refresh and try again.');
    return;
  }
  setPrivateNote((prev) => (prev.filter((note) => (
    note.chatId !== privateNote.chatId
  ))));
};

export const mapMemberIsNotified = (member: PrivateNoteAttendeeV2): PrivateNoteAttendeeV2 => ({
  isNotified: true,
  resolvedState: member.resolvedState,
  userId: member.userId,
  isShepherdUser: member.isShepherdUser,
  data: {
    name: member.data.name,
    email: member.data.email,
    firstName: member.data.firstName,
    lastName: member.data.lastName,
    photoUrl: member.data.photoUrl,
  },
  external: {
    email: {
      receiveTaskEmail: member.external.email.receiveTaskEmail,
    },
    slack: {
      hasEnabledSlack: member.external.slack.hasEnabledSlack,
      notifications: member.external.slack.notifications,
    },
    trello: {
      isTrelloEnabled: member.external.trello.isTrelloEnabled,
      isAutoSyncEnabled: member.external.trello.isAutoSyncEnabled,
      isAllowOtherToSyncEnabled: member.external.trello.isAllowOtherToSyncEnabled,
      version: member.external.trello.version,
    },
  },
});

export const returnNotifiedMembers = (privateNote: PrivateNoteData, currentUserId: string) => {
  const nonCurrentAttendees = privateNote.members.filter(
    (member) => member.userId !== currentUserId,
  );
  const currentAttendee = privateNote.members.filter((member) => member.userId === currentUserId);
  if (currentAttendee.length === 0) return privateNote.members;
  const updatedCurrentUser = mapMemberIsNotified(currentAttendee[0]);
  return [...nonCurrentAttendees, updatedCurrentUser];
};

export const sortSecretChatDataChronologically = (secretChatData: PrivateNoteData[]) => {
  if (secretChatData.length === 1) return secretChatData;
  return secretChatData.sort((a, b) => (b.created.timestamp - a.created.timestamp));
};

export const determinePrivateSpaceNotesToShare = (
  checkedNotes: string[],
  notes: PrivateNoteHtml[],
): PrivateNoteHtml[] => {
  const notesToSend: PrivateNoteHtml[] = [];
  if (checkedNotes.length === 0) return [];
  notes.forEach((note) => {
    if (checkedNotes.includes(note.noteId)) {
      notesToSend.push(note);
    }
  });
  return notesToSend;
};
