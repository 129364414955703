import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import { AuthContext, UserDataContext } from '../../../../App';
import Modal from '../../../../shared/components/Modal';
import {
  CALENDAR_SHARE_VIEW,
  EMAIL_SHARE_VIEW, NotesData, NotesSwitchData, NOTION_SHARE_VIEW, PlatformData,
  PlatformsMultiInputsData,
  ShareModalMode, SHARE_VIEW, SLACK_SHARE_VIEW, TRELLO_SHARE_VIEW,
} from './utils/types';
import ModalBody from './Molecule/ModalBody';
import ModalFooterButtons from './Molecule/ModalFooterButtons';
import {
  defaultNotesSwitchData,
  generateShareNotesData,
  getModalTitle, getNotesSwitchData, getPlatformData, isSendButtonEnabled,
  mapModeToPlatformName, shareNotesToGoogleCalendarEventPrefix,
} from './utils';
import MeetingDataContext from '../../context/MeetingDataContext';
import {
  ResolvedState, SharePlatforms, SlackChannel, TaskItem,
} from '../../../../shared/types/types';
import { determinePrivateSpaceNotesToShare } from '../../../../shared/components/private-notes/utils/functions';
import { assembleEmailHTML } from '../../../../utils/email/emailUtils';
import { makeSubstringsBold } from '../../../../utils/strings';
import { getUTCString } from '../../../../utils/dateUtils/date';
import {
  generateLog,
  generateNotionPage, generateSlackMessage, getMeetingSectionToShare, sendEmails, sendToTrello,
} from '../../../../utils/meetings/ShareNotesUtils';
import { toastDanger, toastSuccess } from '../../../../utils/notifications';
import { EmailRecipient } from '../ShareNotesModal';
import PreviewEmailModal from '../PreviewEmailModal';
import {
  EMAIL, NOTION, REJECTED, RESOLVED, SLACK, TRELLO,
} from '../../../../utils/enums';
import { appendTextToGoogleEventDescription } from '../../../../utils/google/GoogleCalendarAPI';

const Container = styled.div`
 padding: 24px 24px 0px 24px ;
`;

const ModalFooterContainer = styled.div``;

interface Props {
  isOpen: boolean,
  handleCloseClick: () => void,
  taskItems: TaskItem[],
  notesData: NotesData,
  privateSpaceNotesChecked: string[],
  setTriggerConfetti: Dispatch<SetStateAction<boolean>>
}

const ShareNotesModal = ({
  isOpen, handleCloseClick, taskItems,
  notesData, privateSpaceNotesChecked, setTriggerConfetti,
}:Props) => {
  const { trackEvent } = useIntercom();

  const userData = useContext(UserDataContext);
  const { integrations } = userData;
  const authData = useContext(AuthContext);
  const { googleData: { ids: { eventId } } } = useContext(MeetingDataContext);
  const {
    version, data: { title, description },
    date: { start, end },
  } = useContext(MeetingDataContext);
  const {
    agendaHtml, sharedNotesHtml, personalNotesHtml,
    privateNotesHtml, emailHeadTemplate, setEmailHeadTemplate,
  } = notesData;

  const [mode, setMode] = useState<ShareModalMode>(SHARE_VIEW);
  const [platformData, setPlatformData] = useState<PlatformData[]>([]);
  const [isExportButtonLoading, setIsExportButtonLoading] = useState<boolean>(false);
  const [isEmailPreviewModalOpen, setIsEmailPreviewModalOpen] = useState<boolean>(false);
  const [notesSwitchData, setNotesSwitchData] = useState<NotesSwitchData>(defaultNotesSwitchData);

  const [taskToSend, setTaskToSend] = useState<TaskItem[]>([]);
  const [emailRecipients, setEmailRecipients] = useState<EmailRecipient[]>([]);
  const [slackChannelsToReceive, setSlackChannelsToReceive] = useState<SlackChannel[]>([]);

  const platformsMultiInputsData: PlatformsMultiInputsData = {
    emailRecipients,
    setEmailRecipients,
    slackChannelsToReceive,
    setSlackChannelsToReceive,
    taskItems,
    taskToSend,
    setTaskToSend,
  };

  useEffect(() => {
    const updatedPlatformData = getPlatformData(integrations, setMode);
    setPlatformData(updatedPlatformData);
  }, [integrations]);

  useEffect(() => {
    const updatedNotesSwitchData = getNotesSwitchData(
      version, agendaHtml, sharedNotesHtml,
      personalNotesHtml, privateSpaceNotesChecked, taskItems,
    );
    setNotesSwitchData(updatedNotesSwitchData);
  }, [version, agendaHtml, sharedNotesHtml, personalNotesHtml, taskItems, privateNotesHtml]);

  const setupShareNotesData = (emailToSend: EmailRecipient[] = []) => {
    const {
      isAgendaNotesChecked, isAgendaNotesEnabled, isSharedNotesChecked,
      isPersonalNotesChecked, isTasksChecked,
    } = notesSwitchData;
    const emails = emailToSend.map(({ displayValue }) => ({ email: displayValue }));
    const emailHeadHtml = mode === CALENDAR_SHARE_VIEW ? '' : makeSubstringsBold(emailHeadTemplate, [title, getUTCString(start.date)]);
    const checkedPrivateNotesHtml = determinePrivateSpaceNotesToShare(
      notesSwitchData.isPrivateSpaceNotesChecked,
      privateNotesHtml,
    );
    const notesAndTasksHtml = assembleEmailHTML(
      isAgendaNotesChecked && isAgendaNotesEnabled,
      isSharedNotesChecked, isPersonalNotesChecked, isTasksChecked,
      emailHeadHtml, agendaHtml, sharedNotesHtml, personalNotesHtml,
      taskItems, privateSpaceNotesChecked, checkedPrivateNotesHtml,
    );
    return generateShareNotesData(
      authData,
      notesAndTasksHtml, title, start, notesSwitchData,
      emailHeadHtml, emailHeadTemplate, agendaHtml, sharedNotesHtml,
      personalNotesHtml, taskItems, checkedPrivateNotesHtml,
      privateSpaceNotesChecked, emails,
    );
  };

  const handleExport = () => {
    setIsExportButtonLoading(true);
    const promises: Promise<any>[] = [];
    const platformsToShare: SharePlatforms[] = [];

    if (mode === NOTION_SHARE_VIEW) {
      platformsToShare.push(NOTION);
      const shareNotesData = setupShareNotesData();
      generateNotionPage(shareNotesData, integrations.notion, promises);
    }

    if (mode === EMAIL_SHARE_VIEW) {
      platformsToShare.push(EMAIL);
      const shareNotesData = setupShareNotesData(emailRecipients);
      sendEmails(userData, shareNotesData, trackEvent, promises);
    }

    if (mode === SLACK_SHARE_VIEW) {
      platformsToShare.push(SLACK);
      const shareNotesData = setupShareNotesData();
      generateSlackMessage(
        shareNotesData,
        slackChannelsToReceive,
        integrations.slack[0].userAccessToken,
        promises,
      );
    }

    if (mode === TRELLO_SHARE_VIEW) {
      platformsToShare.push(TRELLO);
      sendToTrello(true, taskToSend, integrations.trello, promises);
    }

    if (mode === CALENDAR_SHARE_VIEW) {
      const shareNotesData = setupShareNotesData();
      shareNotesData.html = shareNotesToGoogleCalendarEventPrefix + shareNotesData.html;
      promises.push(appendTextToGoogleEventDescription(eventId,
        authData.email, description, shareNotesData.html));
    }

    Promise.all(promises)
      .then(() => {
        handleLog(RESOLVED, platformsToShare);
        setTriggerConfetti(true);
        toastSuccess('Success', `Notes successfully sent to ${mapModeToPlatformName(mode)}!`);
      })
      .catch((error: any) => {
        handleLog(REJECTED, platformsToShare);
        console.error('error in sending', error);
        Sentry.captureException(error);
        toastDanger('Error', 'Something went wrong while sharing notes. If this persists, please contact customer support');
      })
      .finally(() => {
        setIsExportButtonLoading(false);
        handleClose();
      });
  };

  const handleLog = (status: ResolvedState, platformsToShare: SharePlatforms[]) => {
    const meetingSectionToShare = getMeetingSectionToShare(
      notesSwitchData.isAgendaNotesChecked, notesSwitchData.isSharedNotesChecked,
      notesSwitchData.isPersonalNotesChecked, notesSwitchData.isTasksChecked,
    );
    generateLog(authData.userId, platformsToShare, meetingSectionToShare, start, end, status);
  };

  const handleClose = () => {
    setIsEmailPreviewModalOpen(false);
    setMode(SHARE_VIEW);
    handleCloseClick();
  };

  const onPreviewClick = () => {
    setIsEmailPreviewModalOpen(true);
  };

  const isExportButtonDisabled = !isSendButtonEnabled(
    mode, platformsMultiInputsData, notesSwitchData,
  );

  return (
    <>
      <Modal title={getModalTitle(mode)} isOpen={isOpen} setModalClosed={handleClose}>
        <Container>
          <ModalBody
            mode={mode}
            setMode={setMode}
            platformData={platformData}
            platformsMultiInputsData={platformsMultiInputsData}
            notesSwitchData={notesSwitchData}
            setNotesSwitchData={setNotesSwitchData}
            emailHeadTemplate={emailHeadTemplate}
            setEmailHeadTemplate={setEmailHeadTemplate}
          />
        </Container>
        <ModalFooterContainer>
          <ModalFooterButtons
            mode={mode}
            setMode={setMode}
            handleExport={handleExport}
            isExportLoading={isExportButtonLoading}
            isExportButtonDisabled={isExportButtonDisabled}
            onPreviewClick={onPreviewClick}
          />
        </ModalFooterContainer>
        <PreviewEmailModal
          isModalOpen={isEmailPreviewModalOpen}
          loading={isExportButtonLoading}
          onBackClick={() => { setIsEmailPreviewModalOpen(false); }}
          onSendClick={handleExport}
          notesData={notesData}
          notesSwitchData={notesSwitchData}
          emailHeadTemplate={emailHeadTemplate}
          taskItems={taskItems}
        />
      </Modal>
    </>

  );
};

export default ShareNotesModal;
