import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import {
  darkBlue1,
  gray1, gray8, gray9, overlaySurface,
} from '../../colours';
import CloseSquareIcon from '../../icons/closeSquare';
import { header500 } from '../../typography';
import Scrollbar from '../scrollbar';
import ShortcutsModalHeader from '../shortcuts/components/ShortcutsModalHeader';

const Title = styled.span`
  ${header500};
  color: ${gray9};
`;

const TitleIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 8px;
  }
`;

const IconWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;

  :hover {
    path {
      fill: ${gray8};
    }
  }
`;

const ModalHeader = styled.div<{ isHeadless: boolean }>`
  ${({ isHeadless }) => !isHeadless && `border-bottom: 2px solid ${darkBlue1};`};
  padding: 18px 24px;
`;

const ModalHeaderRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface ModalProps {
  isOpen: boolean;
  setModalClosed: () => void
  title?: string;
  children: ReactNode;
  isShortcutsModal?: boolean;
  isScrollbarEnabled?: boolean;
  icon?: any,
  isHeadless?: boolean;
}

const Modal = ({
  isOpen, setModalClosed, title, children, isShortcutsModal, isScrollbarEnabled, icon, isHeadless,
}: ModalProps) => (
  <ReactModal
    ariaHideApp={false}
    isOpen={isOpen}
    onRequestClose={setModalClosed}
    style={{
      content: {
        background: gray1,
        borderRadius: 10,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
        height: 'fit-content',
        inset: '15vh calc((100vw - 457px)/2)',
        width: '457px',
        padding: 0,
        border: 'none',
      },
      overlay: {
        zIndex: 20,
        background: overlaySurface,
      },
    }}
  >
    <ModalHeader isHeadless={isHeadless as boolean}>
      <ModalHeaderRowContainer>
        <TitleIconContainer>
          <Title>
            {!isHeadless && title}
          </Title>
          {icon}
        </TitleIconContainer>
        <IconWrapper onClick={setModalClosed}>
          <CloseSquareIcon width="24" height="24" fill={gray9} />
        </IconWrapper>
      </ModalHeaderRowContainer>
      {isShortcutsModal && <ShortcutsModalHeader />}
    </ModalHeader>
    {isScrollbarEnabled
      ? (
        <Scrollbar maxHeight="77vh">
          {children}
        </Scrollbar>
      ) : children}
  </ReactModal>
);

Modal.defaultProps = {
  icon: null,
  title: '',
  isShortcutsModal: false,
  isScrollbarEnabled: true,
  isHeadless: false,
};

export default Modal;
