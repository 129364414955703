import styled from 'styled-components';
import { TabPanel } from 'react-tabs';

export const TabContentStyles = styled.div`
  .react-tabs__tab-panel {
    margin-top: 18px;
    &--selected {
    }
  }
`;

const TabContent = TabPanel;

export default TabContent;
