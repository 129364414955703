import React from 'react';
import { dbUserUpdateInfo } from '../../database/firebaseUsersAPI';
import { AuthState, DesktopNotification, ResolvedState } from '../../shared/types/types';
import { DISABLE_EVENT, ENABLE_EVENT } from '../analytics/enums';
import { logDesktopNotificationEvent } from '../analytics/eventLogger';
import { SCOPES } from '../constants';
import { toastDanger, toastInfo, toastSuccess } from '../notifications';
import {
  getGoogleTokensAPI, initiateStage2API, saveNotificationDetailsAPI, stopWebhooksAPI,
} from './apis';

declare let gapi: any;

export const NotificationPublicKey = 'BMY1wOiNrIm1CoTl0tMbJMBegwvLlSNFsPGDu5Elfiwrnz4kQVPLjRS-H5Qcnft_fGDaKAgKSODlr5auFHDDTXk';

export const getGoogleOfflineToken = async (
  userId: string,
) => {
  console.log('getGoogleOfflineToken called', userId);

  const authResponse = await gapi.auth2.getAuthInstance().currentUser.get().grantOfflineAccess({
    access_type: 'offline',
    prompt: 'consent',
    scopes: SCOPES,
  });

  const GoogleTokensResponse: ResolvedState = await getGoogleTokensAPI(userId, authResponse.code);

  if (GoogleTokensResponse === 'resolved') {
    toastSuccess('Login Completed', 'You can now enable desktop notification');
  } else {
    toastDanger('Login Failed', 'Retry enabling desktop notification again');
  }
  return GoogleTokensResponse;
};

export const subscribeToDesktopNotification = async (
  authState: AuthState,
  userId: string,
  setNotificationStatus: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  console.log('subscribeToDesktopNotification called', userId);

  // Register Service Worker
  console.log('Registering service worker 🔄');
  const reg = await navigator.serviceWorker.register('../../serviceWorker.js', { scope: '/' });

  if ('permissions' in navigator) {
    const notificationPerm = await navigator.permissions.query({ name: 'notifications' });
    notificationPerm.onchange = () => onNotificationPermissionChange(
      authState, notificationPerm, reg, userId, setNotificationStatus,
    );
  }

  let serviceWorker;
  if (reg.installing) {
    serviceWorker = reg.installing;
    console.log('Service worker installing 🔄');
  } else if (reg.waiting) {
    serviceWorker = reg.waiting;
    console.log('Service worker installed & waiting 🔄');
  } else if (reg.active) {
    serviceWorker = reg.active;
    console.log('Service Worker Registered ✅');
  }

  if (serviceWorker) {
    if (serviceWorker.state === 'activated') {
      console.log('Service Worker Already Registered ✅');
      return registerPush(authState, reg, userId, setNotificationStatus);
    }
    serviceWorker.addEventListener('statechange', (e: any) => {
      if (e.target.state === 'activated') {
        console.log('Service Worker Just now activated ✅');
        return registerPush(authState, reg, userId, setNotificationStatus);
      }
      return 'pending' as ResolvedState;
    });
  }
  return 'pending' as ResolvedState;
};

export const disableDesktopNotification = async (
  authState: AuthState,
  userId: string,
) => {
  const response = await stopWebhooksAPI(userId);
  if (response === 'resolved') {
    toastInfo('Desktop Notification Disabled', '');
    logDesktopNotificationEvent(authState.userId, DISABLE_EVENT, 'feature');
    return 'resolved' as ResolvedState;
  }
  toastDanger('Desktop Notification Disabled Failed', '');
  return 'rejected' as ResolvedState;
};

const onNotificationPermissionChange = (
  authState: AuthState,
  notificationPerm: any,
  register: ServiceWorkerRegistration,
  userId: string,
  setNotificationStatus: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  console.log(`User decided to change his settings. New permission: ${notificationPerm.state}`);
  if (notificationPerm.state === 'granted') {
    return registerPush(authState, register, userId, setNotificationStatus);
  }
  return 'pending' as ResolvedState;
};

const registerPush = async (
  authState: AuthState,
  register: ServiceWorkerRegistration,
  userId: string,
  setNotificationStatus: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  console.log('registerPush called', userId);

  // Register Push
  console.log('Registering PushSubscription 🔄');
  const subscription: PushSubscription = await register.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(NotificationPublicKey),
  });
  console.log('PushSubscription Registered ✅');

  // Send Push Notification
  console.log('Sending Push Notification Object 🔄');

  const response = await saveNotificationDetailsAPI(
    userId, mapToDesktopNotification(subscription.toJSON()),
  );

  if (response === 'resolved') {
    initiateStage2(userId);
    setNotificationStatus(true);
    toastSuccess('Success', 'Notifications will be enabled in only a few minutes, this page will close automatically');
    logDesktopNotificationEvent(authState.userId, ENABLE_EVENT, 'feature');
    return 'resolved' as ResolvedState;
  }
  setNotificationStatus(false);
  toastInfo('Something went wrong', 'Try enabling notification again');
  return 'rejected' as ResolvedState;
};

const initiateStage2 = async (
  userId: string,
) => {
  console.log('initiateStage2 called', userId);
  const response = await initiateStage2API(userId);
  console.log(response);
};

const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line no-useless-escape
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const mapToDesktopNotification = (subscriptionObject: any) => {
  const desktopNotificationObj: DesktopNotification = {
    endpoint: subscriptionObject.endpoint ?? '',
    expirationTime: subscriptionObject.expirationTime ?? null,
    keys: {
      p256dh: subscriptionObject.keys.p256dh ?? '',
      auth: subscriptionObject.keys.auth ?? '',
    },
  };

  return desktopNotificationObj;
};

export const updateUserHasDismissedDesktopNotification = (userId:string, value:boolean) => dbUserUpdateInfo(userId, { 'data.hasDismissedDesktopNotification': value })
  .then((result) => {
    console.log('update dismiss desktop notification result:', result);
  });
