import React from 'react';
import styled from 'styled-components';
import ButtonSmall, { ButtonSmallProps } from '../button-small';

const SizeModifier = styled.div`
  button {
    height: 36px;
  }
`;

const ButtonBig = (props: ButtonSmallProps) => (
  <SizeModifier>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <ButtonSmall {...props} />
  </SizeModifier>
);

export default ButtonBig;
