import { createGlobalStyle } from 'styled-components';
import { layoutWhite, surface } from '../shared/colours';

export const SIDEBAR_WIDTH_NUMBER = 100;
export const SIDEBAR_WIDTH = `${SIDEBAR_WIDTH_NUMBER}vw`;

// If more fonts needed
// https://fontsfree.net/ -- Search required file eg SF Pro Text Medium (500 font weight)

// Common Weight naming
// https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping

const GlobalStyle = createGlobalStyle`
  html, body {
    color: ${surface};
    background: ${layoutWhite};
    box-sizing: border-box;
    margin: 0;
    font-family: Inter, SF Pro Text, SF Pro Display;
    font-style: normal;
    width: ${SIDEBAR_WIDTH};
    height: 100%;
    overflow: hidden;
    @media only screen and (min-width: 1512px){
      margin: auto;
      max-width: 1512px;
      background: #2D3336;
    }
  }

  #root {
    height: 100%; 
  }

  div {
    box-sizing: border-box;
  }

  .notification-container--top-right {
    right: 35px !important;
    top: 19px !important;
  }

  .notification__item--success {
    background-color: #C1F4E3 !important;
    border-left: 8px solid #138b9e;
  }

  .notification__item--info {
    background-color: #D1F5FF !important;
    border-left: 8px solid #138b9e;
  }

  .notification__item--danger {
    background-color: #FFACB4 !important;
    border-left: 8px solid #138b9e;
  }

  .notification__message {
    color: #1B2124 !important;
  }

  .notification__title {
    color: #1B2124 !important;
  }
`;

export default GlobalStyle;
