/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import styled from 'styled-components';
import {
  Mark, MarkType, NodeType, Schema,
} from 'prosemirror-model';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';
import { toggleMark } from 'prosemirror-commands';
import { wrapInList } from 'prosemirror-schema-list';
import { BaseEmoji, Picker } from 'emoji-mart';
import { selectionCell } from '@meetshepherd/prosemirror-tables';
import { Selection, SelectionRange, TextSelection } from 'prosemirror-state';
import prependHttp from 'prepend-http';
import { undo, redo } from 'y-prosemirror';
import { redo as redoLegacy, undo as undoLegacy } from 'prosemirror-history';

import {
  blue3, blue6, blue7, cyan3, cyan6, cyan7, darkBlue1, darkBlue4, gray1, gray2,
  gray3, gray4, gray6, gray7, gray8, gray9, green3, green6, green7, purple3, purple6,
  purple7, red3, red6, red7, surface, yellow3, yellow6, yellow7,
} from '../../../colours';
import { header500, uiText, uiTextMedium } from '../../../typography';
import ButtonSmall from '../../button-small';
import CustomMenuItem, { MainIconContainer } from './menu-item';
import FontSizeIcon from './icons/font-size';
import FontIcon from './icons/font';
import BoldIcon from './icons/bold';
import ItalicIcon from './icons/italic';
import UnderlineIcon from './icons/underline';
import FontColorIcon from './icons/font-color';
import EnclosingBlockIcon from './icons/enclosing-block';
import UnorderedListIcon from './icons/unordered-list';
import OrderedListIcon from './icons/ordered-list';
import CheckListIcon from './icons/checklist';
import TableIcon from './icons/table';
import AtIcon from './icons/at';
import LinkIcon from './icons/link';
import EmojiIcon from './icons/emoji';
import UndoIcon from './icons/undo';
import RedoIcon from './icons/redo';
import ThreeDotsIcon from './icons/three-dots';
import markActive from '../logic/menu/helpers/mark-active';
import { EditorContext } from '..';
import table from '../logic/menu/items/table';
import 'emoji-mart/css/emoji-mart.css';
import GifIcon from './icons/gif';
import ImageIcon from './icons/image';
import FontSizeDropdown from '../../text-editor/dropdowns/FontSizeDropdown';
import StrikethroughIcon from './icons/strikethrough';
import HighlightIcon from './icons/highlight';
import ColorsDropdownOrg from '../../text-editor/dropdowns/ColorsDropdownOrg';
import LiftOutIcon from './icons/enclosing-block-out';
import { liftAny, sinkAny } from '../logic/sink-lift';
import keymapAdapter, { LinkMenuOptions } from '../logic/keymap/keymap-adapter';
import ClearFormatIcon from '../../../icons/text-editor/ClearFormatIcon';
import { linkPreviewPK } from '../logic/plugins/link-creation-preview';
import fontSpecs from '../logic/marks/fonts';
import RevisionIcon from './icons/revision';
import DropdownMenu from '../../dropdown/DropdownMenu';
import CopyIcon from './icons/copy';
import EditIcon from './icons/edit';
import MeetingDataContext from '../../../../pages/meeting/context/MeetingDataContext';
import MeetingVersionNumber from './MeetingVersionNumber';
import { useEditorContext as useV3EditorContext } from '../index_old';
import { useEditorContext as useV5EditorContext } from '../index';
import { insertNonNestableTable } from '../logic/menu/helpers/table-utils';
import {
  CLICK_EVENT,
  CLOSE_EVENT, DISABLE_EVENT, ENABLE_EVENT, OPEN_EVENT,
  TextEditorOptions,
  TextEditorOptionSource,
  TEXT_EDITOR_TOOLBAR,
} from '../../../../utils/analytics/enums';
import { handleTextEditorLog } from '../../../../utils/analytics/eventLogger';
import { AuthContext } from '../../../../App';

const IconContainer = styled.div``;

const Title = styled.span`
  ${header500};
  color: ${gray9};
`;

const MenuContainer = styled.div`
  height: 34px;
  width: 100%;
  background-color: ${gray1};
  display: flex;
  align-items: center;
  border: solid ${darkBlue1};
  border-width: 0 0 2px 0;
  gap: 4px;
  padding-left: 10px;
`;

const MenuSeparator = styled.div`
  height: 16px;
  margin-top: auto;
  margin-bottom: auto;
  width: 0px;
  border: ${darkBlue1} solid;
  border-width: 0 2px 0 0;
`;

const FontColorPicker = styled.div`
  background-color: ${gray1};
  display: flex;
  flex-flow: column;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  max-width: 150px;
`;

const ColorPickerContainer = styled.div`
  display: flex;
  flex-shrink: 1 0 auto;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

interface ColorPickerCircleProps {
  color: string;
}

const ColorPickerCircle = styled.div<ColorPickerCircleProps>`
  height: 18px;
  width: 18px;
  border-radius: 100%;
  background-color: ${({ color }) => color};
`;

const ButtonContainer = styled.div`
  margin-top: 8px;
`;

const SubMenuItemsContainer = styled.div`
  background-color: ${gray1};
  display: flex;
  flex-flow: row;
  gap: 8px;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;

interface DropdownPosition {
  top: number;
  left: number;
}

interface MenuDropdownProps {
  absolute?: DropdownPosition | null;
}

const MenuDropdown = styled.div<MenuDropdownProps>`
  ${({ absolute }) => (absolute ? `position:absolute;top:${absolute.top}px;left:${absolute.left}px;` : '')}
  background-color: ${gray1};
  display: flex;
  flex-flow: column;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  font-family: "Inter, sans-serif";
`;

const InputContainer = styled.div`
  position: relative;
  overflow: hidden;
  background: ${gray4};
  border: 2px solid ${gray4};
  border-radius: 8px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 175ms;
  display: flex;
  justify-content: center;
  align-items: center;

  :first-of-type {
    margin-bottom: 8px;
  }

  :focus-within {
    border: 2px solid #058FEF;
  }
`;

const Input = styled.input`
  outline: 0px solid transparent;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  flex-grow: 1;
  ${uiText}
  border: 0;
`;

interface MenuItemProps {
  selected?: boolean;
  fontFamily?: string;
}

const DropdownItem = styled.div<MenuItemProps>`
  padding: 6px 8px;
  border-radius: 6px;
  scroll-margin: 8px;
  display: flex;
  flex-flow: row;
  align-items: center;

  ${({ fontFamily }) => (fontFamily === 'initial' ? uiTextMedium : `font-family: ${fontFamily};`)}
  ${({ selected }) => (selected ? `background-color: ${darkBlue1};` : '')}

  &:hover {
    cursor: pointer;
    background-color: ${({ selected }) => (selected ? gray6 : gray2)};
  }

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

DropdownItem.defaultProps = {
  selected: false,
  fontFamily: 'initial',
};

interface CustomeEditorMenuProps {
  schema: Schema;
  view: EditorView;
  setUpdate: CallableFunction;
  hide: boolean;
  legacy: boolean;
}

const CustomEditorMenu = ({
  schema,
  view,
  setUpdate,
  hide,
  legacy,
}: CustomeEditorMenuProps) => {
  /**
   * This context holds some important values, such
   * as the prosemirror view, the update state (which
   * is used for forced updates here), and the setUpdate
   * setter, for updating every bound useEffect in the
   * application.
   */
  const ctx = legacy ? useV3EditorContext() : useV5EditorContext();

  const { userId } = useContext(AuthContext);
  const meetingData = useContext(MeetingDataContext);

  /**
   * Factory which returns a callback that verifies
   * wether the text cursor is currently within
   * a specified mark. Is used for toggling the "active"
   * state of mark buttons (such as bold, italic or underline).
   * @param mark The name of the mark that is to be checked.
   * @returns A callback which will return true or false, depending
   * on the location of the cursor.
   */
  const checkMarkActive = (mark: string) => () => {
    if (!ctx?.view) return false;
    return markActive(ctx.view.state, (ctx.view.state.schema as Schema).marks[mark]);
  };

  /**
   * Factory which returns a callback that applies a mark.
   * @param mark The name of the mark to be applied.
   * @param attrs Extra attributes for the mark
   * @returns A callback which will toggle a mark over a specified
   * selection. It can also store it in a special "marks to be applied"
   * (storedMarks) if the selection length is 0.
   */
  const dispatchToggleMark = (mark: string, attrs?: Record<string, unknown>) => () => {
    console.debug(ctx?.view);
    if (!ctx?.view) return;
    try {
      toggleMark(ctx.view.state.schema.marks[mark], attrs)(ctx.view.state, ctx.view.dispatch);
    } catch (e) {
      console.warn('Can\'t move cursor');
    }
    setUpdate({});
  };

  /**
   * Factory which returns a callback that applies list styling.
   * @param type The name of the list kind.
   * @returns A callback which will wrap the current selection in a list.
   */
  const dispatchWrapInList = (type: string) => () => {
    if (!ctx?.view) return;
    wrapInList(ctx.view.state.schema.nodes[type])(ctx.view.state, ctx.view.dispatch);
  };

  /**
   * Function which inserts text at the current cursor location.
   * @param toInsert String content to be inserted.
   * @returns Nothing.
   */
  const insert = (toInsert: string) => {
    if (!ctx?.view) return;
    const d = ctx.view.dispatch;
    const v = ctx.view;
    const s = ctx.view.state;
    const { tr } = ctx.view.state;
    tr.insertText(toInsert);
    d(tr);
  };

  /**
   * Function used for node insertion.
   * @param nodeName Node name.
   * @param attr Extra attributes for node creation.
   * @param contents Text (or Node) contents for when the node is inserted.
   * @returns Nothing.
   */
  const insertNode = (
    nodeName: string,
    attr: Record<string, any> = {},
    contents?: any,
  ) => {
    if (!ctx?.view) return;
    const ntype: NodeType = ctx.view.state.schema.nodes[nodeName];
    const { tr } = ctx.view.state;
    const d = ctx.view.dispatch;
    tr.insert(
      ctx.view.state.selection.$anchor.pos,
      ntype.create(attr, contents),
    );
    d(tr);
  };

  /**
   * These fonts will show up in the menu. Before attaching anything to
   * the menu, make sure you export the specific font from
   * src/shared/components/prosemirror/logic/marks/fonts.ts
   * and that they are properly inserted into the sechema at
   * src/shared/components/prosemirror/hooks/use-prose-mirror-firebase.ts
   */
  const fonts = useMemo(
    () => [
      {
        name: 'Inter',
        family: 'Inter',
        mark: 'inter',
        active: () => {
          let cond = false;
          Object.entries(fontSpecs).forEach(([key]) => {
            cond ||= checkMarkActive(key)();
          });
          return !cond || checkMarkActive('inter')();
        },
      },
      {
        separator: true,
        name: 'Separator',
        family: 'Separator',
        mark: '',
        active: () => false,
      },
      {
        name: 'Open Sans',
        family: 'Open Sans',
        mark: 'openSans',
        active: checkMarkActive('openSans'),
      },
      {
        name: 'Lato',
        family: 'Lato',
        mark: 'lato',
        active: checkMarkActive('lato'),
      },
      {
        name: 'Montserrat',
        family: 'Montserrat',
        mark: 'montserrat',
        active: checkMarkActive('montserrat'),
      },
      {
        name: 'Raleway',
        family: 'Raleway',
        mark: 'raleway',
        active: checkMarkActive('raleway'),
      },
    ],
    [ctx?.update],
  );

  /**
   * This useMemo() use will constantly seek the current highlight color,
   * if any is present. It will only get updated on ctx.update changes.
   * If no highlight is present it will return null.
   */
  const highlightColor = useMemo(() => {
    if (!ctx?.view) return null;

    const { state } = ctx.view;

    const {
      from, $from, to, empty,
    } = state.selection;

    if (empty) {
      const storedHighlight = (state.storedMarks ?? []).find((mark) => mark.type.spec.group === 'shepherd-custom-highlights');
      if (storedHighlight) return storedHighlight;
      const fromHighlight = ($from.marks().find((mark) => mark.type.spec.group === 'shepherd-custom-highlights'));
      if (fromHighlight) return fromHighlight;
    }

    let nodeMark = null;

    state.doc.nodesBetween(from, to, (node) => {
      const attemptMark = node.marks.find((mark) => mark.type.spec.group === 'shepherd-custom-highlights');
      if (attemptMark) nodeMark = attemptMark;
    });

    return nodeMark;
  }, [ctx?.update]);

  /**
   * This useMemo() use will constantly seek the current text color,
   * if any is present. It will only get updated on ctx.update changes.
   * If no text color is present it will return null.
   */
  const textColor = useMemo(() => {
    if (!ctx?.view) return null;

    const { state } = ctx.view;

    const {
      from, $from, to, empty,
    } = state.selection;

    if (empty) {
      const storedHighlight = (state.storedMarks ?? []).find((mark) => mark.type.spec.group === 'shepherd-custom-colors');
      if (storedHighlight) return storedHighlight;
      const fromHighlight = ($from.marks().find((mark) => mark.type.spec.group === 'shepherd-custom-colors'));
      if (fromHighlight) return fromHighlight;
    }

    let nodeMark = null;

    state.doc.nodesBetween(from, to, (node) => {
      const attemptMark = node.marks.find((mark) => mark.type.spec.group === 'shepherd-custom-colors');
      if (attemptMark) nodeMark = attemptMark;
    });

    return nodeMark;
  }, [ctx?.update]);

  const linkInput = useRef<HTMLInputElement | null>(null);
  const linkTitleInput = useRef<HTMLInputElement | null>(null);
  const linkMenuDropdown = useRef<HTMLDivElement | null>(null);

  type LinkInsert = { title: string, link: string };
  /**
   * State used for link inertion.
   */
  const [linkInsert, setLinkInsert] = useState<LinkInsert>({
    title: '',
    link: '',
  });

  const [dropdownPosition, setDropdownPosition] = useState<DropdownPosition | null>(null);

  const [selectionRange, setSelectionRange] = useState<Selection<any> | null>(null);
  const isRangeSelected = useMemo(() => {
    return selectionRange != null && !selectionRange.empty;
  }, [selectionRange]);

  const insertLinkButtonLabel = useMemo(() => {
    return !selectionRange || (selectionRange && !selectionRange.empty) ? 'Create link' : 'Create link'; // TODO this is not needed anymore
  }, [ctx?.update, selectionRange]);

  // make a link preview if applicable
  const linkPreview = () => {
    if (!ctx?.view) return;

    const { tr } = ctx.view.state;
    const { selection } = ctx.view.state;
    const { empty } = selection;

    if (empty) {
      setSelectionRange(null);
      return;
    }

    setSelectionRange(selection);

    // TODO check that the selection is not inside another link.
    // if it is a child of another link skip the preview and return.

    const { bottom, left } = ctx.view.coordsAtPos(selection.from);

    // TODO refactor
    if (linkInput && linkInput.current) {
      const inputContainer = linkMenuDropdown.current;
      const dropdownActivator = inputContainer
        ?.parentElement?.parentElement;
      if (inputContainer && dropdownActivator) {
        const { innerHeight } = window;
        const { clientWidth, clientHeight } = inputContainer;
        const target = dropdownActivator.getBoundingClientRect();
        let heightBound = Math.max(bottom + clientHeight - innerHeight, 0);
        heightBound += heightBound > 0 ? 16 * 3 : 0; // padding
        const finalVec2 = {
          top: bottom - target.bottom - heightBound,
          left: left - target.right - Math.max(left + clientWidth - 520, 0),
        };
        setDropdownPosition(finalVec2);
      } else { // unnecessary but just in case
        setDropdownPosition(null);
      }
    } else {
      setDropdownPosition(null);
    }

    ctx.view.dispatch(
      tr.setMeta(linkPreviewPK, {
        selection,
        options: { // optional
          remove: false,
        },
      }),
    );
  };

  // remove a link preview if applicable
  const removeLinkPreview = () => {
    if (!ctx?.view || !selectionRange) return;

    if (selectionRange.empty) {
      setSelectionRange(null);
      return;
    }

    const { tr } = ctx.view.state;
    const { selection } = ctx.view.state;
    const { empty } = selection;

    ctx.view.dispatch(
      tr.setMeta(linkPreviewPK, {
        selection,
        options: {
          remove: true,
        },
      }),
    );
  };

  const linkInputFocus = (e?: HTMLInputElement) => {
    // Executed only once when entering the link insert menu
    // initial preview generation is called here
    linkPreview();
    if (e) {
      const { activeElement } = document;
      if (!(activeElement === e)) { e.focus(); }
    } else {
      // TODO check the current selection, and see what state the modal should be in
      const selection = ctx?.view?.state?.selection;
      // eslint-disable-next-line max-len
      if (selection && selection.empty) { linkTitleInput?.current?.focus(); } else { linkInput?.current?.focus(); }
    }
  };

  /** http://localhost:3000/meeting/sRkZV9mGGNsoR3T7R9Rg
   * Link insertion command.
   * If the user has an ongoing non-empty selection, toggle the linkMark instead
   * @param details Link and title information.
   * @returns Nothing.
   */
  const insertLink = (details: LinkInsert) => {
    if (!ctx?.view) return;

    const parsedLink = prependHttp(details.link);

    const { state } = ctx.view;
    const { selection } = ctx.view.state;
    const { tr } = state;

    let { title } = details;
    if (!title) { title = parsedLink; }

    if (!selection.empty) {
      const [start, end] = [
        selection.$from,
        selection.$to,
      ];

      title = state.doc.textBetween(start.pos, end.pos);
    }
    const linkMark = state.schema.marks.link.create({
      title,
      href: parsedLink,
    });

    const txt = state.schema.text(title, [linkMark]);
    const textNode = state.schema.nodes.textInlineNode.create(
      { text: title },
      [txt],
      [],
    );

    if (!selection.empty) {
      removeLinkPreview();
      tr.replaceSelectionWith(textNode);
    } else {
      tr.insert(
        state.selection.$anchor.pos,
        textNode,
      );
    }
    setLinkInsert({
      link: '',
      title: '',
    });
    ctx.view.dispatch(tr);
  };

  const handleLog = (source: TextEditorOptionSource,
    option: TextEditorOptions,
    data: Object) => {
    handleTextEditorLog(userId, { source, option, ...data });
  };

  const colorMenuRef = useRef(null);

  return (
    <MenuContainer
      style={hide ? { display: 'none' } : {}}
      onMouseDown={(e) => {
        // This can be any target, the cast here
        // is done because the custom behavior is
        // expected to be executed on this type.
        // For safety, we used optional chaining.
        const target = e.target as HTMLInputElement;
        if (target?.matches('#shepherd-link-insert-link')
          || target?.matches('#shepherd-link-insert-title')) {
          setUpdate({});
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        setUpdate({});
      }}
    >
      <CustomMenuItem
        legacy={legacy}
        key="1"
        tooltipText="Font"
        closeOnClickAway
        list
        listContents={(scope: any) => {
          if (!ctx?.view) return '';
          return (
            <MenuDropdown>
              {fonts.map((font) => {
                if (font.separator) {
                  return (
                    <div
                      key={font.name}
                      style={{
                        height: '1px',
                        width: '100%',
                        backgroundColor: gray4,
                        maxWidth: '98px',
                        margin: 'auto',
                        marginTop: '4px',
                        marginBottom: '4px',
                      }}
                    />
                  );
                }
                return (
                  <DropdownItem
                    key={font.name}
                    fontFamily={font.family}
                    onMouseDown={(e) => {
                      handleLog(TEXT_EDITOR_TOOLBAR, 'font_style', { action: 'change_font', selectedFont: font.name });
                      e.preventDefault();
                      e.stopPropagation();
                      dispatchToggleMark(font.mark)();
                      scope.closeContents();
                    }}
                    selected={font.active()}
                  >
                    {font.name}
                  </DropdownItem>
                );
              })}
            </MenuDropdown>
          );
        }}
        contents={({ show }) => (
          <IconContainer onClick={() => { handleLog(TEXT_EDITOR_TOOLBAR, 'font_style', { action: show ? OPEN_EVENT : CLOSE_EVENT }); }}>
            <FontIcon
              fill={show ? surface : undefined}
            />
          </IconContainer>
        )}
      />

      <CustomMenuItem
        legacy={legacy}
        key="2"
        tooltipText="Font Size"
        closeOnClickAway
        list
        listContents={(scope: any) => {
          if (scope) {
            return (
              <FontSizeDropdown
                ctx={scope.ctx}
                onStyleChange={() => {
                  scope.closeContents();
                }}
              />
            );
          }
          return '';
        }}
        contents={({ show }) => (
          <IconContainer onClick={() => { handleLog(TEXT_EDITOR_TOOLBAR, 'font_size', { action: show ? OPEN_EVENT : CLOSE_EVENT }); }}>
            <FontSizeIcon
              fill={show ? surface : undefined}
            />
          </IconContainer>
        )}
      />

      <CustomMenuItem
        legacy={legacy}
        key="3"
        tooltipText="Bold"
        activeCallback={checkMarkActive('strong')}
        action={dispatchToggleMark('strong')}
        contents={({ show }) => (
          <BoldIcon
            fill={show ? surface : undefined}
          />
        )}
      />

      <CustomMenuItem
        legacy={legacy}
        key="4"
        tooltipText="Italic"
        activeCallback={checkMarkActive('em')}
        action={dispatchToggleMark('em')}
        contents={() => (
          <ItalicIcon />
        )}
      />

      <CustomMenuItem
        legacy={legacy}
        key="5"
        tooltipText="Underline"
        activeCallback={checkMarkActive('underline')}
        action={dispatchToggleMark('underline')}
        contents={() => (
          <UnderlineIcon />
        )}
      />

      <CustomMenuItem
        legacy={legacy}
        key="6"
        adapter="textColour"
        tooltipText="Text colour"
        closeOnClickAway
        list
        listContents={(scope: any) => (
          <ColorsDropdownOrg
            selectedColor={textColor ? textColor.attrs.rawColor : '#1B2124'}
            setSelectedColor={(color) => {
              handleLog(TEXT_EDITOR_TOOLBAR, 'font_color', { action: 'change_color', fontColor: color });
              scope.closeContents();
              if (!ctx?.view) return false;
              const { marks }: { marks: Record<string, Mark> } = ctx.view.state.schema;
              const [seekMark] = Object.entries(marks).find(([name, mark]) => (
                (mark as any).spec.group === 'shepherd-custom-colors'
                && mark.attrs.rawColor?.default === color
              )) ?? [undefined, undefined];
              if (!seekMark) return false;
              dispatchToggleMark(seekMark)();
              return true;
            }}
            handleResetClick={() => {
              scope.closeContents();
              dispatchToggleMark('surface')();
            }}
          />
        )}
        contents={({ show }) => (
          <IconContainer onClick={() => { handleLog(TEXT_EDITOR_TOOLBAR, 'font_color', { action: show ? OPEN_EVENT : CLOSE_EVENT }); }}>
            <FontColorIcon
              fill={show ? surface : undefined}
            />
          </IconContainer>
        )}
      />
      <CustomMenuItem
        legacy={legacy}
        key="7"
        adapter="highlightColour"
        tooltipText="Highlight colour"
        closeOnClickAway
        list
        listAlign="right"
        listContents={(scope: any) => {
          return (
            <ColorsDropdownOrg
              selectedColor={highlightColor ? highlightColor.attrs.rawColor : gray1}
              setSelectedColor={(color) => {
                handleLog(TEXT_EDITOR_TOOLBAR, 'font_highlight', { action: 'change_highlight_color', color });
                scope.closeContents();
                if (!ctx?.view) return false;
                const { marks }: { marks: Record<string, Mark> } = ctx.view.state.schema;
                const [seekMark] = Object.entries(marks).find(([name, mark]) => (
                  (mark as any).spec.group === 'shepherd-custom-highlights'
                  && mark.attrs.rawColor?.default === color
                )) ?? [undefined, undefined];
                if (!seekMark) return false;
                dispatchToggleMark(seekMark)();
                return true;
              }}
              handleResetClick={() => {
                dispatchToggleMark('empty-highlight')();
              }}
            />
          );
        }}
        contents={({ show }) => (
          <IconContainer onClick={() => { handleLog(TEXT_EDITOR_TOOLBAR, 'font_highlight', { action: show ? OPEN_EVENT : CLOSE_EVENT }); }}>
            <HighlightIcon />
          </IconContainer>
        )}
      />

      <MenuSeparator />

      <CustomMenuItem
        legacy={legacy}
        key="8"
        tooltipText="Bullet list"
        action={dispatchWrapInList('bullet_list')}
        contents={() => (
          <UnorderedListIcon />
        )}
      />

      <CustomMenuItem
        legacy={legacy}
        key="9"
        tooltipText="Numbered list"
        action={dispatchWrapInList('ordered_list')}
        contents={() => (
          <OrderedListIcon />
        )}
      />

      <CustomMenuItem
        legacy={legacy}
        key="10"
        tooltipText="Checkbox"
        action={dispatchWrapInList('todo_list')}
        contents={() => (
          <CheckListIcon />
        )}
      />

      <CustomMenuItem
        legacy={legacy}
        key="11"
        tooltipText="Insert table"
        closeOnClickAway
        hideChevron
        list
        adapter="tableControl"
        action={(): boolean | undefined => {
          if (!ctx || !ctx.view) return undefined;
          const insideTable = ctx.isInsideTable;
          if (insideTable) return false;
          const { state, dispatch } = ctx.view;
          insertNonNestableTable(state, dispatch);
          return true;
        }}
        listContents={(scope: any) => (
          <MenuDropdown>
            {table.map((value, index) => {
              if (value.id === 'insert-table' && ctx?.isInsideTable) {
                return '';
              }
              if (value.colorPicker) {
                return (
                  <CustomMenuItem
                    legacy={legacy}
                    key={`${value.label ?? index}_12`}
                    closeOnClickAway
                    hideChevron
                    list
                    listAlign="horizontalLeft"
                    listContents={(secondaryScope) => (
                      <ColorsDropdownOrg
                        selectedColor={(() => {
                          const color = '#FFFFFF';
                          const state = ctx?.view?.state;
                          if (!state) return color;
                          const tableCellNodePosition = selectionCell(state)?.pos;
                          if (!tableCellNodePosition) return color;
                          const tableNode = ctx?.view?.state.doc.nodeAt(tableCellNodePosition);
                          return tableNode?.attrs?.background ?? '#FFFFFF';
                        })()}
                        setSelectedColor={(color) => {
                          secondaryScope.closeContents();
                          scope.closeContents();
                          if (!ctx?.view) return false;
                          return value.callback(color, ctx.view.state, ctx.view.dispatch);
                        }}
                        handleResetClick={() => {
                          secondaryScope.closeContents();
                          scope.closeContents();
                          if (!ctx?.view) return false;
                          return value.callback(null, ctx.view.state, ctx.view.dispatch);
                        }}
                      />
                    )}
                    Container={DropdownItem}
                    contents={() => (
                      <div
                        style={{
                          display: 'flex',
                          flexFlow: 'row',
                        }}
                      >
                        {(value.icon
                          && (
                            <MainIconContainer
                              style={{
                                marginRight: '8px',
                              }}
                            >
                              {value.icon}
                            </MainIconContainer>
                          )
                        )}
                        {value.label}
                      </div>
                    )}
                  />
                );
              }
              if (value.separator) {
                return (
                  <div
                    style={{
                      height: '1px',
                      width: '178px',
                      backgroundColor: gray4,
                      marginBottom: '4px',
                    }}
                  />
                );
              }
              return (
                <DropdownItem
                  key={`${value.label ?? index}_13`}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!ctx?.view) return;
                    value.callback(ctx.view.state, ctx.view.dispatch);
                    // data.setShow(false);
                    scope.closeContents();
                  }}
                >
                  {(value.icon
                    && (
                      <MainIconContainer
                        style={{
                          marginRight: '8px',
                        }}
                      >
                        {value.icon}
                      </MainIconContainer>
                    )
                  )}

                  {value.label}
                </DropdownItem>
              );
            })}
          </MenuDropdown>
        )}
        contents={({ show }) => (
          <IconContainer onClick={() => { handleLog(TEXT_EDITOR_TOOLBAR, 'insert_table', { action: show ? OPEN_EVENT : CLOSE_EVENT }); }}>
            <TableIcon
              fill={show ? surface : undefined}
            />
          </IconContainer>
        )}
      />

      <MenuSeparator />

      <CustomMenuItem
        legacy={legacy}
        key="14"
        tooltipText="Mention someone"
        action={() => {
          handleLog(TEXT_EDITOR_TOOLBAR, 'mention', { action: CLICK_EVENT });
          insert('@');
        }}
        contents={() => (
          <AtIcon />
        )}
      />

      {/* TODO should the tooltip text get renamed? */}
      <CustomMenuItem
        legacy={legacy}
        key="15"
        tooltipText="Insert Link"
        closeOnClickAway
        captureEvents
        list
        hideChevron
        listAlign="right"
        init={(showState) => {
          const [show, setShow] = showState;
          keymapAdapter.reactMethods = {
            openLinkMenu: () => {
              setShow(true);
            },
            closeLinkMenu: () => {
              setShow(false);
            },
            focusLinkMenu: () => {
              linkInputFocus();
            },
            insertLink: () => {
              // commit changes
              insertLink(linkInsert);
            },
            clearFormatting: (pmView) => {
              if (!pmView) return;
              const { selection } = pmView.state;
              if (!selection.empty) {
                const { tr } = pmView.state;
                tr.removeMark(selection.from, selection.to);
                pmView.dispatch(tr);
              }
            },
          };
        }}
        onOpen={() => {
          if (!linkInput?.current) return;
          linkInputFocus();
        }}
        onClose={() => {
          removeLinkPreview();
          setDropdownPosition(null);
          view?.focus();
        }}
        listContents={(scope: any) => {
          if (scope) {
            return (
              <MenuDropdown
                ref={linkMenuDropdown}
                absolute={isRangeSelected ? dropdownPosition : null}
                onKeyDown={(event) => {
                  // While the menu item is open, Escape will close it.
                  if (event.key === 'Escape') {
                    setLinkInsert({
                      link: '',
                      title: '',
                    });
                    scope.setShow(false);
                    // Focus the ProseMirror editor.
                    view.focus();
                  } else if (event.key === 'Enter') {
                    insertLink(linkInsert);
                    scope.setShow(false);
                  }
                }}
                style={{
                  padding: '16px',
                }}
              >
                {!isRangeSelected
                  && (
                    <Title
                      style={{
                        marginBottom: '16px',
                      }}
                    >
                      Insert Link
                    </Title>
                  )}
                {!isRangeSelected
                  && (
                    <InputContainer>
                      <Input
                        id="shepherd-link-insert-title"
                        placeholder="Title (Optional)"
                        onChange={(e) => {
                          setLinkInsert({ ...linkInsert, title: e.target.value });
                        }}
                        ref={linkTitleInput}
                        onMouseDownCapture={(e: React.MouseEvent<HTMLInputElement>) => {
                          e.preventDefault();
                          linkInputFocus(e.currentTarget);
                        }}
                      />
                    </InputContainer>
                  )}
                <InputContainer>
                  <Input
                    id="shepherd-link-insert-link"
                    placeholder="Link"
                    onChange={(e) => {
                      setLinkInsert({ ...linkInsert, link: e.target.value });
                    }}
                    ref={linkInput}
                    onMouseDownCapture={(e: React.MouseEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      linkInputFocus(e.currentTarget);
                    }}
                  />
                </InputContainer>
                <ButtonContainer
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: !isRangeSelected ? '16px' : '0px',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <ButtonSmall
                    text="Cancel"
                    onClick={(e) => {
                      scope.closeContents();
                    }}
                    isOutline
                  />
                  <ButtonSmall
                    text={insertLinkButtonLabel}
                    onClick={(e) => {
                      insertLink(linkInsert);
                      scope.closeContents();
                    }}
                  />
                </ButtonContainer>
              </MenuDropdown>
            );
          }
          return '';
        }}
        contents={({ show }) => (
          <IconContainer onClick={() => { handleLog(TEXT_EDITOR_TOOLBAR, 'insert_link', { action: show ? OPEN_EVENT : CLOSE_EVENT }); }}>
            <LinkIcon
              fill={show ? surface : undefined}
            />
          </IconContainer>

        )}
      />

      <CustomMenuItem
        legacy={legacy}
        key="16"
        adapter="emojiMart"
        tooltipText="Emoji"
        closeOnClickAway
        list
        hideChevron
        listAlign="right"
        listContents={(data: any) => (
          <Picker
            emojiSize={20}
            showPreview={false}
            emojisToShowFilter={(emoji) => {
              if ((emoji as any).added_in) {
                const numerical = parseFloat((emoji as any).added_in);
                return numerical < 12.0;
              }
              return false;
            }}
            color="#058fef"
            native
            title=""
            emoji=""
            autoFocus
            onSelect={(emoji: BaseEmoji) => { insert(emoji.native); data.setShow(false); }}
          />
        )}
        contents={({ show }) => (
          <IconContainer onClick={() => { handleLog(TEXT_EDITOR_TOOLBAR, 'insert_emoji', { action: show ? OPEN_EVENT : CLOSE_EVENT }); }}>
            <EmojiIcon
              fill={show ? surface : undefined}
            />
          </IconContainer>

        )}
      />

      <MenuSeparator />

      <CustomMenuItem
        legacy={legacy}
        key="17"
        tooltipText="Undo"
        action={() => {
          if (!ctx?.view) return () => null;
          if (legacy) {
            return undoLegacy(ctx.view.state, ctx.view.dispatch);
          }
          return undo(ctx.view.state);
        }}
        contents={() => (
          <UndoIcon />
        )}
      />

      <CustomMenuItem
        legacy={legacy}
        key="18"
        tooltipText="Redo"
        action={() => {
          if (!ctx?.view) return () => null;
          if (legacy) {
            return redoLegacy(ctx.view.state, ctx.view.dispatch);
          }
          return redo(ctx.view.state);
        }}
        contents={() => (
          <RedoIcon />
        )}
      />

      <MenuSeparator />

      <CustomMenuItem
        legacy={legacy}
        key="19"
        tooltipText="More"
        closeOnClickAway
        list
        hideChevron
        listAlign="right"
        listContents={(data: any) => (
          <SubMenuItemsContainer>
            <CustomMenuItem
              legacy={legacy}
              key="20"
              tooltipText="Clear formatting"
              activeCallback={() => false}
              action={() => {
                if (!ctx?.view) return;
                const { selection } = ctx.view.state;
                if (!selection.empty) {
                  const { tr } = ctx.view.state;
                  tr.removeMark(selection.from, selection.to);
                  ctx.view.dispatch(tr);
                }
              }}
              contents={() => (
                <IconContainer onClick={() => { handleLog(TEXT_EDITOR_TOOLBAR, 'clear_formatting', { action: CLICK_EVENT }); }}>
                  <ClearFormatIcon fill={surface} />
                </IconContainer>
              )}
            />
            <CustomMenuItem
              legacy={legacy}
              key="21"
              tooltipText="Strikethrough"
              activeCallback={checkMarkActive('strikethrough')}
              action={dispatchToggleMark('strikethrough')}
              contents={() => (
                <IconContainer onClick={() => { handleLog(TEXT_EDITOR_TOOLBAR, 'strike_through', { action: CLICK_EVENT }); }}>
                  <StrikethroughIcon fill={surface} />
                </IconContainer>
              )}
            />
            <CustomMenuItem
              legacy={legacy}
              key="22"
              tooltipText="Change indent"
              closeOnClickAway
              list
              listContents={(scope: any) => (
                <SubMenuItemsContainer>
                  <CustomMenuItem
                    legacy={legacy}
                    key="23"
                    tooltipText="Decrease indent"
                    action={() => {
                      if (!ctx?.view) return;
                      liftAny(ctx.view)(ctx.view.state, ctx.view.dispatch);
                    }}
                    contents={() => (
                      <LiftOutIcon fill={surface} />
                    )}
                  />
                  <CustomMenuItem
                    legacy={legacy}
                    key="24"
                    tooltipText="Increase indent"
                    action={() => {
                      if (!ctx?.view) return;
                      sinkAny(ctx.view)(ctx.view.state, ctx.view.dispatch);
                    }}
                    contents={() => (
                      <EnclosingBlockIcon
                        fill={surface}
                      />
                    )}
                  />
                </SubMenuItemsContainer>
              )}
              contents={({ show }) => (
                <IconContainer onClick={() => { handleLog(TEXT_EDITOR_TOOLBAR, 'change_indent', { action: CLICK_EVENT }); }}>
                  <EnclosingBlockIcon
                    fill={surface}
                  />
                </IconContainer>
              )}
            />
            <CustomMenuItem
              legacy={legacy}
              key="25"
              tooltipText="Insert image"
              action={() => {
                if (!ctx?.view) return () => null;
                data.setShow(false);
                return ctx.openImageModal();
              }}
              contents={() => (
                <IconContainer onClick={() => { handleLog(TEXT_EDITOR_TOOLBAR, 'insert_media', { action: CLICK_EVENT }); }}>
                  <ImageIcon fill={surface} />
                </IconContainer>
              )}
            />
            <CustomMenuItem
              legacy={legacy}
              key="26"
              tooltipText="Insert GIF"
              action={() => {
                if (!ctx?.view) return () => null;
                data.setShow(false);
                return ctx.openGifModal();
              }}
              contents={() => (
                <IconContainer onClick={() => { handleLog(TEXT_EDITOR_TOOLBAR, 'insert_media', { action: CLICK_EVENT }); }}>
                  <GifIcon fill={surface} />
                </IconContainer>
              )}
            />
            {
            // TODO: Uncomment this when history mode is finished.
            /* <CustomMenuItem
legacy={legacy}
              action={() => {
                if (!ctx?.view) return () => null;
                data.setShow(false);
                return ctx.openRevisionModal();
              }}
              contents={() => (
                <RevisionIcon fill={surface} />
              )}
            /> */}
          </SubMenuItemsContainer>
        )}
        contents={({ show }) => (
          <IconContainer onClick={() => { handleLog(TEXT_EDITOR_TOOLBAR, 'three_dot_menu', { action: show ? OPEN_EVENT : CLOSE_EVENT }); }}>
            <ThreeDotsIcon
              fill={show ? surface : undefined}
            />
          </IconContainer>

        )}
      />
      <MeetingVersionNumber meetingData={meetingData} />
    </MenuContainer>
  );
};

export default CustomEditorMenu;
