/* eslint-disable no-unused-vars */
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import CreateTask from '../../../shared/components/create-task';
import PreviousMeetingButtonMol from '../../../shared/components/previous-meeting-notes/components/PreviousMeetingButtonMol';
import {
  MeetingData, MeetingSection, PrivateNoteData, TaskItems, UserCenterPage,
} from '../../../shared/types/types';
import { USER_CENTER_ALL_NOTES } from '../../../utils/enums';
import ShareMeetingNotes from '../../meeting/share-meeting';
import BackgroundColorContainerAtom from './BackgroundColorContainerAtom';
// import ButtonMolecule from './ButtonMolecule';
import ContainerAtom from './ContainerAtom';
import MoreOptions from './more-options/MoreOptions';
import TransparentBackgroundContainerAtom from './TransparentBackgroundContainerAtom';

interface Props {
  isEditTemplateBottomBarEnabled: boolean
  meetingData: MeetingData,
  section: UserCenterPage,
  taskItems: TaskItems,
  meetingTab: MeetingSection,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>,
  isCreateTaskModalOpen: boolean,
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  setIsRecurringMeeting: Dispatch<SetStateAction<boolean>>,
  privateNotes: PrivateNoteData[],
}

const PreviousBtnStyled = styled.div`
    @media only screen and (min-width: 1280px){
      display: none;
    }
    @media only screen and (max-width: 1280px){
      display: block;
    }
  `;

const ActionButtonStyled = styled.div`
    right: 12.5px;
    position: absolute;
    display: flex;
  `;
const BottomBar = ({
  meetingData,
  section,
  taskItems,
  meetingTab,
  setIsTemplatesOpen,
  isCreateTaskModalOpen,
  setCreateTaskModalOpen,
  setIsRecurringMeeting,
  privateNotes,
  isEditTemplateBottomBarEnabled,
}: Props) => {
  if (isEditTemplateBottomBarEnabled) return null;

  if (section === USER_CENTER_ALL_NOTES) return null;

  if (meetingData.meetingId.length === 0) return null;

  if (section === 'currentMeeting') {
    return (
      <TransparentBackgroundContainerAtom>
        <ContainerAtom>
          <PreviousBtnStyled>
            <PreviousMeetingButtonMol
              meetingData={meetingData}
              setIsRecurringMeeting={setIsRecurringMeeting}
            />
          </PreviousBtnStyled>
          <ActionButtonStyled>
            <ShareMeetingNotes
              taskItems={taskItems.tasks}
              privateNotes={privateNotes}
              closeDropdown={() => { }}
            />
            <MoreOptions
              meetingData={meetingData}
              meetingTab={meetingTab}
              isCreateTaskModalOpen={isCreateTaskModalOpen}
              setCreateTaskModalOpen={setCreateTaskModalOpen}
              setIsTemplatesOpen={setIsTemplatesOpen}
            />
          </ActionButtonStyled>
        </ContainerAtom>
      </TransparentBackgroundContainerAtom>
    );
  }
  return (
    <BackgroundColorContainerAtom>
      <ContainerAtom>
        <ActionButtonStyled>
          <CreateTask
            meetingData={meetingData}
            isOpen={isCreateTaskModalOpen}
            setIsOpen={setCreateTaskModalOpen}
          />
        </ActionButtonStyled>
      </ContainerAtom>
    </BackgroundColorContainerAtom>
  );
};

export default BottomBar;
