import create from 'zustand';
import { cfGetAttendeesFromGoogleAttendees } from '../database/firebaseMeetingAPI';
import GoogleAPI from '../external/GoogleAPI/GoogleAPI';
import ConsoleImproved from '../shared/classes/ConsoleImproved';
import PublicUsers from '../shared/classes/PublicUsers';
import {
  GapiMeetingData, GoogleAttendee, PublicUserDataV2, ResolveState,
} from '../shared/types/types';
import { PENDING, RESOLVED } from '../utils/enums';

interface AllMeetingsStore {
  resolveState: ResolveState;
  meetings: GapiMeetingData[];
  fetchMeetingsDaysAgo: number;
  users: PublicUsers;
  // eslint-disable-next-line no-unused-vars
  fetchMeetings: (daysAgo: number) => void;
  // eslint-disable-next-line no-unused-vars
  fetchUsers: (googleAttendees: GoogleAttendee[]) => Promise<PublicUserDataV2[]>;
  // getUserByEmail: (googleAttendees: GoogleAttendee[]) => Promise<void>;
}

const useMeetingsAndUsersStore = create <AllMeetingsStore>((set, get) => ({
  resolveState: PENDING,
  meetings: [],
  users: new PublicUsers(),
  fetchMeetingsDaysAgo: 0,
  fetchMeetings: async (daysAgo) => {
    const { resolveState, fetchMeetingsDaysAgo } = get();
    if (resolveState === RESOLVED && fetchMeetingsDaysAgo === daysAgo) {
      ConsoleImproved.log('Meetings already fetched');
      return;
    }

    const meetings = await GoogleAPI.getMeetingsLastSevenDays(daysAgo);
    if (meetings.resolvedState !== RESOLVED) {
      ConsoleImproved.log('Failed to fetch meetings', meetings);
      set(() => ({
        resolveState: meetings.resolvedState,
      }));
      return;
    }

    const googleAttendees = GoogleAPI.mapMeetingsToAttendees(meetings.item);
    await get().fetchUsers(googleAttendees);
    set(() => ({
      meetings: meetings.item,
      resolveState: RESOLVED,
      fetchMeetingsDaysAgo: daysAgo,
    }));
  },
  fetchUsers: async (googleAttendees: GoogleAttendee[]) => {
    const { users } = get();
    const usersNotAlreadyInList = users
      .getGoogleAttendeesNotAlreadyInList(googleAttendees);
    if (usersNotAlreadyInList.length === 0) {
      ConsoleImproved.log('Users already fetched');
      return users.getUsersAlsoInGoogleAttendees(googleAttendees);
    }

    const newUsers = await cfGetAttendeesFromGoogleAttendees(usersNotAlreadyInList);
    users.add(newUsers);
    set(() => ({
      users,
    }));
    return users.getUsersAlsoInGoogleAttendees(googleAttendees);
  },
}));

export default useMeetingsAndUsersStore;
