import React from 'react';
import * as Sentry from '@sentry/react';
import {
  AuthState, IntercomTrackEvent, LoginOrSignup,
} from '../../shared/types/types';
import {
  API_KEY, CLIENT_ID, DISCOVERY_DOCS, SCOPES,
} from '../constants';
import { LOGGED_IN_STATE, LOGGED_OUT_STATE } from '../enums';
import { analytics, auth } from '../firebase';
import { LOGIN_EVENT, SIGNUP_EVENT } from '../analytics/enums';
import { setMixpanelUserAlias } from '../mixpanel';
import { trackLoginAnalytics, trackSignUpAnalytics } from './GoogleCalendarUtils';
import { saveGoogleTokens } from './GoogleCalendarAPI';

declare let gapi:any;

type SetAuthState = React.Dispatch<React.SetStateAction<AuthState>>;

const initializeGoogleAPI = (setAuthState: SetAuthState) => {
  gapi.load('client:auth2', () => {
    listenForFirebaseState(setAuthState);
  });
};

const listenForFirebaseState = (setLoggedIn: SetAuthState) => (
  auth().onAuthStateChanged(async (user) => {
    try {
      if (user) {
        await loadCalendarAPIs();
        const profile = await gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
        const { uid } = user;
        const splitFullName = user.displayName?.split(' ');
        const firstName = await profile.getGivenName() ?? splitFullName?.[0];
        const lastName = await profile.getFamilyName() ?? splitFullName?.[1];
        const email = await profile.getEmail() ?? user.email;
        const photoURL = await profile.getImageUrl() ?? user.photoURL;

        setLoggedIn({
          userId: uid,
          userState: LOGGED_IN_STATE,
          firstName,
          lastName,
          email: email || '',
          photoUrl: photoURL || '',
        });
        console.log('user is present', {
          userId: uid,
          userState: LOGGED_IN_STATE,
          firstName,
          lastName,
          email: email || '',
          photoUrl: photoURL || '',
        });
      } else {
        setLoggedIn({
          userId: '',
          userState: LOGGED_OUT_STATE,
          firstName: '',
          lastName: '',
          email: '',
          photoUrl: '',
        });
        console.log('user is not present');
      }
    } catch (error) {
      console.error('Something went wrong while getting the google auth instance. Logging the user out');
      Sentry.captureException(error);
      signOutOfGoogleAndFirebase(true);
    }
  })
);

const logAnalytics = (
  authState: AuthState,
  intercomTrackEvent: IntercomTrackEvent,
  authenticationType: LoginOrSignup,
  signUpEmail: string = '',
) => {
  if (authenticationType === LOGIN_EVENT) trackLoginAnalytics(authState, intercomTrackEvent);

  trackSignUpAnalytics(authState, intercomTrackEvent, signUpEmail);
};

export const signInToGoogleAndFirebase = (
  authState: AuthState,
  onScopesNotGranted: any,
  intercomTrackEvent: any,
) => {
  gapi.auth2.authorize({
    client_id: CLIENT_ID,
    scope: SCOPES,
    response_type: 'code token id_token',
  }, (response:any) => {
    if (response.error) {
      Sentry.captureException(response.error);
    }
    if (checkGrantedScopes(SCOPES, response.scope)) {
      const token = response.id_token;
      const credentials = auth.GoogleAuthProvider.credential(token);
      analytics().logEvent('login', { method: 'google' });
      auth().signInWithCredential(credentials)
        .then(async (user: any) => {
          console.log('Signed in with Firebase. Yeah!');

          const isNewUser = user.additionalUserInfo?.isNewUser ?? false;
          const userId = user.user?.uid ?? '';
          const email = user.user?.email ?? '';

          if (isNewUser) {
            await saveGoogleTokens(userId, response.code);
            setMixpanelUserAlias(userId);
            logAnalytics(authState, intercomTrackEvent, SIGNUP_EVENT, email);
            analytics().logEvent('signup', { method: 'firebase' });
            return;
          }

          logAnalytics(authState, intercomTrackEvent, LOGIN_EVENT);
          analytics().logEvent('login', { method: 'firebase' });
        })
        .catch((error: Error) => {
          console.log('Something went wrong when signing in to Shepherd (firebase)');
          console.log(error);
          Sentry.captureException(error);
          signOutOfGoogleAndFirebase();
        });
    } else {
      onScopesNotGranted();
    }
  });
};

const loadCalendarAPIs = async () => gapi.client.init({
  apiKey: API_KEY,
  clientId: CLIENT_ID,
  discoveryDocs: DISCOVERY_DOCS,
  scope: SCOPES,
});

const checkGrantedScopes = (
  demandedScopes: string,
  grantedScopes: string,
): boolean => demandedScopes.split(' ')
  .every((demandedScope) => grantedScopes.split(' ').includes(demandedScope));

export const signOutOfGoogleAndFirebase = (redirectToSignIn = false) => {
  auth().signOut().then(() => {
    analytics().logEvent('logout', { method: 'firebase' });
  });
  gapi.auth.signOUt();
  analytics().logEvent('logout', { method: 'google' });
  if (redirectToSignIn) window.location.replace(`${window.location.origin}/signin`);
};

export const getGAPI = () => gapi;

export default initializeGoogleAPI;
