import React from 'react';
import { Scrollbar as ScrollbarType, ScrollStatus } from 'smooth-scrollbar/interfaces';
import styled from 'styled-components';
import Scrollbar from '../../../shared/components/scrollbar';
import { GapiMeetingData } from '../../../shared/types/types';
import DayLabelAtom from '../DayLableAtom';
import NoteItemMolecule from './NoteItemMolecule';

const Container = styled.div`

`;

interface Props {
  groupedNotes: { [date: string]: GapiMeetingData[] };
  // eslint-disable-next-line no-unused-vars
  onAction: (eventId: string, calendarId: string) => void,
  // eslint-disable-next-line no-unused-vars
  onScroll: (status: ScrollStatus, scrollbar: ScrollbarType | null) => void,
}

// TODO add loading state

const NotesListOrganism = ({ groupedNotes, onAction, onScroll }: Props) => {
  const handleScroll = async (status: ScrollStatus, scrollbar: ScrollbarType | null) => {
    onScroll(status, scrollbar);
  };
  return (
    <Container>
      {/* <NotesListLabelAtom text="Most recent" />
    <SelectAllMolecule /> */}
      <Scrollbar key="allNotesSection" maxHeight="calc(100vh - 160px)" onScroll={handleScroll}>
        {Object.keys(groupedNotes).map((eachDay) => (
          <div key={eachDay}>
            <DayLabelAtom date={eachDay} />
            {groupedNotes[eachDay].map((note) => (
              <NoteItemMolecule key={note.id} note={note} onAction={onAction} />
            ))}
          </div>
        ))}
      </Scrollbar>
    </Container>
  );
};

export default NotesListOrganism;
