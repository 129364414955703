import React from 'react';
import { DropdownOption } from '../../utils/types';
import MultiSelectDropdownOption from '../multi-select-dropdown-option-atom';
import MultiSelectDropdownContainer from './utils/styles';

interface MultiSelectDropdownProp {
  dropdownOptions: Array<DropdownOption>,
  selectedOptions: Array<string>,
  isDropdownOpen: boolean,
  /* eslint-disable-next-line */
  onDropdownOptionClick: (option: string) => void,
}

const MultiSelectDropdown = ({
  dropdownOptions,
  selectedOptions,
  isDropdownOpen,
  onDropdownOptionClick,
}: MultiSelectDropdownProp) => {
  if (!isDropdownOpen) return null;

  return (
    <MultiSelectDropdownContainer>
      {dropdownOptions.map((option) => (
        <MultiSelectDropdownOption
          key={option.id}
          option={option}
          selectedOptions={selectedOptions}
          onDropdownOptionClick={onDropdownOptionClick}
        />
      ))}
    </MultiSelectDropdownContainer>
  );
};

export default MultiSelectDropdown;
