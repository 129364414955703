import moment from 'moment';
import { GapiMeetingData } from '../types/types';
import ConsoleImproved from './ConsoleImproved';

/**
 * A class to represent a date, with many useful methods.
 *
 * We represent date in in the format defined in
 * rfc3339 - https://datatracker.ietf.org/doc/html/rfc3339
 */
class SDateTestOutNewPattern {
  readonly timestamp: number;

  readonly date: string;

  constructor(newDate?: Date) {
    this.timestamp = Number(moment(newDate ?? new Date()).format('x'));
    this.date = newDate?.toUTCString() ?? new Date().toUTCString();
  }

  getHoursMinutesAndSeconds(): string {
    return moment(this.date).format('HH:mm:ss');
  }

  /**
   * Returns the current date in 'HH:mm:ss' format
   *
   * @Examples
   *
   * `14:12:59` where `14` is hours, `12` is minutes and `59` is seconds
   *
   * `08:12:59` where `08` is hours, `12` is minutes and `59` is seconds
   */
  static getCurrentHoursMinutesAndSeconds(): string {
    return moment(new Date()).format('HH:mm:ss');
  }

  /**
   * Returns the current date in 'mm:ss:SS' format, SS is milliseconds
   *
   * @Examples
   *
   * `00:00:00` where `00` is minutes, `00` is seconds and `00` is milliseconds
   *
   * `59:59:99` where `59` is minutes, `59` is seconds and `99` is milliseconds
   */
  static getMinutesSecondsAndHundredths(): string {
    return moment(new Date()).format('mm:ss:SS');
  }

  static printPassedSinceSDate(sdate: SDateTestOutNewPattern): void {
    const secondsAndHundredths = moment(moment(new Date()).diff(moment(sdate.date))).format('s.SS');
    ConsoleImproved.log(`${secondsAndHundredths} seconds and hundredths passed since ${sdate.getHoursMinutesAndSeconds()}`);
  }

  static isMeetingCurrentlyOngoing(gapiMeeting: GapiMeetingData): boolean {
    const currentTime = moment(new Date());
    const startTime = moment(gapiMeeting.start.dateTime);
    const endTime = moment(gapiMeeting.end.dateTime);
    return currentTime.isBetween(startTime, endTime);
  }
}

export default SDateTestOutNewPattern;
