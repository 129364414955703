import React from 'react';
import styled from 'styled-components';
import { gray2, gray3 } from '../../colours';
import { header200, uiText } from '../../typography';

const DescriptionInput = styled.textarea`
  background: ${gray2};
  border: none;
  border-radius: 10px;
  width: 409px;
  height: 80px;
  margin: 8px 0 24px 0;
  border: 2px solid ${gray3};
  box-sizing: border-box;
  padding: 8px 8px 8px 8px;
  resize: none;
  ${uiText};

  ::placeholder{
    margin: 10px 0px 10px 8px;
    margin-left: 8px;
  }

  :focus{
    outline: none;
  }
`;

const TaskDescriptionSubHeader = styled.span`
  ${header200};
`;

interface Props {
  description: string,
  // eslint-disable-next-line no-unused-vars
  handleDescriptionChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
}

const TaskDescriptionComponent = ({ description, handleDescriptionChange }: Props) => (
  <>
    <TaskDescriptionSubHeader>
      Description
    </TaskDescriptionSubHeader>
    <DescriptionInput
      value={description}
      placeholder="Type task details here..."
      onChange={handleDescriptionChange}
    />
  </>
);

export default TaskDescriptionComponent;
