export const generateLastSavedText = (updatedTs: number = 1623007706675) => {
  const nowTs = Date.now();
  const diffSeconds = Math.round((nowTs - updatedTs) / 1000);
  if (diffSeconds <= 1) return 'just now';
  if (diffSeconds >= 60) {
    const minutes = Math.floor(diffSeconds / 60);
    const s = minutes > 1 ? 's' : '';
    return `${minutes} minute${s} ago`;
  }
  return `${diffSeconds} seconds ago`;
};

export const copyFormatted = (html: any) => {
  // From HTML to text which can be pasted in i.e. gmail / slack
  // Create an iframe (isolated container) for the HTML
  const container = document.createElement('div');
  container.innerHTML = html;

  // Hide element
  container.style.position = 'fixed';
  container.style.pointerEvents = 'none';
  container.style.opacity = '0';

  // Detect all style sheets of the page
  const activeSheets = Array.prototype.slice.call(document.styleSheets)
    .filter((sheet) => !sheet.disabled);

  // Mount the iframe to the DOM to make `contentWindow` available
  document.body.appendChild(container);

  // Copy to clipboard
  window?.getSelection()?.removeAllRanges();

  const range = document.createRange();
  range.selectNode(container);
  window?.getSelection()?.addRange(range);

  document.execCommand('copy');
  for (let i = 0; i < activeSheets.length; i += 1) activeSheets[i].disabled = true;
  document.execCommand('copy');
  for (let i = 0; i < activeSheets.length; i += 1) activeSheets[i].disabled = false;

  // Remove the iframe
  document.body.removeChild(container);
};

export const isEmptyHTML = (html: string): boolean => {
  if (isImgElementInHtml(html)) return false;
  const cleanedHtml = stripWhitespaceAndHtmlTag(html);
  if (typeof cleanedHtml === 'undefined') return true; // chromatic deploy fail without this check
  if (cleanedHtml.length === 0) return true;
  return false;
};

export const stripWhitespaceAndHtmlTag = (html: string): string => {
  if (html) {
    // strip HTML tags
    const stripedHtml = html.replace(/(<([^>]+)>)/g, '');
    // remove &nbsp;
    const cleanedHtml = stripedHtml.replace('&nbsp;', '');
    // trim whitespaces
    const trimmedHtml = cleanedHtml.trim();

    return trimmedHtml;
  }

  return html;
};

export const makeSubstringsBold = (text: string, subStrings: string[]) => {
  let result = text;
  subStrings.forEach((subString) => {
    result = result.replace(subString, `<b>${subString}</b>`);
  });
  return result;
};

export const makeSlackHeader = (text: string, subStrings: string[]) => {
  let result = text;
  subStrings.forEach((subString) => {
    result = result.replace(subString, `<b>${subString}</b>`);
  });
  console.log(text, subStrings, result);
  return result;
};

/**
 * Takes in a list of strings, and removes any duplicates
 */
export const removeDuplicates = (array: string[]) => Array.from((new Set(array)).values());

export const removeDuplicatesFromString = (
  value: string,
) => Array.from(new Set(value.split(','))).toString();

export const capitalize = (text: string) => text[0].toUpperCase() + text.slice(1);

export function getAllImgElements(html: string) {
  const el = document.createElement('html');
  el.innerHTML = html;
  const elements = el.getElementsByTagName('img');
  const elementsArray = Array.from(elements).map((e) => e.outerHTML);
  // Example output:
  // [ '<img class="dummy">', '<img class="ProseMirror-separator">' ]
  return elementsArray;
}

export const isImgElementInHtml = (html: string) => {
  const imgElements = getAllImgElements(html);
  // ProseMirror cursor is rendered as an img with class ProseMirror
  // We check for all img elements except these ones
  return imgElements.some((element) => !element.includes('ProseMirror'));
};

/**
 * This is a helper function to get the similarity of 2 strings
 * taken from https://stackoverflow.com/questions/10473745/compare-strings-javascript-return-of-likely
 * Input: 2 strings
 * Output: number between 0-1 , accepted value > 0.8 // TODO Asish: 0.8 is a value that is is not
 * relevant for this function. That is the parent function should decide the threshold
 * Also, I think it's not 0.8 :P
 */

export const getStringSimilarity = (string1: string, string2: string) => {
  let longer = string1;
  let shorter = string2;
  if (string1.length < string2.length) {
    longer = string2;
    shorter = string1;
  }
  const longerLength: any = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
};

function editDistance(string1: string, string2: string) {
  const s1 = string1.toLowerCase();
  const s2 = string2.toLowerCase();

  const costs: any = new Array([]);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j <= s2.length; j++) {
      // eslint-disable-next-line eqeqeq
      if (i == 0) { costs[j] = j; } else if (j > 0) {
        let newValue: any = costs[j - 1];
        if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
          newValue = Math.min(Math.min(newValue, lastValue),
            costs[j]) + 1;
        }
        costs[j - 1] = lastValue;
        lastValue = newValue;
      }
    }
    if (i > 0) { costs[s2.length] = lastValue; }
  }
  return costs[s2.length];
}
