import React, { useRef } from 'react';
import styled from 'styled-components';
import DownArrow from '../../icons/downArrow';
import UpArrow from '../../icons/upArrow';
import { header200 } from '../../typography';
import { surface } from '../../colours';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';
import { MeetingSection } from '../../types/types';
import MeetingTabDropdownOption from './DropdownOption';
import {
  DropdownMenu,
  DropdownTriggerArrow,
  DropdownInputPlaceholder,
} from '../dropdown/OnboardingDropdown';
import { PERSONAL_NOTES, SHARED_NOTES, TASK } from '../../../utils/enums';

const Container = styled.div`  
  position: relative;
  margin-bottom: 24px;
`;

const Label = styled.p`
  ${header200};
  color: ${surface};
  margin: 0 0 12px 0;
`;

export interface DefaultTabDropdownItem {
  id: number,
  value: MeetingSection,
}

export interface DefaultTabDropdownProps {
  label: string,
  name: string,
  // eslint-disable-next-line no-unused-vars
  onChange: (tab: MeetingSection) => void,
  placeholder?: string,
}

// TODO: Redo parameter placeholder, since placeholder is the actual value selected
const DefaultMeetingTabDropdown = ({
  label, name, placeholder, onChange,
}: DefaultTabDropdownProps) => {
  const DefaultTabDropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useDetectOutsideClick(DefaultTabDropdownRef, false);
  const tabOptions: MeetingSection[] = [SHARED_NOTES, PERSONAL_NOTES, TASK];

  const toggleIsDropdownOpen = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClick = (newTab: MeetingSection) => {
    onChange(newTab);
    setIsDropdownOpen(false);
  };

  return (
    <label htmlFor="dummyId">
      <Label>{label}</Label>
      <Container>
        <DropdownTriggerArrow type="button" onClick={toggleIsDropdownOpen} name={name} data-active={isDropdownOpen}>
          <DropdownInputPlaceholder>{placeholder}</DropdownInputPlaceholder>
          {isDropdownOpen ? <UpArrow /> : <DownArrow />}
        </DropdownTriggerArrow>
        <DropdownMenu
          data-active={isDropdownOpen}
          ref={DefaultTabDropdownRef}
        >
          <ul>
            {tabOptions.map((tab) => (
              <MeetingTabDropdownOption value={tab} onClick={handleClick} key={tab} />
            ))}
          </ul>
        </DropdownMenu>
      </Container>
    </label>
  );
};

DefaultMeetingTabDropdown.defaultProps = {
  placeholder: 'Choices',
};

export default DefaultMeetingTabDropdown;
