import React from 'react';
import styled from 'styled-components';
import { header400 } from '../../../typography';

const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
`;

const HeadText = styled.div`
  ${header400}
  margin-bottom: 20px;
`;

const CloseAccount = () => (
  <Container>
    <HeadText>Close account</HeadText>
    <p>
      Don’t need Shepherd any longer?
      We understand, and appreciate you using our service!
      Please email
      {' '}
      <a href="mailto:support@meetshepherd.com">support@meetshepherd.com</a>
      {' '}
      to delete your account.
    </p>
  </Container>
);

export default CloseAccount;
