import styled from 'styled-components';
import { gray7, gray9 } from '../../../shared/colours';
import { header900, uiText } from '../../../shared/typography';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  text-align: center;
`;

export const ContentWrapper = styled.div`
  width: 400px; 
  margin: 0 auto;
`;

export const HeaderText = styled.p`
  ${header900};
  margin-top: 80px;
`;

export const Subtitle = styled.p`
  ${uiText};
  margin: 24px 0 80px;
`;

export const LogInWithGoogleButtonContainer = styled.div`
  button {
    background: ${gray9};

    :hover {
      background: ${gray7};
    }
  }
`;
