import React, {
  createContext, ReactNode, useCallback, useContext, useMemo, useReducer,
} from 'react';

export interface UIState {
  isInviteMemberModalOpen: boolean;
}

export interface UIStateController {
  openInviteMemberModal: () => void;
  closeInviteMemberModal: () => void;
}

const initialState: UIState = {
  isInviteMemberModalOpen: false,
};

export const UIContext = createContext<UIStateController & UIState | UIState>(initialState);

UIContext.displayName = 'UIContext';

type UIAction =
  | {
    type: 'OPEN_INVITE_MEMBER_MODAL';
  } | {
    type: 'CLOSE_INVITE_MEMBER_MODAL';
  };

const uiReducer = (state: UIState, action: UIAction): UIState => {
  if (action.type === 'OPEN_INVITE_MEMBER_MODAL') {
    return {
      ...state,
      isInviteMemberModalOpen: true,
    };
  }
  if (action.type === 'CLOSE_INVITE_MEMBER_MODAL') {
    return {
      ...state,
      isInviteMemberModalOpen: false,
    };
  }

  return state;
};

interface Props {
  children: ReactNode;
}

export const UIProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(uiReducer, initialState);

  const openInviteMemberModal = useCallback(
    () => dispatch({ type: 'OPEN_INVITE_MEMBER_MODAL' }),
    [dispatch],
  );
  const closeInviteMemberModal = useCallback(
    () => dispatch({ type: 'CLOSE_INVITE_MEMBER_MODAL' }),
    [dispatch],
  );

  const value: UIStateController & UIState = useMemo(
    () => ({
      ...state,
      openInviteMemberModal,
      closeInviteMemberModal,
    }),
    [state],
  );

  return (
    <UIContext.Provider value={value}>
      {children}
    </UIContext.Provider>
  );
};

export const useUI = () => {
  const context = useContext(UIContext) as UIStateController & UIState;
  if (context === undefined) {
    throw new Error('useUI must be used within a UIProvider');
  }
  return context;
};
