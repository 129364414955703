import React, {
  Dispatch, SetStateAction, useContext, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../../App';
import { gray1 } from '../../../shared/colours';
import HorizontalLoader from '../../../shared/components/css-spinner/HorizontalLoader';
import DeletePrivateNoteConfirmationModal from '../../../shared/components/private-notes/delete-private-note-confirmation-modal';
import PrivateNotesTab from '../../../shared/components/private-notes/private-notes-tab-organism';
import { deletePrivateNote, emptyPrivateNoteData } from '../../../shared/components/private-notes/utils/functions';
import {
  MeetingData, MeetingSection, MeetingVersion, PrivateNoteData,
} from '../../../shared/types/types';
import {
  AGENDA, PERSONAL_NOTES, SHARED_NOTES, TASK,
} from '../../../utils/enums';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';
import MeetingDataContext from '../context/MeetingDataContext';
import AgendaTabButtonAtom from './AgendaTabButtonAtom';
import TabDivButton from './TabDivButton';
import { handleSetTabAfterPrivateNoteDeleted } from './TabUtils';

const TabsMenuContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  background-color: ${gray1};
  padding: 13px 16px;
  width: 100%;
`;
export interface Props {
  meetingData: MeetingData,
  meetingTab: MeetingSection,
  setMeetingTab: Dispatch<SetStateAction<MeetingSection>>,
  closeTemplatesView?: () => void
  unviewedTasks: number,
  setPrivateNoteOpen?: Dispatch<SetStateAction<boolean>>,
  privateNotes: PrivateNoteData[],
  // eslint-disable-next-line no-unused-vars
  onClickSelectPrivateNote: (chatId: string) => void,
  setPrivateNotes: Dispatch<SetStateAction<PrivateNoteData[]>>,
  meetingDataVersion: MeetingVersion,
  activePrivateNoteId: string,
}

const TabsMenu = ({
  meetingTab,
  setMeetingTab,
  unviewedTasks,
  closeTemplatesView = () => null,
  meetingDataVersion,
  onClickSelectPrivateNote,
  setPrivateNotes,
  setPrivateNoteOpen,
  privateNotes,
  activePrivateNoteId,
}: Props) => {
  const meetingData = useContext(MeetingDataContext);
  const authState = useContext(AuthContext);

  const meetingTabsRef = useRef(document.createElement('div'));
  const deletePrivateNoteModal = useRef(null);

  const [activePrivateNote, setActivePrivateNote] = useState<number>(-1);
  const [isDeletePrivateNoteState, setIsDeletePrivateNoteState] = useState<boolean>(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useDetectOutsideClick(
    deletePrivateNoteModal, false,
  );
  const [chatToDelete, setChatToDelete] = useState<PrivateNoteData>(emptyPrivateNoteData());

  useEffect(() => {
    if (privateNotes.length === 0 && activePrivateNoteId.length === 0) setMeetingTab(SHARED_NOTES);
  }, [privateNotes]);

  useEffect(() => {
    if (activePrivateNoteId.length === 0) {
      return;
    }

    privateNotes
      .filter((privateNote) => privateNote.chatId !== chatToDelete.chatId)
      .forEach((privateNote: PrivateNoteData, index) => {
        if (privateNote.chatId === activePrivateNoteId) {
          setActivePrivateNote(index);
        }
      });
  }, [activePrivateNoteId]);

  useEffect(() => {
    if (!isDeletePrivateNoteState) return;

    deletePrivateNote(authState.userId, chatToDelete, setPrivateNotes, meetingData.meetingId);
    setIsDeletePrivateNoteState(false);
    handleSetTabAfterPrivateNoteDeleted(
      privateNotes,
      setMeetingTab,
      setActivePrivateNote,
      onClickSelectPrivateNote,
      chatToDelete,
    );
  }, [isDeletePrivateNoteState]);

  const onClickSetNotesTab = (pressedNoteTab: MeetingSection) => () => {
    setMeetingTab(pressedNoteTab);
    closeTemplatesView();
  };

  const onClickOpenPrivateNotesModal = () => {
    if (setPrivateNoteOpen) setPrivateNoteOpen(true);
  };

  const onDeletePrivateNote = () => {
    setIsDeletePrivateNoteState(true);
    setIsDeleteConfirmationOpen(false);
  };

  const onCancelDeletePrivateNote = () => setIsDeleteConfirmationOpen(false);

  if (meetingData.resolvedState !== 'resolved') {
    return (
      <TabsMenuContainer ref={meetingTabsRef}>
        <HorizontalLoader />
      </TabsMenuContainer>
    );
  }

  return (
    <TabsMenuContainer ref={meetingTabsRef}>
      <AgendaTabButtonAtom
        meetingDataVersion={meetingDataVersion}
        meetingTab={meetingTab}
        onClick={onClickSetNotesTab(AGENDA)}
      />
      <TabDivButton
        active={meetingTab === SHARED_NOTES}
        disabled={meetingTab === SHARED_NOTES}
        onClick={onClickSetNotesTab(SHARED_NOTES)}
        tooltipText="Visible to you and other attendees"
        meetingTab={SHARED_NOTES}
      >
        Shared notes
      </TabDivButton>
      <TabDivButton
        active={meetingTab === PERSONAL_NOTES}
        disabled={meetingTab === PERSONAL_NOTES}
        onClick={onClickSetNotesTab(PERSONAL_NOTES)}
        tooltipText="Strictly visible to you only"
        meetingTab={PERSONAL_NOTES}
      >
        My notes
      </TabDivButton>
      <TabDivButton
        active={meetingTab === TASK}
        disabled={meetingTab === TASK}
        onClick={onClickSetNotesTab(TASK)}
        unviewedTasks={unviewedTasks}
        tooltipText="Create and assign tasks for you and your team"
        meetingTab={TASK}
      >
        Tasks
      </TabDivButton>
      <PrivateNotesTab
        privateNotes={privateNotes}
        setPrivateNotes={setPrivateNotes}
        meetingTab={meetingTab}
        setMeetingTab={setMeetingTab}
        selectedChat={activePrivateNote}
        setSelectedChat={setActivePrivateNote}
        onClickOpenPrivateNotesModal={onClickOpenPrivateNotesModal}
        handleSecretNotesSelected={onClickSelectPrivateNote}
        setChatToDelete={setChatToDelete}
        setIsDeleted={setIsDeleteConfirmationOpen}
      />
      <DeletePrivateNoteConfirmationModal
        isOpen={isDeleteConfirmationOpen}
        onClickDeletePrivateNote={onDeletePrivateNote}
        onClickCancelDelete={onCancelDeletePrivateNote}
        refOutsideClick={deletePrivateNoteModal}
      />
    </TabsMenuContainer>
  );
};

TabsMenu.defaultProps = {
  setSecretChatOpen: null,
};

const checkIfStateAreTheSame = (prevState: Props, presentState: Props) => {
  if (prevState.meetingTab !== presentState.meetingTab
    || prevState.unviewedTasks !== presentState.unviewedTasks
    || prevState.meetingDataVersion !== presentState.meetingDataVersion
    || prevState.privateNotes !== presentState.privateNotes
  ) {
    return false;
  }
  return true;
};

export default React.memo(TabsMenu, checkIfStateAreTheSame);
