/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { Mutex } from 'async-mutex';
import OrderedMap from 'orderedmap';
// import applyDevTools from 'prosemirror-dev-tools';
import { imagePlugin, updateImageNode } from 'prosemirror-image-plugin';
import {
  MarkSpec, Node, NodeSpec,
  Schema,
} from 'prosemirror-model';
import { marks, schema } from 'prosemirror-schema-basic';
import { addListNodes } from 'prosemirror-schema-list';
import {
  EditorState, TextSelection, NodeSelection, AllSelection,
} from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  bufferTime, fromEvent,
  map, throttleTime,
} from 'rxjs';
import { IndexeddbPersistence } from 'y-indexeddb';
import { yCursorPlugin, ySyncPlugin, yUndoPlugin } from 'y-prosemirror';
import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';
import * as awarenessProtocol from 'y-protocols/awareness';
// TODO: Uncomment this on release
import { getDbRef } from '../../../../utils/firebase';
import { yjs } from '../../../../utils/yjs-backend';
import { MeetingSection, NoteType, ResolvedState } from '../../../types/types';
import setup from '../logic';
import lazyTransactionAdapter from '../logic/adapters/lazy-transaction-adapter';
import linkPreviewAdapter from '../logic/adapters/link-preview-adapter';
import tableControlsAdapter from '../logic/adapters/table-controls-adapter';
import { buildColorMarks, defaultColors } from '../logic/marks/color-marks';
import fontSize from '../logic/marks/font-size';
import fonts from '../logic/marks/fonts';
import { buildHighlightMarks, defaultHighlights } from '../logic/marks/highlight-marks';
import strikethrough from '../logic/marks/strikethrough';
import underline from '../logic/marks/underline';
import { tableNodes } from '../logic/menu/helpers/table-utils';
import { todoItemSpec, todoListSpec } from '../logic/nodes/checkbox';
import { ImagePluginSettingsFactory } from '../logic/nodes/image';
import MentionSpec from '../logic/nodes/mention';
import SpanSpec from '../logic/nodes/span';
import { TaskSpec, TaskView } from '../logic/nodes/task';
import TimestampSpec from '../logic/nodes/timestamp';
import TimestampDividerSpec from '../logic/nodes/timestamp-divider';
import codeMirror from '../logic/plugins/code-mirror';
import placeholder from '../logic/plugins/placeholder';
import suggestions from '../logic/suggestions';
// import { yjs } from '../../../../utils/yjs-backend';

// Note: This is a temporary file, used for development by inventiff
// import { yjs } from '../../../../utils/inventiff-yjs-backend';
// Note: This is the file we should be using in prod

// TODO: Comment this on release (or delete it)
// import { getDbRef } from '../../../../utils/inventiff-firebase';
//! THIS IS NOT requried, but it might need additional changes to be abel to remove

function placeholderMessage(page: NoteType) {
  switch (page) {
    case 'agenda':
      return 'Type \'@\' to mention someone or \'/\' for commands';
    case 'shared':
      return 'Type \'@\' to mention someone or \'/\' for commands';
    case 'private':
      return 'Only you can see these notes. Type \'@\' to mention someone or \'/\' for commands';
    default:
      return 'Type \'@\' to mention someone or \'/\' for commands';
  }
}
export interface HookProps {
  path: string;
  canEdit?: boolean;
  page: MeetingSection;
  toggleImageUpload: () => void;
  username?: string;
  userId?: string;
}

export function userToColour(id: number | string) {
  const num = id
    .toString()
    .split('')
    .reduce((acc: number, cur: string) => acc + Number(cur.charCodeAt(0)), 0);

  const colours = ['red', 'green', 'cyan', 'purple', 'blue', 'orange', 'pink', 'darkblue'];

  return colours[num % colours.length];
}

export function userToHex(id: number | string) {
  const num = id
    .toString()
    .split('')
    .reduce((acc: number, cur: string) => acc + Number(cur.charCodeAt(0)), 0);

  const colours = ['#fcc5cb', '#97f7db', '#91e5fa', '#bea4f5', '#78b2f5', '#FF772A', '#f70998', '#261B9F'];

  return colours[num % colours.length];
}

export default function useProseMirrorYjs(props: HookProps) {
  const proseMirrorRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState<ResolvedState>('pending');

  const [editorView, setEditorView] = useState<EditorView | null>(null);

  const [editorSchema, setEditorSchema] = useState<Schema | null>(null);

  /**
   * This state is not used only for readonly.
   * Currently, it can also be used for hiding, masking, deleting, etc. -
   * on a lost connection.
   */
  const [editorReadonly, setEditorReadonly] = useState<boolean>(false);

  const mutex = useMemo(() => new Mutex(), []);

  useEffect(() => {
    const baseMarks = {} as Record<string, MarkSpec>;

    const ydoc = new Y.Doc();

    const providerOptions = {
      connect: true,
      awareness: new awarenessProtocol.Awareness(ydoc),
      WebsocketPolyfill: WebSocket,
      resyncInterval: 30000,
      maxBackoffTime: 2500,
    };

    /* eslint-disable global-require */
    const provider = new WebsocketProvider(
      yjs.websocketProvider,
      props.path,
      ydoc,
      providerOptions,
    );
    /* eslint-enable global-require */
    let persistence: IndexeddbPersistence;
    let editorInitialized = false;
    provider.on('status', (event: { status: any; }) => {
      console.log('provider status', event.status, 'editorInitialized', editorInitialized);
      if (event.status === 'connected' && !editorInitialized) {
        onConnectionSuccess();
      }
    });

    const onConnectionSuccess = () => {
      editorInitialized = true;
      console.log('editor initialized');
      setLoading('resolved');
      persistence = new IndexeddbPersistence(props.path, ydoc);
      persistence.on('synced', () => {
      });
      mutex.runExclusive(async () => {
        const { awareness } = provider;

        const updateAwareness = () => {
          awareness.setLocalStateField('user', {
            name: props.username || 'Participant',
            yjsId: ydoc.clientID,
            userId: props.userId || ydoc.clientID,
            timestamp: Date.now(),
            color: userToHex(props.userId || ydoc.clientID),
          });
        };

        const myCursorBuilder = (user: any) => {
          const colour = userToColour(user.userId);
          const cursor = document.createElement('span');
          cursor.classList.add('ProseMirror-yjs-cursor');
          cursor.classList.add(`pyc-${colour}-caret`);
          const userDiv = document.createElement('span');
          userDiv.classList.add('ProseMirror-yjs-cursor-user');
          userDiv.classList.add(`pyc-${colour}-body-cursor`);
          userDiv.setAttribute('spellcheck', 'false');
          userDiv.contentEditable = 'false';
          userDiv.id = `yjs-cursor-${user.yjsId}`;
          // userDiv.setAttribute('style', 'background-color: red');
          userDiv.setAttribute('data-username', user.name);
          // userDiv.insertBefore(document.createTextNode(user.name), null);
          cursor.insertBefore(userDiv, null);

          cursor.addEventListener('move', () => {
            // console.debug('Move detected.');
          });

          return cursor;
        };

        fromEvent(awareness, 'change')
          .pipe(
            map((updatePayload: any) => [...updatePayload[0].added, ...updatePayload[0].updated]),
            bufferTime(1500),
          )
          .subscribe((changes) => {
            const changedCursors = new Set(changes.flat(2));
            const cursors = document.querySelectorAll('.ProseMirror-yjs-cursor-user');
            cursors.forEach((cursor) => {
              const userId = cursor.id.match(/\d+/);
              if (userId && changedCursors.has(parseInt(userId[0], 10))) {
                cursor.classList.add('ProseMirror-yjs-cursor-up');
              } else {
                cursor.classList.remove('ProseMirror-yjs-cursor-up');
              }
            });
          });

        view = new EditorView(proseMirrorRef.current!, {
          state: EditorState.create({
            schema: prosemirrorSchema,
            plugins: [
              ySyncPlugin(yXmlFragment),
              // @ts-ignore
              yCursorPlugin(awareness, {
                cursorBuilder: myCursorBuilder,
              }),
              yUndoPlugin(),
              ...setup({ schema: prosemirrorSchema }),
              placeholder(placeholderMessage(props.page as NoteType)),
              suggestions,
              imagePlugin(
                prosemirrorSchema,
                ImagePluginSettingsFactory(props.path),
              ),
              ...codeMirror(),
            ],
          }),
          nodeViews: {
            task(node: Node, v: EditorView, getPos: () => number) {
              return new TaskView(node, v, getPos);
            },
          },
        });

        // applyDevTools(view);

        updateAwareness();

        fromEvent(view.dom, 'input')
          .pipe(
            throttleTime(1000),
          ).subscribe(() => {
            updateAwareness();
          });

        setEditorView(view);
        lazyTransactionAdapter.view = view;
        // view.focus();
        if (view.state.selection.empty) {
          console.log('empty selection focus');
          view.focus();
        } else {
          const lastPosition = view.state.doc.content.size;
          console.log('non-empty selection focus');
          const sel = view.state.selection;
          const $pos = sel.$from;
          let { depth } = $pos;
          if (depth) {
            while ($pos.node(depth).isInline) {
              depth -= 1;
            }
          }
          if (!$pos.node(depth).isTextblock) {
            console.log('not textblock');
          }
          view.dispatch(view.state.tr.setSelection(
            TextSelection.create(
              view.state.doc, $pos.start(depth),
            ),
          ));
          view.focus();
        }
      });
    };

    const whileConnecting = () => {
      console.log('whileConnecting');
    };

    const yXmlFragment = ydoc.getXmlFragment('prosemirror');

    const codeBlockSpec = (schema.spec.nodes as any).get('code_block');

    const prosemirrorSchema: Schema = new Schema({
      nodes: addListNodes(
        (schema.spec.nodes as OrderedMap<NodeSpec>)
          .append(
            tableNodes({
              tableGroup: 'block',
              cellContent: 'block+',
              cellAttributes: {
                background: {
                  default: null,
                  getFromDOM(dom: any) {
                    return dom.style.backgroundColor || null;
                  },
                  setDOMAttr(value: any, attrs: any) {
                    if (value) {
                      // eslint-disable-next-line no-param-reassign
                      attrs.style = `${(attrs.style || '')};background-color: ${value};`;
                    }
                  },
                },
                'text-align': {
                  default: null,
                  getFromDOM(dom: any) {
                    return dom.style.textAlign || null;
                  },
                  setDOMAttr(value: any, attrs: any) {
                    if (value) {
                      // eslint-disable-next-line no-param-reassign
                      attrs.style = `${(attrs.style || '')};text-align: ${value};`;
                    }
                  },
                },
              },
            }) as any,
          ).append({
            resolvedTimestamp: TimestampSpec,
            resolvedMention: MentionSpec,
            textInlineNode: SpanSpec,
            todo_list: todoListSpec,
            todo_item: todoItemSpec,
            task: TaskSpec,
            timestampDivider: TimestampDividerSpec,
          }).append(
            updateImageNode(
              schema.spec.nodes,
              ImagePluginSettingsFactory(props.path),
            ),
          ).update(
            'code_block',
            {
              ...(codeBlockSpec || {}),
              attrs: { ...codeBlockSpec?.attrs, lang: { default: 'javascript' } },
            },
          ),
        'paragraph block*',
        'block',
      ),
      marks: Object.assign(
        baseMarks,
        marks,
        {
          underline,
          fontSize,
          strikethrough,
          ...fonts,
        },
        buildColorMarks(...defaultColors),
        buildHighlightMarks(...defaultHighlights),
      ),
    });

    setEditorSchema(prosemirrorSchema);
    lazyTransactionAdapter.setSchema(prosemirrorSchema);

    /**
     * This mutex is required here as cleanup can
     * only happens once the text editor has been
     * instantiated. Since there is no mechanism
     * to synchronise the cleanup with regards to
     * the text editor constructor, they had to be
     * placed within the same mutex.
     */
    let view: EditorView;

    return () => {
      /**
       * See explanation at the first use of
       * mutex.runExclusive in this useEffect().
       */
      mutex.runExclusive(async () => {
        setLoading('pending');
        if (view) {
          try {
            view.destroy();
          } catch (e) {
            console.log(e);
          }
          try {
            linkPreviewAdapter.closePreviewDropdown();
          } catch (e) {
            console.log(e);
          } try {
            tableControlsAdapter.closePreviewControls();
          } catch (e) {
            console.log(e);
          }
        }

        try {
          ydoc.destroy();
        } catch (e) {
          console.log(e);
        }

        if (persistence) {
          try {
            persistence.destroy();
          } catch (e) {
            console.log(e);
          }
        }
        if (provider) {
          try {
            provider.destroy();
          } catch (e) {
            console.log(e);
          }
        }
        if (ydoc) {
          try {
            ydoc.destroy();
          } catch (e) {
            console.log(e);
          }
        }
      });
    };
  }, [props.path, props.canEdit, props.page, mutex]);

  return [proseMirrorRef, editorView, editorSchema, getDbRef, editorReadonly, loading] as const;
}
