import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import SlackMultiInput from './SlackMultiInput';
import { SlackChannel } from '../../../../shared/types/types';
import PlatformShareCheckbox from '../PlatformShareCheckbox';

const SwitchGroup = styled.div`
  & > div:not(:last-of-type) {
    margin-bottom: 16px;
  }
  margin-bottom: 0px;
`;

export interface Props {
  isSlackEnabled: boolean,
  isChecked: boolean,
  setIsChecked: Dispatch<SetStateAction<boolean>>,
  slackChannels: SlackChannel[],
  slackDms: SlackChannel[],
  setChannelsToReceive: Dispatch<SetStateAction<SlackChannel[]>>,
  channelsToReceive: SlackChannel[],
}

const ShareSlackCheckboxAndDropdown = ({
  isSlackEnabled,
  isChecked, setIsChecked, slackChannels, slackDms, setChannelsToReceive, channelsToReceive,
}: Props) => {
  if (!isSlackEnabled) return null;

  return (
    <>
      <SwitchGroup>
        <PlatformShareCheckbox platform="slack" isChecked={isChecked} setIsChecked={setIsChecked} />
      </SwitchGroup>
      <SlackMultiInput
        channels={slackChannels}
        dms={slackDms}
        isChecked={isChecked}
        setChannelsToReceive={setChannelsToReceive}
        channelsToReceive={channelsToReceive}
      />
    </>
  );
};

export default ShareSlackCheckboxAndDropdown;
