import React, { Dispatch, HTMLProps, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import {
  surface, gray1, gray4, gray8, blue6,
} from '../../../shared/colours';
import ReactTooltip from '../../../shared/components/tooltip/ReactTooltip';
import { header400 } from '../../../shared/typography';

const Container = styled.div<ContainerProps>`
  width: fit-content;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 14px 14px 0 0;
  position: relative;

  background: ${surface};
  padding: 16px 0 16px 0;

  ${({ isSelected }) => (!isSelected && css`
  &:hover {
    span {
      color: ${gray4};
    } 
  } 
  `)}

  ${({ isSelected }) => (isSelected && css`
    border-bottom: 4px solid ${blue6};
    border-radius: 4px 4px 0px 0px;
    transition: all 0.3s ease-in-out;
  `)}
`;

const SpacingContainer = styled.div<ContainerProps>`
  padding-left: 20px;
  padding-right: 20px;
  ${({ isSelected }) => (!isSelected && css`
  &:hover {
    /* background: ${gray8}; */
    & > div {
      /* background: ${gray8}; */
    }
  } 
  `)}
`;

const Text = styled.span<ContainerProps>`
  color: ${gray1};
  ${header400};
`;

interface ContainerProps {
  isSelected: boolean,
}

interface Props {
  text: string,
  isSelected: boolean,
  tooltipText: string,
  setIsHover: Dispatch<SetStateAction<boolean>>,
}

const PageTabButtonAtom = ({
  text, onClick, isSelected, tooltipText, setIsHover,
}: Props & HTMLProps<HTMLElement>) => {
  const onMouseEnter = () => isSelected && setIsHover(true);

  const onMouseLeave = () => isSelected && setIsHover(false);

  return (
    <ReactTooltip tip={tooltipText} place="top">
      <SpacingContainer
        data-is-selected={isSelected}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        isSelected={isSelected}
      >
        <Container onClick={onClick} isSelected={isSelected}>
          <Text isSelected={isSelected}>{text}</Text>
        </Container>
      </SpacingContainer>
    </ReactTooltip>
  );
};

export default PageTabButtonAtom;
