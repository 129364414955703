import styled from 'styled-components';
import { surface, gray1, gray2 } from '../../colours';
import { link } from '../../typography';

interface MenuProps {
  'data-active': boolean
}

const DropdownMenu = styled.nav`
  z-index: 1;
  background: ${gray1};
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  opacity: ${(props: MenuProps) => (props['data-active'] ? 1 : 0)};
  visibility: ${(props: MenuProps) => (props['data-active'] ? 'visible' : 'hidden')};
  transform: ${(props: MenuProps) => (props['data-active'] ? 'translateY(0)' : 'translateY(-20px)')};

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    button {
      ${link};
      border: none;
      background: none;
      padding: 6px 16px;
      color: ${surface};
      cursor: pointer;
      width: 100%;
      text-align: left;
      
      &:hover {
        background: ${gray2};
      }
    }
  }
`;

export default DropdownMenu;
