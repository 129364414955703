import styled from 'styled-components';
import { TabList } from 'react-tabs';

export const TabButtonsContainerStyles = styled.div`
  .react-tabs__tab-list {
    margin: 0;
    padding: 0;
    display: flex;
  }
`;

const TabButtonsContainer = TabList;

export default TabButtonsContainer;
