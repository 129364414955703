import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ScrollStatus, Scrollbar as ScrollbarType } from 'smooth-scrollbar/interfaces';
import { Tabs as ReactTabs } from 'react-tabs';
import TabButton, { TabButtonStyles } from './TabButton';
import TabButtonsContainer, { TabButtonsContainerStyles } from './TabButtonsContainer';
import TabContent, { TabContentStyles } from './TabContent';
import Scrollbar from '../scrollbar';

const TabsStyles = styled.div`
  .react-tabs {
    min-height: ${(props: any) => props['data-height']};
    padding: 0px 16px;
  }
`;

interface Props {
  tabIndex: number,
  // eslint-disable-next-line no-unused-vars
  setTabIndex: (index: number) => void,
  children: ReactNode,
  height: string,
  // eslint-disable-next-line no-unused-vars
  onScroll?: ((status: ScrollStatus, scrollbar: ScrollbarType | null) => void) | undefined,
}

const TaskTabs = ({
  children, tabIndex, setTabIndex, height, onScroll,
}: Props) => (
  <Scrollbar maxHeight={height} onScroll={onScroll}>
    <TabsStyles data-height={height}>
      <TabButtonsContainerStyles>
        <TabButtonStyles>
          <TabContentStyles>
            <ReactTabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
              {children}
            </ReactTabs>
          </TabContentStyles>
        </TabButtonStyles>
      </TabButtonsContainerStyles>
    </TabsStyles>
  </Scrollbar>
);

TaskTabs.defaultProps = {
  onScroll: undefined,
};

export default TaskTabs;

export {
  TabButton, TabButtonsContainer, TabContent,
  TabButtonStyles, TabButtonsContainerStyles, TabContentStyles, TabsStyles,
};
