import { Dispatch, SetStateAction } from 'react';
import { TabDropdownItem } from '../../shared/components/simple-dropdown';
import {
  TaskStatus, TrelloBoardData, TrelloData, TrelloListData,
} from '../../shared/types/types';
import { defaultTrelloData, getTrelloBoardsAPIResponse, TrelloAPIBoard } from './trelloUtils';

export const updateList = (
  type: TaskStatus,
  selectedOption:TabDropdownItem,
  listsResponse: TrelloAPIBoard[],
  setTrelloData: Dispatch<SetStateAction<TrelloData>>,
) => {
  const list:TrelloListData = listsResponse
    .filter(
      (listObj:TrelloAPIBoard) => listObj.id === selectedOption.id,
    )
    .map((listObj:TrelloAPIBoard) => ({
      listId: listObj.id,
      listName: listObj.name,
    }))[0];

  if (type === 'todo') {
    setTrelloData((prev) => ({
      ...prev,
      board: {
        ...prev.board,
        todoList: list,
      },
    }));
  }
  if (type === 'inProgress') {
    setTrelloData((prev) => ({
      ...prev,
      board: {
        ...prev.board,
        inProgressList: list,
      },
    }));
  }
  if (type === 'completed') {
    setTrelloData((prev) => ({
      ...prev,
      board: {
        ...prev.board,
        completedList: list,
      },
    }));
  }
};

export const updateWorkspace = (selectedOption:TabDropdownItem,
  setTrelloData:Dispatch<SetStateAction<TrelloData>>) => {
  setTrelloData((prev) => ({
    ...prev,
    workspaceId: selectedOption.id,
    workspaceName: selectedOption.value,
    board: defaultTrelloData.board,
  }));
};

export const updateBoard = (selectedOption:TabDropdownItem,
  boardResponse:getTrelloBoardsAPIResponse[],
  setTrelloData:Dispatch<SetStateAction<TrelloData>>) => {
  const boardData:TrelloBoardData = boardResponse.filter(
    (boardObj:getTrelloBoardsAPIResponse) => boardObj.id === selectedOption.id,
  ).map((boardObj:getTrelloBoardsAPIResponse) => ({
    ...defaultTrelloData.board,
    boardId: boardObj.id,
    boardName: boardObj.name,
    boardURL: boardObj.url,
  }))[0];
  setTrelloData((prev) => ({
    ...prev,
    board: boardData,
  }));
};
