import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../App';
import { dbSaveTrelloData } from '../../database/firebaseTrelloAPI';
import CssSpinner from '../../shared/components/css-spinner';
import { surface } from '../../shared/colours';
import { TrelloData, ResolveState, ResolvedState } from '../../shared/types/types';
import { header400 } from '../../shared/typography';
import { defaultTrelloData } from '../../utils/trello/trelloUtils';
import { logIntegrationsEvent } from '../../utils/analytics/eventLogger';
import { ENABLE_EVENT, REDIRECT_LOCATION, TRELLO_INTEGRATION } from '../../utils/analytics/enums';

const TextContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  ${header400}

  margin-top: 50px;
`;

export default function TrelloRedirectPage() {
  const [successfullyIntegratedTrello, setSuccessfullyIntegratedTrello] = useState<ResolveState>('pending');
  const [isValidCode, setIsValidCode] = useState<Boolean>(false);
  const [token, setToken] = useState('');
  const { userId } = useContext(AuthContext);

  useEffect(() => {
    const tokenCode: string = window.location.href.split('=').pop() || '';
    console.log(tokenCode);
    if (tokenCode.length > 10) {
      setIsValidCode(true);
      setToken(tokenCode);
    } else {
      setIsValidCode(false);
    }
  }, []);

  useEffect(() => {
    if (isValidCode) {
      const trelloData: TrelloData = {
        ...defaultTrelloData,
        accessToken: token,
      };
      console.log(trelloData);
      dbSaveTrelloData(
        userId,
        trelloData,
        setIntegrationStatus as React.Dispatch<React.SetStateAction<ResolveState>>,
      );
    }
  }, [isValidCode]);

  const setIntegrationStatus = (status: ResolvedState) => {
    setSuccessfullyIntegratedTrello(status);
    if (status === 'resolved') logIntegrationsEvent(userId, ENABLE_EVENT, TRELLO_INTEGRATION, REDIRECT_LOCATION);
  };

  if (!isValidCode) {
    return (
      <TextContainer>
        The code provided is not valid
      </TextContainer>
    );
  }

  if (successfullyIntegratedTrello === 'pending') {
    return (
      <TextContainer>
        Loading...
        {' '}
        <CssSpinner color={surface} size={30} thickness={4} padding={4} />
      </TextContainer>
    );
  }

  if (successfullyIntegratedTrello === 'resolved') {
    return (
      <TextContainer>
        Trello Integrated Successfully! 🎉 you can close this page.
      </TextContainer>
    );
  }

  return (
    <TextContainer>
      Something went wrong
    </TextContainer>
  );
}
