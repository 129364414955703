import { Field, useField } from 'formik';
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import DownArrow from '../../icons/downArrow';
import UpArrow from '../../icons/upArrow';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';
import DropdownTriggerArrow from './DropdownTriggerArrow';
import DropdownMenu from './DropdownMenu';
import DropdownErrorMessage from './DropdownErrorMessage';
import DropdownInputPlaceholder from './DropdownInputPlacehoder';
import ModalLabel from '../Modal/ModalLabel';

const Container = styled.div`  
  position: relative;
`;
interface DropdownItems {
  id: number,
  value: string,
}

interface DropdownProps {
  id: string,
  label: string,
  name: string,
  items: Array<DropdownItems>,
  placeholder?: string,
}

const OnboardingDropdown = ({
  id, label, name, items, placeholder,
}: DropdownProps) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (isTouched) {
      setTouched(true);
    }
  }, [isTouched]);

  const [, meta, helpers] = useField(name);

  const { value, error, touched } = meta;
  const { setValue, setTouched } = helpers;

  const onClick = () => {
    setIsActive(!isActive);
    setIsTouched(true);
  };

  const handleClick = (currentValue: string) => {
    setValue(currentValue);
    setIsActive(false);
  };

  const showErrorMessage = !isActive && error && touched;

  const CustomDropdown = () => (
    <label htmlFor={id}>
      <ModalLabel text={label} />
      <Container id={id}>
        <DropdownTriggerArrow type="button" onClick={onClick} name={name} value={value} data-active={isActive}>
          <DropdownInputPlaceholder>{value || placeholder}</DropdownInputPlaceholder>
          {isActive ? <UpArrow /> : <DownArrow />}
        </DropdownTriggerArrow>
        <DropdownMenu
          data-active={isActive}
          ref={dropdownRef}
        >
          <ul>
            {items.map((item) => (
              <li key={item.id}>
                <button type="button" onClick={() => handleClick(item.value)}>{item.value}</button>
              </li>
            ))}

          </ul>
        </DropdownMenu>
      </Container>
      <DropdownErrorMessage>{showErrorMessage && error}</DropdownErrorMessage>
    </label>
  );

  return <Field as={CustomDropdown} />;
};

OnboardingDropdown.defaultProps = {
  placeholder: 'Choices',
};

export default OnboardingDropdown;

export {
  DropdownErrorMessage,
  DropdownMenu,
  DropdownTriggerArrow,
  DropdownInputPlaceholder,
};
