/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { UserDataContext } from '../../../App';
import { cfCheckDesktopNotificationPermissionAPI } from '../../../database/cloudFunctionNotificationAPI';
import DesktopNotificationEnableModal from './DesktopNotificationEnableModal';

const DeskTopNotificationReminder = () => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    userId,
    data: { hasDismissedDesktopNotification },
    date: { created },
    settings: { isDesktopNotificationEnabled },
  } = useContext(UserDataContext);

  const hasSignedUpMoreThanTwoHours = () => Date.now()
    - Number(created.timestamp) > 1000 * 60 * 60 * 2;

  useEffect(() => {
    cfCheckDesktopNotificationPermissionAPI(userId)
      .then((result) => {
        console.log('here', result);
        if (result === 'resolved'
          && !hasDismissedDesktopNotification
          && !isDesktopNotificationEnabled
          && hasSignedUpMoreThanTwoHours()) {
          setIsOpen(true);
        }
      });
  }, []);

  return (
    <>
      <DesktopNotificationEnableModal isOpen={isOpen} setClosed={() => setIsOpen(false)} />
    </>
  );
};

export default DeskTopNotificationReminder;
