import React, {
  useContext, useState,
} from 'react';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../../../../../App';
import { getGoogleOfflineToken } from '../../../../../../utils/DesktopNotification';
import { toastDanger } from '../../../../../../utils/notifications';
import ButtonBig from '../../../../button-big';
import Modal from '../../../../Modal';
import ModalLineAtom from './ModalLineAtom';
import ParagraphAtom from './ParagraphAtom';
import TitleAtom from './TitleAtom';
import ImageAtom from './ImageAtom';
import WorkAtTablePath from '../../../../../img/WorkAtTable.png';
import { logDesktopNotificationEvent } from '../../../../../../utils/analytics/eventLogger';
import { CLOSE_EVENT } from '../../../../../../utils/analytics/enums';

interface Props {
  isOpen: boolean,
  setClosed: () => void,
  onLoginSuccess: () => void,
  onLoginFailure: () => void,
}

const RightAlignWrapper = styled.div`
  display: flex;
  padding: 10px 24px;
  justify-content: flex-end;
  align-items: center;

  & > button {
    margin: 0;
  }

  button:not(:last-child) {
    margin-right: 8px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  padding-bottom: 40px;
`;

const DesktopNotificationModal = (
  {
    isOpen, setClosed, onLoginSuccess, onLoginFailure,
  }: Props,
) => {
  const { userId } = useContext(UserDataContext);
  const authState = useContext(AuthContext);

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const onModalClose = () => {
    logDesktopNotificationEvent(authState.userId, CLOSE_EVENT, 'login_modal');
    onLoginFailure();
    setClosed();
  };

  const handleGoogleLogin = () => {
    setIsLoggingIn(true);
    getGoogleOfflineToken(userId)
      .then(() => {
        onLoginSuccess();
        setClosed();
      })
      .catch((error) => {
        toastDanger('Error', 'Unknown error occurred. Please try again.');
        console.log(error);
      })
      .finally(() => {
        setIsLoggingIn(false);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      setModalClosed={onModalClose}
      isHeadless
    >
      <Container>
        <ImageAtom src={WorkAtTablePath} />
        <TitleAtom>
          🎉 NEW
          <br />
          Desktop notifications
        </TitleAtom>
        <ParagraphAtom>
          In order to enable desktop notifications you need to login again into Shepherd,
          it only takes a few seconds!
        </ParagraphAtom>
      </Container>
      <ModalLineAtom />
      <RightAlignWrapper>
        <ButtonBig text="Login" onClick={handleGoogleLogin} loading={isLoggingIn} />
      </RightAlignWrapper>
    </Modal>
  );
};

export default DesktopNotificationModal;
