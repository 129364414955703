// Core functions always suffix with "Core"
import * as Sentry from '@sentry/browser';
import { functions } from '../utils/firebase';

// eslint-disable-next-line import/prefer-default-export
export const cfCheckDesktopNotificationPermissionCore = async (userId: string) => functions()
  .httpsCallable('checkNotificationPermission')({ userId })
  .then((response: any) => response.data)
  .catch((error) => {
    console.log('Error when checking notification permission', error);
    Sentry.captureException(error);
  });
