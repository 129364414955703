import { logAppEvent } from './eventLogger';
import { APP_FIELD, LOADED_EVENT } from './enums';
import { AuthState } from '../../shared/types/types';

const logAppAnalytics = (
  authState: AuthState,
  hasSidebarOpened: boolean,
) => {
  if (authState.userId.length <= 0 || !hasSidebarOpened) return;

  const urlPath = window.location.pathname;
  const emptyPreviousMeetingPath = '/meeting//previousMeeting/';
  if (urlPath !== emptyPreviousMeetingPath) logAppEvent(authState.userId, APP_FIELD, LOADED_EVENT);
};

export default logAppAnalytics;
