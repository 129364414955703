import styled from 'styled-components';
import { gray1 } from '../../../shared/colours';

const BackgroundColorContainerAtom = styled.div`
  background-color: ${gray1};
  height: 100%;
  width: 100%;
`;

export default BackgroundColorContainerAtom;
