import React from 'react';
import { DEFAULT_ICON_SIZE } from '../../utils/constants';
import { ShepherdIconContainer } from './utils/styles';

export interface ShepherdIconProps {
  width?: number,
  spin?: boolean,
}

const ShepherdIcon = ({
  width,
  spin = false,
}: ShepherdIconProps) => (
  <ShepherdIconContainer spin={spin}>
    <svg width={`${width}px`} viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon">
        <path id="Vector" d="M18.212 1.87856L14.9475 13.1872H12.8858L9.62141 1.87856L10.7382 0.669998H17.0952L18.212 1.87856Z" fill="#FE5968" />
        <path id="Vector_2" d="M27.3167 12.1517L15.8913 15.0004L14.9464 13.1876L23.0215 4.64134L24.6537 4.98665L27.8322 10.5115L27.3167 12.1517Z" fill="#2FDBA1" />
        <path id="Vector_3" d="M23.0215 25.3589L14.9464 16.8126L15.8913 14.9998L27.3167 17.8485L27.8322 19.4887L24.6537 25.0136L23.0215 25.3589Z" fill="#585DCD" />
        <path id="Vector_4" d="M10.7382 29.33L9.62141 28.1214L12.8858 16.8128H14.9475L18.212 28.1214L17.0952 29.33H10.7382Z" fill="#65DEFE" />
        <path id="Vector_5" d="M0.515432 17.8485L11.9408 14.9998L12.8858 16.8126L4.8107 25.3589L3.1785 25.0136L0 19.4887L0.515432 17.8485Z" fill="#FFCB00" />
        <path id="Vector_6" d="M4.8107 4.64134L12.8858 13.1876L11.9408 15.0004L0.515432 12.1517L0 10.5115L3.1785 4.98665L4.8107 4.64134Z" fill="#058FEF" />
      </g>
    </svg>
  </ShepherdIconContainer>
);

ShepherdIcon.defaultProps = {
  width: DEFAULT_ICON_SIZE,
  spin: false,
};

export default ShepherdIcon;
