import React, { useContext, useRef, useState } from 'react';
import * as EmailValidator from 'email-validator';
import { toastDanger, toastSuccess, toastWarning } from '../../../utils/notifications';
import Modal from '../Modal';
import ModalBodyWrapper from '../Modal/ModalBodyWrapper';
import EmailTagGroupOrganism from './EmailTagGroupOrganism';
import SendEmailMolecule from './SendEmailMolecule';
import { EmailSendingStatus, ResolvedState } from '../../types/types';
import { cfSendInviteMembersEmails } from '../../../database/cloudFunctionEmailAPI';
import { cfSearchPublicUserDataV2ByEmailsV2 } from '../../../external/publicUserData/PublicUserDataAPI';
import { trackUserInvites } from '../quick-settings/invite-members/utils/functions';
import { UserDataContext } from '../../../App';
import { INVITE_EMAIL } from '../../../utils/analytics/enums';

export interface InviteMembersModalProps {
  isOpen: boolean,
  setClosed: () => void,
}

const InviteMembersModalOrganism = ({ isOpen, setClosed }: InviteMembersModalProps) => {
  const userData = useContext(UserDataContext);
  const [emailSendingStatusList, setEmailSendingStatusList] = useState<EmailSendingStatus[]>([]);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const validateEmail = async (email: string) => {
    if (!EmailValidator.validate(email)) {
      toastDanger('Invalid email address', 'Please enter a valid email address');
      return false;
    }
    if (emailSendingStatusList.some((sendingStatus) => sendingStatus.email === email)) {
      toastWarning('Email already sent', 'An invite email has already been sent to this address');
      return false;
    }
    const publicUserData = (await cfSearchPublicUserDataV2ByEmailsV2([email]))[0];
    if (publicUserData.isShepherdUser) {
      toastWarning('Invalid email address', 'This email address is already registered with Shepherd');
      return false;
    }

    return true;
  };

  const updateEmailSendingStatus = (
    oldStatusList: EmailSendingStatus[], email: string, resolvedState: ResolvedState,
  ) => {
    const updatedSendingStatusList: EmailSendingStatus[] = oldStatusList.map(
      (sendingStatus) => (sendingStatus.email === email ? { email, resolvedState } : sendingStatus),
    );
    setEmailSendingStatusList(updatedSendingStatusList);
  };

  const sendEmail = async () => {
    if (inputRef.current === null) return;

    const newEmailToSend = inputRef.current.value;

    if (!await validateEmail(newEmailToSend)) return;

    const updatedSendingStatusList: EmailSendingStatus[] = [
      { email: newEmailToSend, resolvedState: 'pending' },
      ...emailSendingStatusList,
    ];
    setEmailSendingStatusList(updatedSendingStatusList);

    cfSendInviteMembersEmails([newEmailToSend])
      .then((resolvedState) => {
        if (resolvedState === 'resolved') {
          updateEmailSendingStatus(updatedSendingStatusList, newEmailToSend, 'resolved');
          trackUserInvites([newEmailToSend], INVITE_EMAIL, userData);
          toastSuccess('Email sent', `${newEmailToSend} has been invited to join Shepherd`);
        } else if (resolvedState === 'rejected') {
          updateEmailSendingStatus(updatedSendingStatusList, newEmailToSend, 'rejected');
          toastDanger("Couldn't send email", 'Please try again');
        }
      });

    inputRef.current.value = '';
    setIsEmailValid(false);
  };

  const onKeyPress = async (event: KeyboardEvent) => {
    if (inputRef.current === null) return;
    setIsEmailValid(EmailValidator.validate(inputRef.current.value));

    if (event.key !== 'Enter') return;
    sendEmail();
  };

  const onInviteButtonClick = async () => {
    sendEmail();
  };

  return (
    <Modal title="Invite friends to join Shepherd" setModalClosed={setClosed} isOpen={isOpen}>
      <ModalBodyWrapper>
        <SendEmailMolecule
          onKeyPress={onKeyPress}
          onInviteButtonClick={onInviteButtonClick}
          isInviteButtonDisabled={!isEmailValid}
          inputRef={inputRef}
        />
        <EmailTagGroupOrganism emailSendingStatusList={emailSendingStatusList} />
      </ModalBodyWrapper>
    </Modal>
  );
};

export default InviteMembersModalOrganism;
