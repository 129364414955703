import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { darkBlue4 } from '../../../../../colours';
import { uiTextS } from '../../../../../typography';

const Container = styled.div`
  ${uiTextS};
  color: ${darkBlue4};
  text-align: center;
`;

interface Props {
  children: ReactNode;
}

const ParagraphAtom = ({ children }: Props) => (
  <Container>
    {children}
  </Container>
);

export default ParagraphAtom;
