/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../../../../../App';
import { dbUpdateSlackData } from '../../../../../../database/firebaseSlackAPI';
import { dbSaveTrelloData } from '../../../../../../database/firebaseTrelloAPI';
import ButtonSmall from '../../../../../../shared/components/button-small';
import Modal from '../../../../../../shared/components/Modal';
import ModalBodyWrapper from '../../../../../../shared/components/Modal/ModalBodyWrapper';
import { SlackChannel, TrelloData } from '../../../../../../shared/types/types';
import {
  NOTION_PROCESSING_VIEW, SLACK_NOTIFICATIONS_VIEW, SLACK_PROCESSING_VIEW,
  SLACK_SELECT_DEFAULT_CHANNEL_VIEW, TRELLO_PROCESSING_VIEW,
} from '../../../../../../utils/constants';
import { slackCoreGetChannelNames } from '../../../../../../utils/slack/SlackCoreAPI';
import { mapDefaultChannelToSlackData } from '../../../../../../utils/slack/slackUtils';
import { defaultTrelloData } from '../../../../../../utils/trello/trelloUtils';
import NotionIntegrationProcessStatus from '../../../../../meeting/modal/NotionModalComponents/NotionIntegrationProcessStatus';
import SlackIntegrationProcessStatus from '../../../../../meeting/modal/SlackModalComponents/SlackIntegrationProcessStatus';
import SlackSelectDefaultChannel from '../../../../../meeting/modal/SlackModalComponents/SlackSelectDefaultChannel';
import TrelloIntegrationProcessStatus from '../../../../../meeting/modal/TrelloModalComponents/TrelloIntegrationProcessStatus';
import { ModalLineLocal } from '../../../../../meeting/modal/ShareNotesModal';
import { ProcessingModalData, ProcessingModalFunctions } from '../../../utils/types';

interface Props {
  data: ProcessingModalData,
  functions: ProcessingModalFunctions
}

const ProcessingModal = ({ data, functions }:Props) => {
  const { isOpen, processingState } = data;
  const { setIsOpenToClosed, setProcessingState, setActivateConfetti } = functions;

  const authState = useContext(AuthContext);
  const userData = useContext(UserDataContext);
  const accessToken = userData?.integrations?.slack[0]?.userAccessToken ?? '';
  const isSlackEnabled = accessToken.length > 10;
  const isNotionEnabled = userData?.integrations.notion.length > 0;
  const { isTrelloEnabled } = userData?.integrations.trello.settings;

  const [trelloData, setTrelloData] = useState<TrelloData>(defaultTrelloData);
  const [slackChannels, setSlackChannels] = useState<SlackChannel[]>([]);
  const [newDefaultChannel, setNewDefaultChannel] = useState<SlackChannel[]>([]);

  useEffect(() => {
    if (isSlackEnabled) slackCoreGetChannelNames(authState, accessToken, setSlackChannels);
  }, [accessToken, isSlackEnabled]);

  useEffect(() => {
    if (userData?.integrations?.trello?.accessToken.length > 10) {
      setTrelloData(userData.integrations.trello);
    }
  }, [userData]);

  const createModalTitle = () => {
    switch (processingState) {
      case SLACK_PROCESSING_VIEW:
        return 'Slack Integration';
      case SLACK_SELECT_DEFAULT_CHANNEL_VIEW:
        return 'Slack Integration';
      case SLACK_NOTIFICATIONS_VIEW:
        return 'Slack Integration';
      case NOTION_PROCESSING_VIEW:
        return 'Notion Integration';
      case TRELLO_PROCESSING_VIEW:
        return 'Trello Integration';
      default:
        return '';
    }
  };

  const title = createModalTitle();

  const selectModalBody = () => {
    switch (processingState) {
      case SLACK_PROCESSING_VIEW:
        return <SlackIntegrationProcessStatus />;
      case SLACK_SELECT_DEFAULT_CHANNEL_VIEW:
        return (
          <SlackSelectDefaultChannel
            slackChannels={slackChannels}
            setNewDefaultChannels={setNewDefaultChannel}
          />
        );
      case NOTION_PROCESSING_VIEW:
        return (
          <NotionIntegrationProcessStatus />
        );
      case TRELLO_PROCESSING_VIEW:
        return (
          <TrelloIntegrationProcessStatus
            trelloData={trelloData}
            setTrelloData={setTrelloData}
          />
        );
      default:
        return null;
    }
  };

  const handleSlackProcessing = () => {
    setProcessingState(SLACK_SELECT_DEFAULT_CHANNEL_VIEW);
  };

  const handleSelectSlackDefaultChannel = () => {
    const slackData = mapDefaultChannelToSlackData(
      newDefaultChannel,
      userData?.integrations?.slack,
    );

    dbUpdateSlackData(authState.userId, slackData);
    setActivateConfetti(true);
    setProcessingState('');
    setIsOpenToClosed();
  };

  const handleTrelloSuccessClick = async () => {
    trelloData.settings.isTrelloEnabled = true;
    await dbSaveTrelloData(authState.userId, trelloData, () => {});
    setTrelloData(defaultTrelloData);
    setActivateConfetti(true);
    setProcessingState('');
    setIsOpenToClosed();
  };

  const handleNotionSuccessClick = () => {
    setActivateConfetti(true);
    setProcessingState('');
    setIsOpenToClosed();
  };

  const selectModalFooter = () => {
    switch (processingState) {
      case SLACK_PROCESSING_VIEW:
        if (!isSlackEnabled) return null;
        return <ButtonSmall text="Next" onClick={handleSlackProcessing} />;
      case SLACK_SELECT_DEFAULT_CHANNEL_VIEW:
        return <ButtonSmall text="Confirm" onClick={handleSelectSlackDefaultChannel} />;
      case TRELLO_PROCESSING_VIEW:
        return <ButtonSmall text="Confirm" onClick={handleTrelloSuccessClick} />;
      case NOTION_PROCESSING_VIEW:
        if (!isNotionEnabled) return null;
        return <ButtonSmall text="Done" onClick={handleNotionSuccessClick} />;
      default:
        return null;
    }
  };

  const renderCloseButtonForFooter = () => {
    if (isNotionEnabled && processingState === NOTION_PROCESSING_VIEW) return null;

    return <ButtonSmall text="Close" onClick={setIsOpenToClosed} isOutline />;
  };

  return (
    <Modal title={title} isOpen={isOpen} setModalClosed={setIsOpenToClosed}>
      <ModalBodyWrapper>{ selectModalBody() }</ModalBodyWrapper>
      <ModalLineLocal />
      <ModalFooterWrapper>
        { renderCloseButtonForFooter()}
        { selectModalFooter() }
      </ModalFooterWrapper>
    </Modal>
  );
};

export default ProcessingModal;

const ModalFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 24px;

  button:last-child {
    margin-left: 8px;
  }
`;
