import React from 'react';
import styled from 'styled-components';
import { gray8 } from '../../../../../shared/colours';
import SimpleTabDropdown, { TabDropdownItem } from '../../../../../shared/components/simple-dropdown';
import { header300, uiText } from '../../../../../shared/typography';

export const SettingLabel = styled.p`
  ${header300}
  padding: 0px;
  margin: 0px 0px 8px 6px;
`;

export const SettingSubText = styled.p`
  ${uiText}
  color: ${gray8};
  padding: 0px;
  margin: 0px 0px 0px 6px;
`;

export const SettingContainer = styled.div`
  margin: 0px 0px 24px 0px;
`;

interface Props {
  label: string,
  placeholder: string,
  name: string,
  options:TabDropdownItem[],
  loading: boolean
  // eslint-disable-next-line no-unused-vars
  onChange: (tab: TabDropdownItem) => void,
  onClick: ()=>void
  emptyOptionsText?: string
}

export default function SettingTab({
  label, placeholder, name, options, loading, onChange, onClick, emptyOptionsText,
}:Props) {
  return (
    <SettingContainer>
      <SettingLabel>{label}</SettingLabel>
      <SimpleTabDropdown
        onClick={onClick}
        onChange={onChange}
        loading={loading}
        options={options}
        placeholder={placeholder}
        name={name}
        emptyOptionsText={emptyOptionsText}
      />
    </SettingContainer>
  );
}

SettingTab.defaultProps = {
  emptyOptionsText: '',
};
