import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../../../../App';
import { SlackChannel } from '../../../../../shared/types/types';
import { header200 } from '../../../../../shared/typography';
import { SLACK } from '../../../../../utils/enums';
import { slackCoreGetChannelNames, slackCoreGetDirectMessages } from '../../../../../utils/slack/SlackCoreAPI';
import { emptySlackData } from '../../../../../utils/slack/slackUtils';
import SlackMultiInput from '../../SlackModalComponents/SlackMultiInput';
import { ModalLineLocal } from '../utils/commonStyles';
import {
  APPS_VIEW, NotesSwitchData, ShareModalMode, SLACK_SHARE_VIEW,
} from '../utils/types';
import CustomMessage from './CustomMessage';
import NotesSwitch from './NotesSwitch';
import Platform from './Platform';

const SectionText = styled.h2`
  ${header200};
  margin-bottom: 16px;
`;

interface Props{
  channelsToReceive: SlackChannel[],
  setChannelsToReceive: Dispatch<SetStateAction<SlackChannel[]>>,
  notesSwitchData: NotesSwitchData,
  setNotesSwitchData: React.Dispatch<React.SetStateAction<NotesSwitchData>>,
  setMode: React.Dispatch<React.SetStateAction<ShareModalMode>>,
  emailHeadTemplate: string,
  setEmailHeadTemplate: Dispatch<SetStateAction<string>>,
}

export default function SlackView({
  channelsToReceive, setChannelsToReceive, notesSwitchData, setNotesSwitchData, setMode,
  emailHeadTemplate, setEmailHeadTemplate,
}: Props) {
  const userData = useContext(UserDataContext);
  const authState = useContext(AuthContext);

  const [slackChannels, setSlackChannels] = useState<SlackChannel[]>([]);
  const [slackDms, setSlackDms] = useState<SlackChannel[]>([]);

  const slackUserData = userData?.integrations?.slack[0] ?? emptySlackData()[0];
  const slackUserAccessToken = slackUserData.userAccessToken ?? '';
  const isSlackEnabled = slackUserAccessToken.length > 10;

  useEffect(() => {
    if (!isSlackEnabled) return;
    if (isSlackEnabled) slackCoreGetChannelNames(authState, slackUserAccessToken, setSlackChannels);
    if (isSlackEnabled) slackCoreGetDirectMessages(authState, slackUserAccessToken, setSlackDms);
  }, [slackUserAccessToken]);

  return (
    <div>
      <SectionText>Your notes will be sent to</SectionText>
      <Platform platform={SLACK} mode={SLACK_SHARE_VIEW} iconSize="26" onClick={() => { setMode(APPS_VIEW); }} />
      <SlackMultiInput
        channels={slackChannels}
        dms={slackDms}
        isChecked
        setChannelsToReceive={setChannelsToReceive}
        channelsToReceive={channelsToReceive}
      />
      <ModalLineLocal bottomMargin />
      <SectionText>Select to share</SectionText>
      <NotesSwitch notesSwitchData={notesSwitchData} setNotesSwitchData={setNotesSwitchData} />
      <ModalLineLocal />
      <SectionText>Add custom message</SectionText>
      <CustomMessage
        setEmailHeadTemplate={setEmailHeadTemplate}
        emailHeadTemplate={emailHeadTemplate}
      />
    </div>
  );
}
