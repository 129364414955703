import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { header600 } from '../../../../../typography';

const Container = styled.div`
  ${header600}
  text-align: center;
  margin: 16px 0;
`;

interface Props {
  children: ReactNode;
}

const TitleAtom = ({ children }: Props) => (
  <Container>
    {children}
  </Container>
);

export default TitleAtom;
