import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../../../../App';
import { logSettingsClicks } from '../../../../../utils/analytics/eventLogger';
import { subscribeToDesktopNotification } from '../../../../../utils/DesktopNotification';
import { checkRequirementsToEnableNotification, stopWebhooksAPI } from '../../../../../utils/DesktopNotification/apis';
import useGetQueryParam from '../../../../../utils/hook/useGetParams';
import { toastDanger } from '../../../../../utils/notifications';
import PathGenerator from '../../../../../utils/PathGenerator';
import { header400 } from '../../../../typography';
import Switch from '../../../switch';
import DesktopNotificationModal from './modal/DesktopNotificationModal';

export const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const HeadText = styled.div`
  ${header400}
`;

const SwitchGroup = styled.div`
  padding-top: 20px;
  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

interface Props {
  isDesktopNotifyModalOpen: boolean;
  setDesktopNotifyModalOpen: Dispatch<SetStateAction<boolean>>;
}

const DesktopNotificationSettings = ({
  isDesktopNotifyModalOpen,
  setDesktopNotifyModalOpen,
}: Props) => {
  const { settings, googleTokens } = useContext(UserDataContext);
  const authState = useContext(AuthContext);
  const { userId } = authState;
  const { trackEvent } = useIntercom();

  const [isDesktopNotificationEnable, setDesktopNotificationEnable] = useState<boolean>(false);
  const [isSwitchDisabled, setSwitchDisabled] = useState<boolean>(false);
  const isDesktopNotificationEnabledFromURLParams = useGetQueryParam(PathGenerator.desktopNotificationsToggleParam) === 'true';

  useEffect(() => {
    if (isDesktopNotificationEnabledFromURLParams && !settings.isDesktopNotificationEnabled) {
      handleSetDesktopNotificationEnable(true);
    }
  }, [isDesktopNotificationEnabledFromURLParams]);

  useEffect(() => {
    setDesktopNotificationEnable(settings.isDesktopNotificationEnabled);
  }, [settings.isDesktopNotificationEnabled]);

  const handleRedirect = (value: boolean) => {
    if (value && !isDesktopNotificationEnabledFromURLParams) {
      window.open(PathGenerator.enableDesktopNotifications());
      logSettingsClicks(userId, 'desktop_notification', '', trackEvent);
    } else {
      handleSetDesktopNotificationEnable(value);
    }
  };

  const handleSetDesktopNotificationEnable = (value:boolean) => {
    if (value) {
      // enable
      setSwitchDisabled(true);
      if (googleTokens.refreshToken.length !== 0) {
        enableSetting();
      } else {
        setDesktopNotifyModalOpen(true);
      }
    } else {
      // disable
      disableSetting();
    }
  };

  const enableSetting = async () => {
    // the permission will only be asked when it is not granted
    setSwitchDisabled(true);
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') return handleNotGrantedPermission();

    if (!checkRequirementsToEnableNotification(userId, settings.isDesktopNotificationEnabled)
    ) return handleNotGrantedPermission();

    const response = await subscribeToDesktopNotification(
      authState, userId, setDesktopNotificationEnable,
    );
    setSwitchDisabled(false);

    if (response !== 'resolved') return handleSomethingWentWrong();
    return handleAfterSuccessFlow();
  };

  const handleNotGrantedPermission = () => {
    toastDanger('Error', 'You have not granted permission to use desktop notifications, please do so in your browser settings');
  };

  const handleSomethingWentWrong = () => {
    toastDanger('Error', 'Something went wrong, please try again');
  };

  const handleAfterSuccessFlow = () => {
    setDesktopNotificationEnable(true);
    if (isDesktopNotificationEnabledFromURLParams) {
      setTimeout(() => {
        window.close();
      }, 5000);
    }
  };

  const disableSetting = async () => {
    setSwitchDisabled(true);
    const result = await stopWebhooksAPI(userId);
    setDesktopNotificationEnable(result !== 'resolved');
    setSwitchDisabled(false);
  };

  const onLoginSuccess = () => {
    enableSetting();
    setSwitchDisabled(false);
  };

  return (
    <Container>
      <HeadText>
        Desktop notifications (Beta)
      </HeadText>
      <SwitchGroup>
        <Switch
          label="Remind me about my meeting 1 minute before it begins"
          checked={isDesktopNotificationEnable}
          setChecked={handleRedirect as Dispatch<SetStateAction<boolean>>}
          disabled={isSwitchDisabled}
          loading={isSwitchDisabled}
        />
      </SwitchGroup>
      <DesktopNotificationModal
        isOpen={isDesktopNotifyModalOpen}
        setClosed={() => setDesktopNotifyModalOpen(false)}
        onLoginSuccess={onLoginSuccess}
        onLoginFailure={() => setSwitchDisabled(false)}
      />
    </Container>
  );
};

export default DesktopNotificationSettings;
