import React from 'react';
import styled from 'styled-components';
import ReactTooltip from '../../shared/components/tooltip/ReactTooltip';
import HeaderTextAtom from './HeaderTextAtom';
import HelpIconAtom from './HelpIconAtom';

const Container = styled.div`
  display: flex;
  align-items: center;
  & > *:first-child {
    margin-right: 12px;
  }
`;

const TooltipText = () => (
  <>
    This page is home to your meeting notes linked to your
    <br />
    calendar events.
    {/* <br />
    , as well as your unattached notesmade in your Quick notes. */}
  </>
);

const AllNotesHeaderMolecule = () => (
  <Container>
    <HeaderTextAtom text="All notes" />
    <ReactTooltip tip={TooltipText()} multiline>
      <HelpIconAtom />
    </ReactTooltip>
  </Container>
);

export default AllNotesHeaderMolecule;
