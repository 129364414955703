import React from 'react';
import { blue6 } from '../colours';

interface Props {
  fill?: string,
}

const TickIcon = ({ fill }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16.3389 4.33744C16.7048 4.61657 16.7752 5.1395 16.4961 5.50542L9.43672 14.7597C8.83129 15.5534 7.66871 15.6404 6.95181 14.9458L3.58694 11.6854C3.25641 11.3652 3.24809 10.8376 3.56835 10.5071C3.88862 10.1765 4.41619 10.1682 4.74672 10.4885L8.11158 13.7489L15.1709 4.49458C15.4501 4.12866 15.973 4.0583 16.3389 4.33744Z" fill={fill} />
  </svg>
);

TickIcon.defaultProps = {
  fill: blue6,
};

export default TickIcon;
