import create from 'zustand';

interface Counter {
  count: number;
  increment: () => void;
}

const useCounterStore = create <Counter>((set) => ({
  count: 0,
  increment: () => set((state) => {
    console.log('Just some test printing');
    return {
      count: state.count + 1,
    };
  }),
}));

export default useCounterStore;
