import React from 'react';
import styled from 'styled-components';
import ReactTooltip from '../../../shared/components/tooltip/ReactTooltip';
import IconAtom from './IconAtom';

const Container = styled.div`
  display: flex;
  cursor: pointer;

  background-color: #058FEF;
  width: 36px;
  height: 36px;
  border: 1px solid #058FEF;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  margin: 4px;

  :hover{
    border: 1px solid #37A5F2;
  }
`;

interface Props {
  icon: any,
  onClick: () => void,
  tooltipText?: string,
}

const SquareButtonMolecule = ({
  icon, onClick, tooltipText = '',
}: Props) => (
  <ReactTooltip tip={tooltipText} delay disabled={tooltipText?.length === 0 ?? true}>
    <Container onClick={onClick}>
      <IconAtom icon={icon} />
    </Container>
  </ReactTooltip>
);

SquareButtonMolecule.defaultProps = {
  tooltipText: '',
};

export default SquareButtonMolecule;
