/* eslint-disable no-unused-vars */
import * as Sentry from '@sentry/browser';
import { Dispatch, SetStateAction } from 'react';
import { functions } from '../firebase';
import {
  AuthState,
  NotionCreatePageData, NotionPageData, ResolvedState, ResolveState,
} from '../../shared/types/types';
import { logNotionUserAction } from '../analytics/eventLogger';
import {
  CREATE_EVENT, GET_EVENT, NOTION_PAGE_FIELD, NOTION_SUB_PAGE_FIELD, NOTION_WORKSPACE_PAGE_FIELD,
} from '../analytics/enums';

export const createAndSendNotionPage = (
  authState: AuthState, createPageObj: NotionCreatePageData[],
) => functions()
  .httpsCallable('createNotionPage')(createPageObj).then((res) => {
    logNotionUserAction(authState.userId, CREATE_EVENT, NOTION_PAGE_FIELD, createPageObj.length);
    return res;
  });

export const getNotionWorkspacePages = (
  authState: AuthState, searchText: string, token: string,
  setPages: Dispatch<SetStateAction<NotionPageData[]>>,
  setResolveState: Dispatch<SetStateAction<ResolveState>>,
) => {
  functions().httpsCallable('getNotionWorkspacePagesV3')({ accessToken: token, query: searchText }).then((data) => {
    logNotionUserAction(authState.userId, GET_EVENT, NOTION_WORKSPACE_PAGE_FIELD, data.data.length);
    if (data.data.length !== 0) {
      // no need to map cf response to NotionPageData[] as mapping is done in cf
      const workspacePages: NotionPageData[] = data.data;
      setPages(workspacePages);
      setResolveState('resolved');
    } else {
      setPages([]);
      setResolveState('rejected');
    }
  }).catch(() => {
    setResolveState('rejected');
  });
};

export const getNotionSubPages = (
  authState: AuthState,
  parentPageId: string, token: string,
  setPages: Dispatch<SetStateAction<NotionPageData[]>>,
  setResolveState: Dispatch<SetStateAction<ResolveState>>,
) => {
  functions().httpsCallable('getNotionSubPages')({ accessToken: token, parentPageId }).then((data) => {
    logNotionUserAction(authState.userId, GET_EVENT, NOTION_SUB_PAGE_FIELD, data.data.length);
    if (data.data.length !== 0) {
      const workspacePages: NotionPageData[] = data.data.map((page: any) => (
        {
          pageId: page.id,
          parentPageId: page.parent?.page_id || '',
          title: page.child_page.title,
          workspaceToken: token,
        }
      ));
      setPages(workspacePages);
      setResolveState('resolved');
    } else {
      setPages([]);
      setResolveState('rejected');
    }
  }).catch(() => {
    setResolveState('rejected');
  });
};

export const saveNotionData = (tempAuthCode: string, userId: string) => functions()
  .httpsCallable('saveNotionDataToUser')({ tempAuthCode, userId })
  .then((res) => ({ state: 'resolved' as ResolvedState, data: res.data }))
  .catch((error) => {
    Sentry.captureException(error);
    return { state: 'rejected' as ResolvedState, data: error };
  });
