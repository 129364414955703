import { DesktopNotification, ResolvedState } from '../../shared/types/types';
import { functions } from '../firebase';
import { toastDanger, toastSuccess } from '../notifications';

export const checkGooglePermissionAPI = (userId: string) => functions()
  .httpsCallable('checkNotificationPermission')({
    userId,
  }).then((data) => data.data.state as ResolvedState).catch(() => 'rejected' as ResolvedState);

export const getGoogleTokensAPI = (userId: string, exchangeCode: string) => functions().httpsCallable('getGoogleTokens')({
  userId,
  exchangeCode,
}).then((data) => data.data.state as ResolvedState).catch(() => 'rejected' as ResolvedState);

export const initiateStage2API = (userId: string) => functions().httpsCallable('initiateStage2v2')({ userId })
  .then(() => 'resolved' as ResolvedState).catch(() => 'rejected' as ResolvedState);

export const saveNotificationDetailsAPI = (
  userId: string, notificationObject: DesktopNotification,
) => functions().httpsCallable('saveNotificationDetails')({
  userId,
  notificationObject,
}).then((data) => data.data as ResolvedState).catch(() => 'rejected' as ResolvedState);

export const checkRequirementsToEnableNotification = (
  userId: string, isDesktopNotificationEnabled: boolean,
) => {
  if ('serviceWorker' in navigator && userId.length !== 0 && !isDesktopNotificationEnabled) {
    return true;
  }
  return false;
};

export const stopWebhooksAPI = (userId: string) => functions().httpsCallable('stopGoogleCalendarWebhook')({ userId })
  .then((data) => {
    toastSuccess('Desktop notification disabled', '');
    return data.data.state as ResolvedState;
  })
  .catch(() => {
    toastDanger('Desktop notification disable failed');
    return 'rejected' as ResolvedState;
  });
