import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../../App';
import ButtonBig from '../../../shared/components/button-big';
import Modal from '../../../shared/components/Modal';
import ImageAtom from '../../../shared/components/quick-settings/notification/desktop-notification-settings/modal/ImageAtom';
import ModalLineAtom from '../../../shared/components/quick-settings/notification/desktop-notification-settings/modal/ModalLineAtom';
import ParagraphAtom from '../../../shared/components/quick-settings/notification/desktop-notification-settings/modal/ParagraphAtom';
import TitleAtom from '../../../shared/components/quick-settings/notification/desktop-notification-settings/modal/TitleAtom';
import WorkOnSofaPath from '../../../shared/img/WorkOnSofa.png';
import { CLOSE_EVENT, ENABLE_EVENT } from '../../../utils/analytics/enums';
import { logDesktopNotificationEvent } from '../../../utils/analytics/eventLogger';
import { subscribeToDesktopNotification, updateUserHasDismissedDesktopNotification } from '../../../utils/DesktopNotification';
import { checkRequirementsToEnableNotification } from '../../../utils/DesktopNotification/apis';
import useGetQueryParam from '../../../utils/hook/useGetParams';
import { toastDanger } from '../../../utils/notifications';
import PathGenerator from '../../../utils/PathGenerator';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  padding-bottom: 40px;
`;

const SpaceBetweenWrapper = styled.div`
  display: flex;
  padding: 10px 24px;
  justify-content: space-between;
  align-items: center;

  & > button {
    margin: 0;
  }

`;

interface Props {
  isOpen: boolean;
  setClosed: () => void;
}

const DesktopNotificationEnableModal = ({ isOpen, setClosed }: Props) => {
  const [isPending, setIsPending] = useState(false);
  const [, setDesktopNotificationEnable] = useState<boolean>(false);
  const { userId, settings } = useContext(UserDataContext);
  const authState = useContext(AuthContext);
  const isDesktopNotificationEnabledFromURLParams = useGetQueryParam(PathGenerator.desktopNotificationsToggleParam) === 'true';

  const onEnableClick = async () => {
    if (!isDesktopNotificationEnabledFromURLParams) {
      logDesktopNotificationEvent(authState.userId, ENABLE_EVENT, 'reminder_modal');
      window.open(PathGenerator.enableDesktopNotifications());
      setClosed();
    } else {
      setIsPending(true);
      handleEnableDesktopNotification();
    }
  };

  const handleEnableDesktopNotification = async () => {
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') return handleNotGrantedPermission();

    if (!checkRequirementsToEnableNotification(userId, settings.isDesktopNotificationEnabled)
    ) return handleNotGrantedPermission();

    const response = await subscribeToDesktopNotification(
      authState, userId, setDesktopNotificationEnable,
    );
    setIsPending(false);

    if (response !== 'resolved') return handleSomethingWentWrong();
    return handleAfterSuccessFlow();
  };

  const handleAfterSuccessFlow = () => {
    logDesktopNotificationEvent(authState.userId, ENABLE_EVENT, 'reminder_modal');
    setDesktopNotificationEnable(true);
  };

  const handleNotGrantedPermission = () => {
    toastDanger('Error', 'You have not granted permission to use desktop notifications, please do so in your browser settings');
  };

  const handleSomethingWentWrong = () => {
    toastDanger('Error', 'Something went wrong, please try again');
  };

  const onDismissClick = () => {
    logDesktopNotificationEvent(authState.userId, CLOSE_EVENT, 'reminder_modal');
    updateUserHasDismissedDesktopNotification(userId, true);
    setClosed();
  };

  return (
    <Modal
      isOpen={isOpen}
      setModalClosed={onDismissClick}
      isHeadless
    >
      <Container>
        <ImageAtom src={WorkOnSofaPath} />
        <TitleAtom>
          🎉 NEW
          <br />
          Desktop notifications
        </TitleAtom>
        <ParagraphAtom>
          Never miss another meeting again!
          We will remind you 1-min before your meeting and provide a meeting link to join instantly.
        </ParagraphAtom>
      </Container>
      <ModalLineAtom />
      <SpaceBetweenWrapper>
        <ButtonBig text="Dismiss" onClick={onDismissClick} isText />
        <ButtonBig text="Enable" onClick={onEnableClick} loading={isPending} />
      </SpaceBetweenWrapper>
    </Modal>
  );
};

export default DesktopNotificationEnableModal;
