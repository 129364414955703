import React from 'react';
import { darkBlue4 } from '../../colours';

interface Props {
  fill?: string,
}

const CheckboxIcon = ({ fill }: Props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.2691 3H5.7309C4.83399 3 4.18248 3.31181 3.75085 3.76618C3.31238 4.22774 3 4.94449 3 5.91988V12.0801C3 13.0581 3.3121 13.7745 3.74936 14.2349C4.17964 14.688 4.83061 15 5.7309 15H12.2691C13.1694 15 13.8204 14.688 14.2506 14.2349C14.6879 13.7745 15 13.0581 15 12.0801V5.91988C15 4.94192 14.6879 4.22554 14.2506 3.76507C13.8204 3.31196 13.1694 3 12.2691 3ZM5.7309 1.5H12.2691C14.8126 1.5 16.5 3.31249 16.5 5.91988V12.0801C16.5 14.6875 14.8126 16.5 12.2691 16.5H5.7309C3.18744 16.5 1.5 14.6875 1.5 12.0801V5.91988C1.5 3.31493 3.1919 1.5 5.7309 1.5Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.5303 6.21967C12.8232 6.51256 12.8232 6.98744 12.5303 7.28033L8.25 11.5607L5.46967 8.78033C5.17678 8.48744 5.17678 8.01256 5.46967 7.71967C5.76256 7.42678 6.23744 7.42678 6.53033 7.71967L8.25 9.43934L11.4697 6.21967C11.7626 5.92678 12.2374 5.92678 12.5303 6.21967Z" fill="#6F7C9F" />
  </svg>
);

CheckboxIcon.defaultProps = {
  fill: darkBlue4,
};

export default CheckboxIcon;
