import React, { useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../../../App';
import { isKnownUserUserId } from '../../../../utils/user/UserIds';
import { blue6 } from '../../../colours';
import { MeetingData, MeetingVersion } from '../../../types/types';
import ReactTooltip from '../../tooltip/ReactTooltip';

const Container = styled.span`
  color: ${blue6};
`;

interface Props {
  meetingData: MeetingData;
}

const MeetingVersionNumber = ({ meetingData }: Props) => {
  const authData = useContext(AuthContext);
  if (meetingData.resolvedState !== 'resolved') return null;
  if (!isKnownUserUserId(authData.userId)) return null;

  const tooltipText = generateMeetingVersionTooltipText(meetingData.version);

  return (
    <ReactTooltip tip={tooltipText} place="left" textEditor>
      <Container>
        {meetingData.version}
      </Container>
    </ReactTooltip>
  );
};

export default MeetingVersionNumber;

const generateMeetingVersionTooltipText = (meetingVersion: MeetingVersion) => {
  let versionText = `${meetingVersion}`;
  if (meetingVersion === 5) {
    versionText = '5 (latest)';
  }
  return `This meeting is currently using the version ${versionText} text editor.`;
};
