import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  width: 230px;
  /* max-height: 609px; */
  border-radius: 20px;
`;

export interface MediaAtomProps {
  media: string
}

const MediaAtom = ({ media }: MediaAtomProps) => (
  <Image src={media} alt="Loading" />
);

export default MediaAtom;
