import * as Sentry from '@sentry/browser';
import { ResolvedState } from '../shared/types/types';
import { cfCheckDesktopNotificationPermissionCore } from './cloudFunctionNotificationCore';

// eslint-disable-next-line import/prefer-default-export
export const cfCheckDesktopNotificationPermissionAPI = async (
  userId: string,
): Promise<ResolvedState> => cfCheckDesktopNotificationPermissionCore(userId)
  // TODO the response data type might change in the future
  .then((data: { state: ResolvedState }) => data.state)
  .catch((error) => {
    console.log('Error when checking notification permission', error);
    Sentry.captureException(error);
    return 'rejected';
  });
