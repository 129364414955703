/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../App';
import { gapiAPIGetMeetingByMeetId } from '../../database/gapiCalendarAPI';
import EmailAPI from '../../external/Email/EmailAPI';
import cfSearchPublicUserDataV2ByEmail, { cfSearchPublicUserDataV2ByEmailsV2 } from '../../external/publicUserData/PublicUserDataAPI';
import SlackAPI from '../../external/Slack/SlackAPI';
import TaskAPI from '../../external/TaskAPI/TaskAPI';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import SDate from '../../shared/classes/SDate';
import { gray4 } from '../../shared/colours';
import ButtonSmall from '../../shared/components/button-small';
import TitleAtom from '../../shared/components/new-feaures-modal/TitleAtom';
import { gapiGetMeeting } from '../../utils/google/GoogleCalendarAPI';
import { gapiCoreGetMyCalendarIds } from '../../utils/google/GoogleCalendarCore';
import { toastInfo } from '../../utils/notifications';
import { stopwatchPrintDiff, stopwatchStart } from '../../utils/stopwatch/stopwatch';
import useCounterStore from '../../zustand/useCounterStore';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 30px;
  background-color: ${gray4};
  height: 100vh;

  > * {
    margin-bottom: 20px;
  }
`;

const HaraldTestingPage = () => {
  const history = useHistory();
  const authData = useContext(AuthContext);
  const userData = useContext(UserDataContext);
  const [json, setJson] = useState({});

  const count = useCounterStore((state) => state.count);
  const incrementCount = useCounterStore((state) => state.increment);

  useEffect(() => {
    console.log('User Data');
    console.log(userData);
    return () => { };
  }, [authData]);

  const handleClick1 = async () => {
    toastInfo('Clicked', 'Button 1 clicked');
    // const emails : string[] = ['harald@meetshepherd.com', 'haraldlons@gmail.com'];
    // const subject = 'Halla på deg';
    // const body = 'Hei, har du noe å gjøre?';
    // EmailAPI.sendBasicEmail(emails, subject, body);
    SlackAPI.sendNotification('Some title', 'This is the message', userData.publicUserData);
  };

  const handleClick2 = async () => {
    incrementCount();
    toastInfo('Clicked', 'Button 2 clicked');
  };
  const handleClick3 = async () => {
    toastInfo('Clicked', 'Button 3 clicked');
  };
  const handleClick4 = () => {
    toastInfo('Clicked', 'Button 4 clicked');
  };

  const handleClick5 = async () => {
    toastInfo('Clicked', 'Button 5 clicked');
    const eventId = '74fmcaponf9vtg8pa9ivr3md0n_20220207T130000Z';

    const time1 = new SDate();
    const allCalendards = await gapiGetMeeting(eventId, 'haraldlons@gmail.com', 'haraldlons@gmail.com');
    ConsoleImproved.log(allCalendards);
    SDate.printPassedSinceSDate(time1);
  };

  const handleClick6 = async () => {
    toastInfo('Clicked', 'Button 6 clicked');
    history.push('/');
  };

  return (
    <Container>
      <TitleAtom>Harald Testing Page</TitleAtom>
      <TitleAtom>{count}</TitleAtom>
      <ButtonSmall text="Click 1" onClick={handleClick1} />
      <ButtonSmall text="Click 2" onClick={handleClick2} />
      {/* <ButtonSmall text="Click 3" onClick={handleClick3} />
      <ButtonSmall text="Click 4" onClick={handleClick4} />
      <ButtonSmall text="Click 5" onClick={handleClick5} />
      <ButtonSmall text="Click 6" onClick={handleClick6} /> */}
      <ReactJson src={json} />
    </Container>
  );
};

export default HaraldTestingPage;
