import { captureException } from '@sentry/react';
import { dbCreateTask } from '../../database/firebaseTasksAPI';
import { emptyTaskItem, mapDocumentsToTaskItems } from '../../database/utils/mapTaskData';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import {
  Assignee,
  AssigneeV2,
  AuthState,
  DatabaseTaskItem,
  ReporterV2, ResolveState, SDate, TaskItem, TaskMeetingData, TaskStatus, TrelloData, User,
} from '../../shared/types/types';
import { CREATE_EVENT } from '../../utils/analytics/enums';
import { logTasksUserAction } from '../../utils/analytics/eventLogger';
import { TASKS_COLLECTION } from '../../utils/constants';
import { dateToSDateObject } from '../../utils/dateUtils/date';
import { RESOLVED } from '../../utils/enums';
import { firestore } from '../../utils/firebase';
import { generateDatabaseTaskItemVariant2, mapTaskItemToDatabaseTaskItem } from '../../utils/tasks/tasksUtils';
import { getUsersTrelloSetting } from '../../utils/trello/trelloAPIs';
import { mapAssigneeV2ToAssignee, mapAssigneeV2ToDatabaseAssignee } from '../../utils/user/publivUserDataV2/PublicUserDataV2Utils';
import { defaultAuthContext } from '../../utils/user/UserDataUtils';
import EmailAPI from '../Email/EmailAPI';
import cfSearchPublicUserDataV2ByEmail from '../publicUserData/PublicUserDataAPI';
import SlackAPI from '../Slack/SlackAPI';
import TrelloAPI from '../Trello/TrelloAPI';
import TaskCoreAPI from './TaskCoreAPI';

/**
 * API to interact with tasks. Tasks are stored in the `/task` collection in firestore.
 */
class TaskAPI extends TaskCoreAPI {
  /**
   * Creates a new task
   *
   * @param DatabaseTaskItem The task to create
   *
   * @returns
   *
   * IF SUCCESS: document `taskId` of the created task.
   *
   * IF FAIL: empty string
   */
  static async createTask(
    taskItem: TaskItem,
    trelloChecked: boolean,
    userData: User,
    trelloData: TrelloData,
    authState: AuthState,
    intercomTrackEvent?: any,
    actionFrom: string = 'undefined',
  ): Promise<string> {
    const databaseTaskItem = mapTaskItemToDatabaseTaskItem(taskItem);

    if (!TaskAPI.validateDatabaseTaskItem(databaseTaskItem)) {
      ConsoleImproved.log('TaskAPI: Task is not valid', taskItem);
      return '';
    }

    const taskId = await dbCreateTask(authState, databaseTaskItem, intercomTrackEvent);
    if (taskId.length === 0) return '';

    logTasksUserAction(authState.userId, CREATE_EVENT, actionFrom, '', intercomTrackEvent, taskItem.data.isPrivate, trelloChecked);

    TrelloAPI.createTask(taskId, databaseTaskItem, trelloChecked, trelloData);
    EmailAPI.sendNotificationForTaskCreation(taskItem, userData.data.name, userData.data.email);
    SlackAPI.sendNotificationForTaskCreation(taskItem, authState);

    return taskId;
  }

  /**
   * Inventiff can use this variant to create a new task
   *
   * @returns
   *
   * IF SUCCESS: document `taskId` of the created task.
   *
   * IF FAIL: empty string
   */
  static async createTaskVariant2(
    // TODO: Add Trello, email and slack
    title: string, reporter: ReporterV2, taskMeetingData: TaskMeetingData,
    assignee?: AssigneeV2, dueDate?: SDate,
  ): Promise<string> {
    ConsoleImproved.log('TaskAPI: createTaskVariant2', {
      assignee, reporter, title, dueDate, taskMeetingData,
    });

    const databaseTaskItem = generateDatabaseTaskItemVariant2(title, reporter, taskMeetingData,
      assignee, dueDate);
    const taskId = await dbCreateTask(defaultAuthContext, databaseTaskItem);
    if (taskId.length === 0) return '';
    return taskId;
  }

  /**
   * If you absolutely have issues with passing the AssigneeV2 and ReporterV2 object,
   *  use this function.
   *
   * @returns
   *
   * IF SUCCESS: document `taskId` of the created task.
   *
   * IF FAIL: empty string
   */
  static async createTaskVariant3(
    // TODO: Add Trello, email and slack
    title: string, reporterEmail: string, taskMeetingData: TaskMeetingData,
    assigneeEmail: string, dueDate?: SDate,
  ): Promise<string> {
    ConsoleImproved.log('TaskAPI: createTaskVariant3', {
      assigneeEmail, reporterEmail, title, dueDate, taskMeetingData,
    });
    const assignee = await cfSearchPublicUserDataV2ByEmail(assigneeEmail);
    const reporter = await cfSearchPublicUserDataV2ByEmail(reporterEmail);
    ConsoleImproved.log('TaskAPI: createTaskVariant3, assignee and reporter', { assignee, reporter });

    const databaseTaskItem = generateDatabaseTaskItemVariant2(title, reporter, taskMeetingData,
      assignee, dueDate);
    const taskId = await dbCreateTask(defaultAuthContext, databaseTaskItem);
    if (taskId.length === 0) return '';

    const taskItem = await TaskAPI.getTask(taskId);

    const trelloData = await getUsersTrelloSetting(assignee.data.email);
    if (trelloData.resolvedState === RESOLVED) {
      TrelloAPI.createTask(taskId, databaseTaskItem, true, trelloData.item);
    }

    EmailAPI.sendNotificationForTaskCreation(taskItem, reporter.data.name, reporter.data.email);
    SlackAPI.sendNotificationForTaskCreationWithCustomReporter(taskItem, reporter);

    return taskId;
  }

  static async updateTitle(
    taskId: string, newTitle: string,
  ): Promise<ResolveState> {
    ConsoleImproved.log('TaskAPI: updateTitle', { taskId, newTitle });
    return TaskCoreAPI.updateTask(taskId, { 'data.title': newTitle });
  }

  static async updateDueDate(
    taskId: string, newDueDate: Date,
  ): Promise<ResolveState> {
    ConsoleImproved.log('TaskAPI: updateTitle', { taskId, newDueDate });
    const updateObject = {
      'date.dueDate.date': dateToSDateObject(newDueDate),
      'date.dueDate.type': 'date',
    };
    return TaskCoreAPI.updateTask(taskId, updateObject);
  }

  static async updateAssignee(
    taskId: string, newAssignee: AssigneeV2,
  ): Promise<ResolveState> {
    ConsoleImproved.log('TaskAPI: updateTitle', { taskId, newAssignee });
    const databaseAssignee = mapAssigneeV2ToDatabaseAssignee(newAssignee);
    const assignee: Assignee = mapAssigneeV2ToAssignee(newAssignee);
    const updateObject = {
      'data.assignee': assignee,
      assignee: databaseAssignee,
    };

    return TaskCoreAPI.updateTask(taskId, updateObject);
  }

  static async updateStatus(
    taskId: string, newStatus: TaskStatus,
  ): Promise<ResolveState> {
    ConsoleImproved.log('TaskAPI: updateStatus', { taskId, newStatus });
    const updateObject = {
      'data.status': newStatus,
    };
    return TaskCoreAPI.updateTask(taskId, updateObject);
  }

  static validateDatabaseTaskItem(item: DatabaseTaskItem) {
    if (item.data.title.length === 0) return false;
    return true;
  }

  static async getTask(taskId: string): Promise<TaskItem> {
    const taskItem = await firestore()
      .collection(TASKS_COLLECTION)
      .doc(taskId)
      .get()
      .then(async (doc) => {
        if (!doc.exists) return emptyTaskItem;
        const tasks = await mapDocumentsToTaskItems([doc]);
        return tasks[0];
      })
      .catch((error) => {
        captureException(error);
        return emptyTaskItem;
      });
    return taskItem;
  }
}

export default TaskAPI;
