import date from 'date-and-time';
import { DatabaseGapiMeetingData, GapiMeetingData, GoogleCalendarEventCreationData } from '../shared/types/types';
import { startTrackingEvent, stopTrackingEvent } from '../utils/analytics/eventLogger';

import {
  getEndOfMonth, getEndOfWeek, getStartOfMonth, getStartOfToday, getStartOfWeek,
} from '../utils/dateUtils/date';
import { getMeetingsInPeriod, getTodaysMeetings } from '../utils/google/GoogleCalendarAPI';
import {
  appendTextToDescriptionString,
  gapiFormatTimeForFirstMeeting,
  gapiInsertFirstMeetingSummary,
  mapDatabaseGapiMeetingDataToGapiMeetingIdsObj,
  RejectedGoogleMeetingIdsObj,
} from '../utils/google/GoogleCalendarUtils';
import {
  cfGapiGetMeetingCore,
  cfGapiGetMyCalendarIdsCore,
  cfGapiInsertMeetingCore,
  cfGapiUpdateMeetingDescriptionCore,
  cfGapiGetMeetingsInCalendarInTimeFrameCore,
} from './gapiCalendarCore';
import { makeErrorMessage, validateEventIdAndCalendarId } from './gapiCalendarUtils';
import mapGapiResultToGapiMeetingData, { rejectedGapiMeetingData } from './utils/gapiMeetingDataUtils';
import { emptyDatabaseGapiMeetingData } from './utils/templateMeetingData';

// not used in current version RC-0.10.0
export const cfGapiListUpcomingEvents = async () => {
  throw new Error('not implemented');
};

export const cfGapiGetMeeting = async (
  accessToken: string, calendarId: string, eventId: string, userEmail: string,
): Promise<GapiMeetingData> => {
  if (!validateEventIdAndCalendarId(eventId, calendarId)) {
    makeErrorMessage(eventId, calendarId);
    return rejectedGapiMeetingData;
  }
  startTrackingEvent('meetingDataLoad');
  return cfGapiGetMeetingCore(accessToken, eventId, calendarId, 1)
    .then((result) => {
      const gapiMeetingData = mapGapiResultToGapiMeetingData(result.item);
      return gapiMeetingData;
    })
    .catch(() => cfGapiGetMeetingCore(accessToken, eventId, userEmail, 2)
      .then((result) => {
        const gapiMeetingData = mapGapiResultToGapiMeetingData(result.item);
        return gapiMeetingData;
      })
      .catch(() => rejectedGapiMeetingData))
    .finally(() => stopTrackingEvent('meetingDataLoad'));
};

const cfGapiGetMeetingsInPeriod = async (
  accessToken: string, timeMin: string, timeMax: string,
): Promise<DatabaseGapiMeetingData[]> => {
  const { item: calendarIds } = await cfGapiGetMyCalendarIdsCore(accessToken);
  const promises = await calendarIds
    .map(
      (calendarId: string) => cfGapiGetMeetingsInCalendarInTimeFrameCore(
        accessToken, calendarId, timeMin, timeMax,
      ),
    );

  const tempResult = await Promise.all(promises)
    .then((resolvedItems) => resolvedItems
      .map((resolvedItem) => resolvedItem.item));
  const result = tempResult.flat();
  return result;
};

export const cfGapiGetTodaysMeetings = async (accessToken: string) => {
  let startOfToday = getStartOfToday();
  startOfToday = date.addDays(startOfToday, 3); // Just for testing different days
  const endOfToday = date.addDays(startOfToday, 1);
  const result = await cfGapiGetMeetingsInPeriod(
    accessToken,
    startOfToday.toISOString(),
    endOfToday.toISOString(),
  );
  console.log('Got result in getTodaysMeetings');
  console.log(result);
  return result;
};

export const cfGapiGetThisWeeksUpcomingMeetings = async (
  accessToken: string,
) => {
  const startOfWeek = getStartOfWeek();
  const endOfWeek = getEndOfWeek();
  console.log(`StartOfweek: ${startOfWeek}`); // By default a week is sunday - saturday week
  console.log(`End of week: ${endOfWeek}`);
  const result = await cfGapiGetMeetingsInPeriod(
    accessToken,
    startOfWeek,
    endOfWeek,
  );
  console.log('Got result in getTodaysMeetings');
  console.log(result);
  return result;
};

export const cfGapiGetMontlyMeetings = async (accessToken: string) => {
  const startOfMonth = getStartOfMonth();
  const endOfMonth = getEndOfMonth();
  const result = await cfGapiGetMeetingsInPeriod(
    accessToken,
    startOfMonth,
    endOfMonth,
  );
  console.log('Got result in getTodaysMeetings');
  console.log(result);
  return result;
};

export const cfGapiAppendAgendaToDescription = async (
  accessToken: string, calendarId: string, eventId: string, agenda: string, description: string,
) => {
  if (!validateEventIdAndCalendarId(eventId, calendarId)) {
    makeErrorMessage(eventId, calendarId);
  }

  const resolvedItem = await cfGapiUpdateMeetingDescriptionCore(
    accessToken,
    eventId,
    calendarId,
    appendTextToDescriptionString(description, agenda),
  );

  if (resolvedItem.resolvedState !== 'resolved') return emptyDatabaseGapiMeetingData;

  return resolvedItem.item;
};

export const cfGapiInsertFirstMeeting = async (accessToken: string) => {
  const summary = gapiInsertFirstMeetingSummary();
  const { startTime, endTime, timeZone } = gapiFormatTimeForFirstMeeting();

  const event: GoogleCalendarEventCreationData = {
    summary,
    description: 'Welcome to the Shepherd Family!',
    start: {
      dateTime: startTime,
      timeZone,
    },
    end: {
      dateTime: endTime,
      timeZone,
    },
  };

  const resolvedItem = await cfGapiInsertMeetingCore(accessToken, event, 'primary');

  if (resolvedItem.resolvedState !== 'resolved') return RejectedGoogleMeetingIdsObj;

  return mapDatabaseGapiMeetingDataToGapiMeetingIdsObj(resolvedItem.item);
};

export const gapiAPIGetMeetingByMeetId = async (
  meetId: string,
): Promise<GapiMeetingData> => {
  // We first search close in time for the meeting,
  // extend the search

  const meetings = await getTodaysMeetings();
  const list = meetings.filter((meeting) => meeting.conferenceData.conferenceId === meetId);
  if (list.length) return list[0];

  const meetingsFromTwoDaysAgoToTwoDaysFromNow = await getMeetingsInPeriod(-2, 4);
  const list2 = meetingsFromTwoDaysAgoToTwoDaysFromNow.filter(
    (meeting) => meeting.conferenceData.conferenceId === meetId,
  );
  if (list2.length) return list2[0];

  const meetingsLastAndNextWeek = await getMeetingsInPeriod(-7, 14);
  const list3 = meetingsLastAndNextWeek.filter(
    (meeting) => meeting.conferenceData.conferenceId === meetId,
  );
  if (list3.length) return list3[0];

  return rejectedGapiMeetingData;
};
